import Vue from 'vue';
import VueRouter from 'vue-router';
import Confirmation from '@/views/Confirmation.vue';
import InvestorProfile from '@/views/InvestorProfile.vue';
import NewProfile from '@/views/InvestorNewProfile.vue';
import MyParcels from '@/views/MyParcels.vue';
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import NewPassword from '@/views/NewPassword.vue';
import NewProperty from '@/views/NewProperty.vue';
import Announcement from '@/views/Announcement.vue';
import Properties from '@/views/Properties.vue';
import Offer from '@/views/Offer.vue';
import Offers from '@/views/Offers.vue';
import Contract from '@/views/Contract.vue';
import UserContract from '@/views/UserContract.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/confirmation/:uuid/:token',
    name: 'confirmation',
    component: Confirmation,
  },
  {
    path: '/properties',
    name: 'myParcels',
    component: MyParcels,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investorProfile/:uuid',
    name: 'investorProfile',
    component: InvestorProfile,
    meta: {
      requiresAuth: true,
      investor: true,
    },
  },
  {
    path: '/properties/:propertyId/announcement',
    name: 'announcement',
    component: Announcement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/properties/:propertyId/announcement/preview/:token',
    name: 'announcementPreview',
    component: Announcement,
  },
  {
    path: '/properties/published',
    name: 'properties',
    component: Properties,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/properties/:propertyId/offers',
    name: 'offerList',
    component: Offers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/properties/:propertyId/offers/:offerId',
    name: 'offer',
    component: Offer,
    meta: {
      requiresAuth: true,
      investor: true,
    },
  },
  {
    path: '/investorProfile/:uuid/new',
    name: 'newProfile',
    component: NewProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/investorProfile/:uuid/edit/:profileId',
    name: 'editProfile',
    component: NewProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/properties/new',
    name: 'newProperty',
    component: NewProperty,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/properties/:uuid/edit',
    name: 'editProperty',
    component: NewProperty,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/resetPassword',
    name: 'reset',
    component: ResetPassword,
  },
  {
    path: '/resetPassword/:uid/:token',
    name: 'newPassword',
    component: NewPassword,
  },
  {
    path: '/contract/:propertyId',
    name: 'contract',
    component: Contract,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/userContract',
    name: 'userContract',
    component: UserContract,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = JSON.parse(window.localStorage.getItem('logged'));
  const investor = JSON.parse(window.localStorage.getItem('investor'));
  if (to.name === null) {
    if (loggedIn) {
      next(from.fullPath);
    } else {
      next({ name: 'login' });
    }
  }
  if (to.name === 'login') {
    if (loggedIn) {
      next({ name: 'myParcels' });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.investor)) {
    if (!investor) {
      next(from.fullPath);
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
