import { render, staticRenderFns } from "./IntermediationContact.vue?vue&type=template&id=354eb48f&scoped=true&"
import script from "./IntermediationContact.vue?vue&type=script&lang=js&"
export * from "./IntermediationContact.vue?vue&type=script&lang=js&"
import style0 from "./IntermediationContact.vue?vue&type=style&index=0&id=354eb48f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354eb48f",
  null
  
)

export default component.exports