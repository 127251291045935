<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        {{ $t("permitExists") }}
      </v-card-title>
      <v-card-text>
        <v-btn-toggle
          v-model="isPermitGrantedOption"
          @change="isPermitGrantedChanged"
        >
          <v-btn class="new-property__toggle-btn btn-left" :value="permitGrantedOptions.granted">
            {{ $t("yes") }}
          </v-btn>
          <v-btn class="new-property__toggle-btn" :value="permitGrantedOptions.inProgress">
            {{ $t("inProgress") }}
          </v-btn>
          <v-btn class="new-property__toggle-btn btn-right" :value="permitGrantedOptions.notGranted">
            {{ $t("no") }}
          </v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>
    <v-card flat class="mt-5 new-property-files">
      <v-card-text v-if="this.isPermitGrantedOption === permitGrantedOptions.granted">
        <v-form :model="permitForm">
          <v-file-input
            class="required"
            :hint="$t('required')"
            :placeholder="$t('addPermissionFiles')"
            :loading="uploadingPermit"
            :disabled="uploadingPermit"
            @change="(files) => {
              fileSelected(
                files,
                'building_permit',
                'building_permit',
                'uploadingPermit',
                'selectedPermitFiles'
              )
            }"
            :rules="permitFileRules"
            :value="selectedPermitFiles"
            multiple
          />
          <file-list :files="permitFiles" @fileRemoved="(id) => onFileRemoved(id, 'building_permit')"/>

          <v-file-input
            :hint="$t('notRequired')"
            :placeholder="$t('addBuildingProject')"
            :loading="uploadingProject"
            :disabled="uploadingProject"
            @change="(files) => {
              fileSelected(
                files,
                'building_project',
                'building_project',
                'uploadingProject',
                'selectedProjectFiles'
              )
            }"
            :model="selectedProjectFiles"
            multiple
          />
          <file-list :files="projectFiles" @fileRemoved="(id) => onFileRemoved(id, 'building_project')"/>

          <v-textarea
            v-model="comments"
            :hint="$t('notRequired')"
            :label="$t('addCommentOrOpinionFiles')"
            counter
            :counter-value="valuePerMax"
          />

          <v-file-input
            multiple
            :hint="$t('notRequired')"
            :placeholder="$t('otherFiles')"
            :loading="uploadingOther"
            :disabled="uploadingOther"
            @change="(files) => {
              fileSelected(
                files,
                'other',
                'other_files',
                'uploadingOther',
                null
              )
            }"
            :model="selectedOtherFiles"
          />
          <file-list :files="otherFiles" @fileRemoved="(id) => {onFileRemoved(id, 'other_files')}"/>
        </v-form>
      </v-card-text>

      <v-card flat v-if="this.isPermitGrantedOption === permitGrantedOptions.inProgress
        || this.isPermitProposalOption && this.isPermitGrantedOption === permitGrantedOptions.notGranted"
      >
        <v-card-title>
          {{ $t("permitProposalExists") }}
        </v-card-title>
        <v-card-text>
          <v-btn-toggle
            v-model="isPermitProposalOption"
            @change="isPermitProposalChanged"
          >
            <v-btn class="new-property__toggle-btn btn-left" :value="permitProposalOptions.yes">{{ $t("yes") }}</v-btn>
            <v-btn class="new-property__toggle-btn btn-right" :value="permitProposalOptions.no">{{ $t("no") }}</v-btn>
          </v-btn-toggle>
        </v-card-text>
      </v-card>
      <v-card-text v-if="this.isPermitProposalOption === permitProposalOptions.yes">
        <v-form :model="permitForm">
          <v-file-input
            class="required"
            :hint="$t('required')"
            :placeholder="$t('addBuildingProject')"
            :loading="uploadingProject"
            :disabled="uploadingProject"
            @change="(files) => {
                fileSelected(
                  files,
                  'building_project',
                  'building_project',
                  'uploadingProject',
                  'selectedProjectFiles'
                )
              }"
            :model="selectedProjectFiles"
            multiple
          />
          <file-list :files="projectFiles" @fileRemoved="(id) => {onFileRemoved(id, 'building_project')}"/>

          <v-file-input
            class="required"
            :hint="$t('required')"
            :placeholder="$t('permitProposal')"
            :loading="uploadingPermitProposal"
            :disabled="uploadingPermitProposal"
            @change="(files) => {
                fileSelected(
                  files,
                  'building_permit_proposal',
                  'building_permit_proposal',
                  'uploadingPermitProposal',
                  'selectedPermitProposalFiles',
                )
              }"
            :model="selectedPermitProposalFiles"
            multiple
          />
          <file-list
            :files="buildingProposalFiles"
            @fileRemoved="(id) => onFileRemoved(id, 'building_permit_proposal')"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fileUpload from '@/services/fileUpload';
import { permitGrantedOptions, permitProposalOptions } from '@/helpers/enums';
import FileList from '../FileList.vue';

export default {
  name: 'Permit',
  components: { FileList },
  created() {
    if (this.parcel.is_permit_granted) {
      this.isPermitGrantedOption = this.parcel.is_permit_granted;
    }

    if (this.parcel.geotechnical_comments) {
      this.comments = this.parcel.geotechnical_comments;
    }

    if (this.parcel.other_files) {
      this.selectedOtherFiles = this.parcel.other_files;
    }
  },
  data: (vm) => ({
    permitGrantedOptions,
    permitProposalOptions,
    isPermitGrantedOption: null,
    isPermitProposalOption: null,
    uploadingPermit: false,
    uploadingProject: false,
    uploadingOther: false,
    uploadingPermitProposal: false,
    comments: null,
    permitForm: false,
    selectedPermitFiles: [],
    selectedProjectFiles: [],
    selectedOtherFiles: [],
    selectedPermitProposalFiles: [],
    commentsRules: [
      (v) => (v && v.length <= 2000) || vm.$t('max2000Chars'),
    ],
    permitFileRules: [
      (v) => !!v || vm.permitFile.length > 0 || vm.$t('cantBeEmpty'),
    ],
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['parcel']),
    permitFiles() {
      return this.parcel.building_permit;
    },
    projectFiles() {
      return this.parcel.building_project;
    },
    otherFiles() {
      return this.parcel.other_files;
    },
    buildingProposalFiles() {
      return this.parcel.building_permit_proposal;
    },
  },
  methods: {
    ...mapActions('newPropertyForm', ['updateParcel']),
    completeStep(completed) {
      this.$emit('stepCompleted', completed);
    },
    isPermitGrantedChanged() {
      this.completeStep(false);
      this.isPermitProposalOption = null;
      if (this.isPermitGrantedOption) {
        const parcel = {
          ...this.parcel,
          is_permit_granted: this.isPermitGrantedOption,
          building_permit: [],
          building_project: [],
          other_files: [],
          building_permit_proposal: [],
        };
        if (this.isPermitGrantedOption === permitGrantedOptions.notGranted) this.completeStep(true);
        this.updateParcel(parcel);
      }
    },
    isPermitProposalChanged() {
      this.completeStep(false);
      if (this.isPermitProposalOption !== null) {
        if (this.isPermitProposalOption === permitProposalOptions.no) {
          const parcel = {
            ...this.parcel,
            is_permit_granted: permitGrantedOptions.notGranted,
            is_building_permit_proposal: this.isPermitProposalOption === permitProposalOptions.yes,
            building_permit_proposal: [],
          };

          this.completeStep(true);
          this.updateParcel(parcel).then(this.isPermitGrantedOption = permitGrantedOptions.notGranted);
        } else {
          const parcel = {
            ...this.parcel,
            is_permit_granted: permitGrantedOptions.inProgress,
            is_building_permit_proposal: this.isPermitProposalOption === permitProposalOptions.yes,
            building_permit_proposal: [],
          };
          this.updateParcel(parcel).then(this.isPermitGrantedOption = permitGrantedOptions.inProgress);
        }
      }
    },
    fileSelected(files, type, updatedParcelField, uploadingStateField, selectedStateField) {
      if (!files) return;
      this[uploadingStateField] = true;
      try {
        fileUpload.uploadMultiple(files, type).then((uploadedFileIds) => {
          const parcel = {
            ...this.parcel,
            [updatedParcelField]: [...this.parcel[updatedParcelField], ...uploadedFileIds],
          };
          this.updateParcel(parcel);
        });
      } catch (error) {
        console.log('Error uploading file', error);
      } finally {
        this[uploadingStateField] = false;
        if (selectedStateField) {
          this[selectedStateField] = [];
        }
      }
    },
    onFileRemoved(id, updatedStateField) {
      const updatedFiles = this.parcel[updatedStateField].filter((fileId) => fileId !== id);
      const parcel = {
        ...this.parcel,
        [updatedStateField]: [...updatedFiles],
      };

      this.updateParcel(parcel);
    },
    valuePerMax(value) {
      return value ? `${value.length} / 2000` : '0 / 2000';
    },
  },
  watch: {
    permissionFile() {
      if (this.permissionFile != null) {
        this.completeStep(true);
      }
      // TODO
      //   const permission = {
      //     ...this.permission,
      //   };
      //
      //   permission.file = this.permissionFile;
      //   this.setPermission(permission);
      //   this.completeStep(true);
      // } else {
      //   this.completeStep(false);
      // }
    },
    comments() {
      const parcel = {
        ...this.parcel,
        geotechnical_comments: this.comments,
      };

      if (!this.comments) {
        parcel.geotechnical_comments = null;
      }

      this.updateParcel(parcel);
    },
  },
};
</script>

<style scoped lang="scss">
  .new-property {
    ::v-deep &__toggle-btn {
      background: transparent !important;

      .v-btn__content {
        color: $black !important;
      }

      &.v-item--active {
        background: $f-blue !important;

        .v-btn__content {
          color: $white !important;
        }
      }
    }
  }

  .new-property-files {
    .required {
      ::v-deep .v-file-input__text {
        &:after {
          color: red;
          content: "*";
        }
      }
    }
  }

  .v-application .error--text {
     content: "*a";
  }
</style>
