<template>
  <v-container
    fluid
    v-if="logged"
    class="pt-md-0"
  >
    <v-row>
      <v-col
        cols="2"
        class="d-block py-0 d-sm-none"
      >
        <v-icon @click.stop="drawer = !drawer">mdi-menu</v-icon>
      </v-col>
      <v-col
        cols="8"
        class="d-block py-0 d-sm-none"
      >
        <img src="../../assets/images/logo.png" alt="logo">
      </v-col>
      <v-col
        cols="4"
        md="4"
        lg="2"
        class="d-none d-sm-block"
      >
        <img src="../../assets/images/logo.png" alt="logo">
      </v-col>
      <v-col
        cols="8"
        md="6"
        lg="5"
        offset-md="2"
        offset-lg="5"
        class="d-none d-sm-block navbar"
        >
          <div class="d-flex text-right justify-space-between h-100 align-center">
            <div class="relative">
              <span
                v-if="this.notification"
                class="notidot"
                :class="{ 'd-block' : this.notification.new_offer }"
              ></span>
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="btn-clear"
                    :class="{ 'route-active' : isPropertyRoute }"
                  >
                    {{ $t('realEstate')}}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <router-link
                      :to="{ name: 'myParcels'}"
                      exact
                    >
                      {{ $t('yourProperties') }}
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link
                      :to="{ name: 'newProperty' }"
                      exact
                    >
                      {{ $t('addProperty') }}
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                      <span
                        v-if="this.notification"
                        class="notidot"
                        :class="{ 'd-block' : this.notification.new_offer }"
                      ></span>
                    <router-link
                      :to="{ name: 'properties' }"
                      exact
                    >
                      {{ $t('publishedProperties') }}
                    </router-link>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-menu open-on-hover offset-y v-if="isInvestor">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="btn-clear"
                  :class="{ 'route-active' : isProfileRoute }"
                >
                  {{ $t('profiles')}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <router-link
                    :to="{ name: 'investorProfile', params: { uuid: this.user.id }}"
                    exact
                  >
                    {{ $t('yourProfiles') }}
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link
                    :class="[!this.user.is_investor_contract_accepted && 'user-contract-router-link']"
                    :to="this.user.is_investor_contract_accepted
                      ? { name: 'newProfile', params: { uuid: this.user.id }}
                      : { name: 'userContract'}"
                    exact
                  >
                    {{ $t('addProfile') }}
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="d-flex align-center">
              <p class="mb-0">{{ user.email }}</p>
            </div>
            <v-icon @click="logout">mdi-login-variant</v-icon>
          </div>
      </v-col>
    </v-row>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      width="420"
      class="navigation-menu d-block d-sm-none"
    >
      <v-list dense >
        <v-list-item
          class="navigation-menu__btn-close-menu"
          @click="drawer = false"
        >
          <v-list-item-action></v-list-item-action>
          <v-list-item-title>
          </v-list-item-title>
          <v-icon>mdi-close</v-icon>
        </v-list-item>
        <v-list-item>
          <p>{{ user.email }}</p>
        </v-list-item>
        <v-list-group no-action>
          <template v-slot:activator>
              <v-list-item class="py-2">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('realEstate') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </template>
          <router-link
            :to="{ name: 'myParcels'}"
            exact
            class="navigation__links"
          >
            <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('yourProperties') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </router-link>
          <router-link
            :to="{ name: 'properties'}"
            exact
            class="navigation__links"
          >
            <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('publishedProperties') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </router-link>
          <router-link
            :to="{ name: 'newProperty'}"
            exact
            class="navigation__links"
          >
            <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('addProperty') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </router-link>
        </v-list-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item class="py-2">
              <v-list-item-content>
                <v-list-item-title>{{ $t('profiles') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <router-link
            :to="{ name: 'investorProfile', params: { uuid: this.user.id }}"
            exact
            class="navigation__links"
          >
            <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('yourProfiles') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </router-link>
          <router-link
            :to="{ name: 'newProfile', params: { uuid: this.user.id }}"
            exact
            class="navigation__links"
          >
            <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('addProfile') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </router-link>
        </v-list-group>
        <v-list-item v-if="logged" class="my-3 pl-8 py-2 navigation__links">
          <v-list-item-content>
            <v-list-item-title @click="logout">{{ $t('logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'Navbar',
  data: () => ({
    drawer: false,
  }),
  created() {
    if (this.logged && isEmpty(this.user)) {
      this.currentUser();
    }
    if (this.logged) {
      this.getNotifications();
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('properties', ['properties']),
    ...mapGetters('notifications', ['notification']),
    logged() {
      return JSON.parse(window.localStorage.getItem('logged'));
    },
    isInvestor() {
      return this.user.type === 'investor';
    },
    isPropertyRoute() {
      return this.$route.name === 'myParcels'
        || this.$route.name === 'newProperty'
        || this.$route.name === 'properties';
    },
    isProfileRoute() {
      return this.$route.name === 'investorProfile' || this.$route.name === 'newProfile';
    },
  },
  methods: {
    ...mapActions('user', ['logOutUser']),
    ...mapActions('user', ['currentUser']),
    ...mapActions('user', ['getInvestorProfiles']),
    ...mapActions('notifications', ['getNotifications']),
    logout() {
      this.logOutUser().then(() => {
        this.$router.push({ name: 'login' });
      });
    },
  },
};
</script>

<style lang="scss">
  .router-link-active {
    font-weight: bold;
    .v-list-item__title {
      color: $f-blue;
      font-weight: bold;
    }
  }
  .user-contract-router-link {
    font-weight: normal;
    .v-list-item__title {
      font-weight: normal;
    }
  }
  .navbar {
    .v-list-item {
      &:hover {
        background-color: $f-blue;
        a {
          color: $white!important;
        }
      }
    }
  }
  .route-active {
    .v-btn__content {
      border-bottom: 2px solid $f-blue;
    }
  }
  .btn-clear {
    background-color: transparent!important;
    box-shadow: none!important;
    text-transform: none!important;
    border-radius: 0!important;
    .v-btn__content {
      font-size: 16px!important;
      font-weight: bold;
      color: $f-blue!important;
    }
  }
  .v-menu__content {
    z-index: 999!important;
  }
  .v-navigation-drawer__content {
    background-color: $white;
  }
  .notidot {
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: $f-red;
    border-radius: 50%;
    right: 0;
    display: none;
  }
  .v-navigation-drawer__border {
    display: none;
  }
</style>
