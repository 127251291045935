<template>
  <v-col
    cols="12"
    md="6"
    class="mt-8 announcement-law"
  >
    <div class="announcement-law-status-container">
      <h2 class="mb-4">{{ $t('lawStatus') }}:</h2>
      <div
        class="announcement relative"
        v-for="(item, index) in land_register"
        :key="index"
      >
        <v-row>
          <v-col cols="6" v-if="tooltip.length" class="relative">
            <span class="font-weight-bold">{{ $t('landRegister') }} {{ index + 1 }}</span>
            <div class="announcement-law-status__tooltip pa-3" v-if="tooltip[index].show">
              <p class="small">{{ $t('landRegisterToolTip') }}</p>
            </div>
            <v-icon
              @click="tooltip[index].show = !tooltip[index].show"
            >
              mdi-information-outline
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" md="6">
            <div class="announcement-law-status__items">
              <p class="mb-1">{{ $t('formOfPossession') }}</p>
              <p class="font-weight-bold">{{ $t(item.property_ownership_type) }}</p>
            </div>
          </v-col>
          <v-col cols="12">
            <p>{{ $t('transmissions')}}:</p>
          </v-col>
          <v-col cols="12" md="3">
            <div class="announcement-law-status__items">
              <p class="mb-1">{{ $t('transmissionEasement') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.has_transmission_easement) }}
              </p>
            </div>
            <div class="announcement-law-status__items mt-4">
              <p class="mb-1">{{ $t('bailiffExecutions') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.has_bailiff_executions) }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="announcement-law-status__items">
              <p class="mb-1">{{ $t('roadServices') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.has_road_easement) }}
              </p>
            </div>
            <div class="announcement-law-status__items mt-4">
              <p class="mb-1">{{ $t('mortgagesSetUp') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.has_established_mortgages) }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="announcement-law-status__items">
              <p class="mb-1">{{ $t('otherRestrictions') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.has_other_restrictions) }}
              </p>
            </div>
            <div class="announcement-law-status__items mt-4">
              <p class="mb-1">{{ $t('parcelBuiltUp') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.is_parcel_built_up) }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="announcement-law-status__items">
              <p class="mb-1">{{ $t('bailiffEntries') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.has_bailiff_entries) }}
              </p>
            </div>
            <div class="announcement-law-status__items mt-4">
              <p class="mb-1">{{ $t('undisclosedPremises') }}</p>
              <p class="font-weight-bold text-uppercase">
                {{ getBoolTranslate(item.has_undisclosed_premises) }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-4 mb-10">
          <v-col cols="12" class="mt-4">
            <p class="font-weight-bold">{{ $t('sectionI')}}</p>
            <p class="font-weight-bold mt-3">
              {{ getBoolTranslate(item.first_chapter) }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-4">
            <p class="font-weight-bold">{{ $t('sectionII')}}</p>
            <p class="font-weight-bold mt-3">
              {{ getBoolTranslate(item.second_chapter) }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-4">
            <p class="font-weight-bold">{{ $t('sectionIII')}}</p>
            <p class="font-weight-bold mt-3">
              {{ getBoolTranslate(item.third_chapter) }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-4">
            <p class="font-weight-bold">{{ $t('sectionIV')}}</p>
            <p class="font-weight-bold mt-3">
              {{ getBoolTranslate(item.fourth_chapter) }}
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LawStatus',
  data: () => ({
    tooltip: [],
  }),
  created() {
    setTimeout(() => {
      this.addTooltips();
    }, 2000);
  },
  computed: {
    ...mapGetters('announcement', ['land_register']),
  },
  methods: {
    getBoolTranslate(item) {
      if (item === false) {
        return this.$t('false');
      }
      if (item === true) {
        return this.$t('true');
      }
      if (item === null) {
        return this.$t('null');
      }
      return item;
    },
    addTooltips() {
      // eslint-disable-next-line no-plusplus
      for (let y = 0; y < this.land_register.length; y++) {
        this.tooltip.push({ show: false });
      }
    },
  },
};
</script>

<style lang="scss">
  .announcement-law {
    border-right: 1px solid $f-grey;
    &-status {
      margin-bottom: 40px;
      border-bottom: 1px solid $f-grey;
      &-container {
        padding: 0 20px;
      }
      &__tooltip {
        position: absolute;
        top: -80px;
        right: -40px;
        box-shadow: 0 3px 6px #00000029;
        width: 60%;
        z-index: 1;
        color: $f-blue;
      }
    }
  }
</style>
