<template>
  <div>
    <v-container class="pa-0">
      <v-row>
        <v-col class="font-small font-weight-bold">
          {{ $t('intermediationUserContractInfo') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-1">
          {{ $t('contractAcceptance') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="font-small py-0">
          {{ $t('contractPreviewInfo') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-checkbox
            v-model="checked"
            class="mt-0 checkbox"
            :label="$t('accept')"
            @change="onCheckboxChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="3" md="5">
          <v-btn
            :disabled="!this.isInvestorContractAccepted"
            @click="completed()"
            class="w-100 btn-blue-rounded"
          >
            {{ $t("next") }}
          </v-btn>
        </v-col>
        <v-col xl="3" md="5">
          <v-btn
            @click="() => $router.push('/properties')"
            class="w-100 btn-blue-rounded"
          >
            {{ $t("exit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'IntermediationUserContact',
  async created() {
    this.isInvestorContractAccepted = this.user.is_investor_contract_accepted;
    this.checked = this.user.is_investor_contract_accepted;
  },
  data: () => ({
    isInvestorContractAccepted: null,
    checked: false,
  }),
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    ...mapActions('user', ['currentUser', 'updateUser']),
    async onCheckboxChange(value) {
      if (!value) this.isInvestorContractAccepted = false;
      await this.updateUser({
        is_investor_contract_accepted: value,
      });
      this.isInvestorContractAccepted = this.user.is_investor_contract_accepted;
    },
    completed() {
      this.$emit('completed');
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .checkbox .v-label {
  font-size: 14px;
}
</style>
