import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { reduce, findIndex } from 'lodash';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    fetched: false,
    properties: [],
    error: {
      isError: false,
      message: '',
    },
  },
  mutations: {
    SET_PROPERTIES: (state, properties) => {
      Vue.set(state, 'properties', properties);
      state.fetched = true;
    },
    REMOVE_PROPERTY: (state, propertyId) => {
      const i = findIndex(state.properties, { id: propertyId });
      state.properties.splice(i, 1);
    },
    UPDATE_PROPERTY: (state, updatedProperty) => {
      const i = state.properties.findIndex((property) => property.id === updatedProperty.id);
      state.properties[i] = updatedProperty;
    },
    SET_ERROR: (state, data) => {
      state.error = data;
    },
  },
  getters: {
    properties: (state) => state.properties,
    lots: (state) => reduce(state.properties, (memo, prop) => {
      memo.push(...prop.lots);
      return memo;
    }, []),
    propertiesError: (state) => state.error,
  },
  actions: {
    fetchProperties: async ({ commit }) => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_HOST_URL}/properties/`);
        commit('SET_PROPERTIES', response.data);
        return response;
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
        return error;
      }
    },
    getProperty: async ({ state, dispatch, commit }, id) => {
      try {
        if (!state.fetched) await dispatch('fetchProperties');
        return state.properties.find((p) => p.id === id);
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
      return null;
    },
    removeProperty: async ({ commit }, id) => {
      try {
        await axios
          .delete(`${process.env.VUE_APP_HOST_URL}/properties/${id}`)
          .then(() => commit('REMOVE_PROPERTY', id));
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
    },
    updateProperty: async ({ commit }, { property, updatedPropertyField, updatedValue }) => {
      try {
        await axios.patch(`${process.env.VUE_APP_HOST_URL}/properties/${property.id}/`, {
          [updatedPropertyField]: updatedValue,
        }).then((response) => {
          if (response && response.data) commit('UPDATE_PROPERTY', response.data);
        });
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
    },
    changePropertyStatus: async ({ commit, dispatch }, propertyId) => {
      try {
        await axios.patch(`${process.env.VUE_APP_HOST_URL}/properties/${propertyId}/change_status`, {
          status: 'added',
        }).then(async () => {
          await dispatch('fetchProperties');
        });
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
    },
  },
};
