<template>
  <v-col
    cols="12"
    md="6"
    class="mt-8"
  >
    <div class="absorbency">
      <h2 class="mb-4">{{ $t('absorbencyAnalysis') }}:</h2>
      <div
        class="absorbency-mpzp"
        v-for="(mpzp, index) in mpzps"
        :key="'item-' + index + Math.random()"
      >
        <p class="font-weight-bold">MPZP - {{ $t('details') }}</p>
        <p class="font-weight-bold">nr. {{ mpzp.plan_number }}</p>
        <p class="mt-7">{{ $t('resolutionNumber') }} {{ mpzp.resolution }}</p>
        <div class="mt-4" v-if="mpzp.resolution_link">
          <p> {{ $t('localPlan') }}:</p>
          <div class="pt-2 d-flex flex-column flex-md-row justify-space-between">
            <a :href="mpzp.resolution_link" target="_blank">
              <v-btn class="btn-blue-rounded mb-3 mb-md-0">
                <v-icon>mdi-cloud-download</v-icon>
                {{ $t('downloadResolution') }}
              </v-btn>
            </a>
          </div>
        </div>
        <div class="absorbency__analysis">
          <div v-for="(item, index) in mpzp.zones" :key="'item-' + index + Math.random()">
            <template v-if="item.lots.length">
              <p class="font-weight-bold mt-9">{{ $t('parametersMPZP') }} {{ item.name }}</p>
              <v-data-table
                :headers="headers"
                :items="item.lots"
                :loading="isZones"
                hide-default-footer
                class="mt-5"
              >
                <template v-slot:item.area="item">
                  <p class="small-text">{{ formatFloat(item.item.area) }}</p>
                </template>
                <template v-slot:item.is_built_up="item">
                  <p class="small-text">{{ getBoolTranslate(item.item.is_built_up) }}</p>
                </template>
              </v-data-table>
              <v-row class="mt-4">
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('destiny') }}</p>
                  <p class="font-weight-bold">{{ $t(item.zoning_category) }}</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('offs') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.exclusions"
                  >
                    {{ getBoolTranslate(item.exclusions) }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('builtArea') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.building_area_percentage"
                  >
                    {{ item.building_area_percentage }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('builtUndergroundArea') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.underground_architecture_area"
                  >
                    {{ item.underground_architecture_area }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('biologicallyActiveArea') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.biologically_active_area_percentage"
                  >
                    {{ item.biologically_active_area_percentage }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('undergroundIntensity') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.underground_intensity"
                  >
                    {{ item.underground_intensity }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('groundIntensity') }}</p>
                  <p
                    class="font-weight-bold "
                    v-if="item.intensity"
                  >
                    {{ item.intensity }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('numberFloors') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.number_of_storeys"
                  >
                    {{ item.number_of_storeys }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('buildingHeight') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.building_height"
                  >
                    {{ item.building_height }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('roofType') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.roof_type"
                  >
                    {{ $t(item.roof_type) }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('elevationTerrain') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.elevation_of_terrain"
                  >
                    {{ item.elevation_of_terrain }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                  v-if="checkZoningForParkSpots(item.zoning_category)"
                >
                  <p class="mb-1">{{ $t('parkingSpaces') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.ratio_of_parking_spots_per_flat"
                  >
                    {{ item.ratio_of_parking_spots_per_flat }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('bikeSpaces') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.ratio_of_bike_spots"
                  >
                    {{ item.ratio_of_bike_spots }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('recreationGreenery') }}</p>
                  <p
                    class="font-weight-bold"
                    v-if="item.ratio_of_recreational_greenery_per_m2"
                  >
                    {{ item.ratio_of_recreational_greenery_per_m2 }}
                  </p>
                  <p class="font-weight-bold" v-else>b/d</p>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <p class="mb-1 font-weight-bold">{{ $t('comment') }}</p>
                  <p>{{ item.first_comment }}</p>
                </v-col>
                <v-col cols="10" offset="1" class="absorbency__spacer mb-6"></v-col>
                <v-col
                  cols="6"
                  md="4"
                >
                  <p class="mb-1">{{ $t('buildingLineForce') }}</p>
                  <p class="font-weight-bold">{{ getBoolTranslate(item.has_applicable_building_line) }}</p>
                </v-col>
                <v-col
                  cols="6"
                  md="4"
                >
                  <p class="mb-1">{{ $t('buildingLineImpassible') }}</p>
                  <p class="font-weight-bold">{{ getBoolTranslate(item.has_impassable_building_line) }}</p>
                </v-col>
                <v-col
                  cols="6"
                  md="4"
                >
                  <p class="mb-1">{{ $t('protectionZone') }}</p>
                  <p class="font-weight-bold">{{ getBoolTranslate(item.is_conservation_protection_zone) }}</p>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <p class="mb-1 font-weight-bold">{{ $t('comment') }}</p>
                  <p>{{ item.second_comment }}</p>
                </v-col>
                <v-col cols="10" offset="1" class="absorbency__spacer mb-6"></v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('archeoProtectionZone') }}</p>
                  <p class="font-weight-bold">{{ getBoolTranslate(item.is_archeological_protection_zone) }}</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('inscribedResidentialReg') }}</p>
                  <p class="font-weight-bold">{{ getBoolTranslate(item.is_archeological_protection_zone) }}</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('roadAccess') }}</p>
                  <p class="font-weight-bold">{{ getBoolTranslate(item.has_road_accessibility) }}</p>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <p class="mb-1">{{ $t('environmentalProtection') }}</p>
                  <p class="font-weight-bold">
                    {{ getBoolTranslate(item.has_environmental_and_landscape_protection) }}
                  </p>
                </v-col>
                <v-col
                  cols="6"
                  md="12"
                >
                  <p class="mb-1 font-weight-bold">{{ $t('other') }}</p>
                  <p>{{ getBoolTranslate(item.other) }}</p>
                </v-col>
                <v-col cols="10" offset="1" class="absorbency__spacer mb-6"></v-col>
              </v-row>
            </template>
          </div>
        </div>
      </div>
      <v-row>
        <v-col
          cols="6"
          md="12"
        >
        </v-col>
      </v-row>
      <div
        class="absorbency-mpzp"
        v-for="(mpzp, index) in mpzps"
        :key="'item-' + index + Math.random()"
      >
        <div
          v-for="(item, index) in mpzp.zones"
          :key="'item-' + index + Math.random()"
        >
          <template v-if="item.lots.length">
            <p class="font-weight-bold my-4">{{ item.name }} - {{ $t(item .zoning_category) }}</p>
            <v-data-table
              :headers="headers2"
              :items="item.lots"
              :loading="isZones"
              hide-default-footer
              class="mt-5"
            >
              <template v-slot:item.shortened_lot_id="item">
                <p class="small-text">{{ item.item.shortened_lot_id }}</p>
              </template>
              <template v-slot:item.area="item">
                <p class="small-text">{{ Math.round(item.item.area) }}</p>
              </template>
              <template v-slot:item.pum="item">
                <p class="small-text">{{ Math.round(item.item.pum) }}</p>
              </template>
              <template v-slot:item.puu="item">
                <p class="small-text">{{ Math.round(item.item.puu) }}</p>
              </template>
              <template v-slot:item.is_built_up="item">
                <p class="small-text">{{ getBoolTranslate(item.item.is_built_up) }}</p>
              </template>
            </v-data-table>
          </template>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { isUndefined } from 'lodash';

export default {
  name: 'Absorbency',
  data: (vm) => ({
    headers: [
      { text: vm.$t('parcels'), value: 'shortened_lot_id' },
      { text: vm.$t('area'), value: 'area' },
      { text: vm.$t('builtUp'), value: 'is_built_up' },
    ],
    headers2: [
      { text: vm.$t('parcels'), value: 'shortened_lot_id' },
      { text: vm.$t('area'), value: 'area' },
      { text: vm.$t('pum'), value: 'pum' },
      { text: vm.$t('puu'), value: 'puu' },
      { text: vm.$t('builtUp'), value: 'is_built_up' },
    ],
  }),
  computed: {
    ...mapGetters('announcement', ['capacity', 'mpzps', 'calculations']),
    isCalculations() {
      return isUndefined(this.calculations);
    },
    isZones() {
      return isUndefined(this.mpzps);
    },
  },
  methods: {
    getBoolTranslate(item) {
      if (item === false) {
        return this.$t('false');
      }
      if (item === true) {
        return this.$t('true');
      }
      if (item === null) {
        return this.$t('null');
      }
      return item;
    },
    checkZoningForParkSpots(item) {
      return item === 'single_family_flat' || item === 'multi_family_flat';
    },
    formatFloat(float) {
      return parseFloat(parseFloat(float).toFixed(2)).toLocaleString('en').replace(/,/g, ' ');
    },
  },
};
</script>

<style lang="scss">
  .absorbency {
    &__details,
    &__analysis{
      img { max-width: 100%}
    }
    &__analysis {
      margin-top: 30px;
    }
    &__spacer {
      border-bottom: 1px solid $f-grey;
    }
    .v-data-table-header {
      tr:first-child {
        background: $f-solitude;
      }
    }
  }
</style>
