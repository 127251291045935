<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="mb-5">
          <router-link :to="{ name: 'properties' }">
            <v-icon class="font-blue">mdi-menu-left</v-icon>
            <span class="small font-weight-bold">{{ $t('backAnnouncementsList') }}</span>
          </router-link>
        </div>
        <PropertyOffer v-if="this.propertyOffers"/>
        <PropertyOffersList v-if="this.propertyOffers"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PropertyOffer from '@/components/offers/PropertyOffer.vue';
import PropertyOffersList from '@/components/offers/PropertyOffersList.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Offers',
  data: () => ({
  }),
  components: {
    PropertyOffersList,
    PropertyOffer,
  },
  created() {
    const { propertyId } = this.$route.params;
    const data = {
      property: propertyId,
      type: 'new_offer',
    };
    this.getPropertyOffers(propertyId);
    this.clearNotification(data);
  },
  computed: {
    ...mapGetters('propertyOffers', ['propertyOffers']),
  },
  methods: {
    ...mapActions('propertyOffers', ['getPropertyOffers']),
    ...mapActions('notifications', ['clearNotification']),
  },
};
</script>
