<template>
  <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <h5>{{ $t('profileName')}}:</h5>
          <InvestorProfiles
            @goToParcel="navigateToParcel"
            :profiles="investorProfiles"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <Map :parcels="lots" :goToParcelOnStart="goToParcelOnStart" ref="map" />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Map from '@/components/Map.vue';
import InvestorProfiles from '@/components/investorProfile/InvestorProfiles.vue';
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'InvestorProfile',
  data: () => ({
    parcelToZoomIn: null,
    goToParcelOnStart: false,
  }),
  created() {
    if (isEmpty(this.investorProfile)) {
      this.getInvestorProfiles();
    }
  },
  components: {
    InvestorProfiles,
    Map,
  },
  computed: {
    ...mapGetters('properties', ['lots']),
    ...mapGetters('user', ['investorProfiles', 'matches']),
    lots() {
      return this.matches.flatMap((match) => match.lot_ids).map((id) => ({ lot_id: id }));
    },
  },
  methods: {
    ...mapActions('user', ['getInvestorProfiles']),
    navigateToParcel(data) {
      this.$refs.map.goToParcel(data);
    },
  },
};
</script>
