<template>
  <v-app>
    <v-container fluid class="login py-md-0">
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="0"
          class="d-flex align-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="10"
              lg="8"
              offset-md="1"
              offset-lg="2"
            >
              <h1 class="login__header">{{ $t('goodToSeeYou') }}!</h1>
              <p class="login__subheader">{{ $t('logOrRegister')}}</p>
              <v-tabs
                v-model="tab"
                fixed-tabs
                active-class="active"
                class="mt-7"
              >
                <v-tab href="#tab-1">{{ $t('login') }}</v-tab>
                <v-tab href="#tab-2">{{ $t('register') }}</v-tab>
                <v-tab-item
                  value="tab-1"
                  transition="fade-transition"
                  reverse-transition="fade-transition"
                >
                  <LoginUser />
                </v-tab-item>
                <v-tab-item
                  value="tab-2"
                  transition="fade-transition"
                  reverse-transition="fade-transition"
                >
                  <RegisterUser @backToLogin="this.backToLogin"/>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="d-none d-md-block pr-md-0 py-md-0">
          <div class="login-background">
            <img src="../assets/images/background.jpg">
            <div class="login-background__content">
              <p class="login-background__content--text">
                {{ $t('loginText')}}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import LoginUser from '@/components/LoginUser.vue';
import RegisterUser from '@/components/RegisterUser.vue';

export default {
  name: 'Login',
  data: () => ({
    tab: null,
  }),
  components: { LoginUser, RegisterUser },
  methods: {
    backToLogin() {
      this.tab = 'tab-1';
    },
  },
};
</script>

<style lang="scss">
  .login {
    @include respond-to(sm) {
      background-image: image-set(
          '../assets/images/background.jpg' 1x
      );
      background-size: cover;
      background-position: center;
      min-height:100vh;
    }
    &__header,
    &__subheader {
      @include respond-to(sm) {
        color: $white;
      }
    }
    &-background {
      max-height: 100vh;
      position: relative;
      &__content {
        position: absolute;
        left: 0;
        top: 30%;
        background: $white;
        width: 70%;
        padding: 25px 10px;
        @include respond-to(md) {
          width: 90%;
        }
        &--text {
          font-weight: bold;
          font-size: 30px;
        }
      }
      img {
        max-height: 100vh;
        width: 100%;
      }
    }
    .v-label {
      @include respond-to(sm) {
        font-size: 12px;
      }
    }
    .v-tab {
      &.active {
        font-weight: bold;
      }
    }
    .v-input__icon.v-input__icon--prepend-inner .v-icon {
      color: $f-blue;
    }
    .v-input__slot::before {
      border-color: $f-blue!important;
    }
  }
</style>
