<template>
  <v-container class="pa-0">
    <v-row>
      <v-col class="pb-1">
        {{ $t('shipmentDetailsInfo') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="checked"
          class="mt-0 checkbox"
          :label="$t('usePreviousData')"
          @change="onCheckboxChange"
        />
      </v-col>
    </v-row>
    <v-form v-model="isDataValid">
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.first_name"
            :label="$t('first_name')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.last_name"
            :label="$t('last_name')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.company_name"
            :label="$t('company_name')"
            class="no-required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.street_name"
            :label="$t('street')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.street_number"
            :label="$t('building_number')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.postal_code"
            :label="$t('postal_code')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.city"
            :label="$t('city')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="delivery_address.phone_number"
            :label="$t('phone_number')"
            :rules="phoneRules.concat(requiredRule)"
          />
        </v-col>
      </v-row>
      <v-alert
        class="mt-5"
        type="error"
        v-model="error.isError"
        dismissible
        transition="fade-transition"
      >
        {{error.message}}
      </v-alert>
      <v-row>
        <v-col cols="12" md="8">
          <div class="text-left">
            <v-btn
              :disabled="!isDataValid"
              class="mx-auto elevation-0 btn-blue-rounded"
              :loading="submitting"
              @click="onApproveButtonClick"
            >
              {{ $t('approve') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import validations from '@/mixins/validations';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShippingAddress',
  props: [
    'currentOwner',
  ],
  watch: {
    currentOwner() {
      this.resetDeliveryAddress();
      this.checked = false;
    },
  },
  data: (vm) => ({
    submitting: false,
    checked: false,
    isDataValid: false,
    delivery_address: {
      first_name: null,
      last_name: null,
      company_name: null,
      street_name: null,
      street_number: null,
      postal_code: null,
      city: null,
      phone_number: null,
    },
    phoneRules: [
      (v) => (v && v.length === 9 && /^\d+$/.test(v)) || vm.$t('invalidPhone'),
    ],
  }),
  computed: {
    ...mapGetters('deliveryAddress', ['error']),
  },
  methods: {
    ...mapActions('deliveryAddress',
      [
        'updateDeliveryAddress',
        'createDeliveryAddress',
      ]),
    onCheckboxChange(value) {
      if (value) {
        this.setDeliveryAddress();
      } else {
        this.resetDeliveryAddress();
      }
      this.checked = value;
    },
    setDeliveryAddress() {
      if (this.currentOwner) {
        this.delivery_address.first_name = this.currentOwner.first_name;
        this.delivery_address.last_name = this.currentOwner.last_name;
        this.delivery_address.company_name = this.currentOwner.company_name;
        this.delivery_address.street_name = this.currentOwner.street_name;
        this.delivery_address.street_number = this.currentOwner.street_number;
        this.delivery_address.postal_code = this.currentOwner.postal_code;
        this.delivery_address.city = this.currentOwner.city;
        this.delivery_address.phone_number = this.currentOwner.phone_number.slice(1);
      }
    },
    resetDeliveryAddress() {
      Object.keys(this.delivery_address).forEach((i) => { this.delivery_address[i] = null; });
    },
    async onApproveButtonClick() {
      this.submitting = true;
      let deliveryAddressResponse = null;
      if (this.currentOwner.delivery_address) {
        deliveryAddressResponse = await this.updateDeliveryAddress({
          id: this.currentOwner.delivery_address,
          data: {
            ...this.delivery_address,
            phone_number: `+${this.delivery_address.phone_number}`,
          },
        });
      } else {
        deliveryAddressResponse = await this.createDeliveryAddress({
          data: {
            ...this.delivery_address,
            phone_number: `+${this.delivery_address.phone_number}`,
          },
        });
      }
      await this.completed(deliveryAddressResponse);
      this.submitting = false;
    },
    completed(data) {
      this.$emit('completedDeliveryForm', data);
    },
  },
  mixins: [
    validations,
  ],
};
</script>

<style scoped lang="scss">
  ::v-deep .checkbox .v-label {
    font-size: 14px;
  }
  ::v-deep .v-text-field__slot {
    .v-label:after {
      content: ' *';
      color: red;
    }
  }
  ::v-deep .no-required {
    .v-label:after {
      content: '';
    }
  }
</style>
