<template>
  <div id="galleryContainer" class="gallery" v-if="announcement">
    <v-img class="gallery__largeView mb-1" :src="this.largeView" />
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        v-for="(image, index) in gallery"
        :key="index"
        @click.native="changeLargeView(image)"
        class="gallery__item"
      >
        <v-img :src="image "/>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import { map, isUndefined } from 'lodash';

export default {
  data() {
    return {
      swiperOption: {
        autoHeight: true,
        slidesPerView: 4,
        spaceBetween: 5,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      largeView: null,
      componentKey: 0,
    };
  },
  props: {
    announcement: {
      type: Object,
    },
    capacity: {
      type: Object,
    },
  },
  computed: {
    gallery() {
      const pictures = [];

      if (this.announcement) {
        if (this.announcement.main_picture) {
          pictures.push(this.announcement.main_picture);
        }

        pictures.push(...map(this.announcement.pictures, (item) => ({
          src: item,
        })));
      }

      if (this.capacity) {
        if (this.capacity.osm) pictures.push({ src: this.capacity.osm });
        if (this.capacity.ortofoto_map) pictures.push({ src: this.capacity.ortofoto_map });
        if (this.capacity.mpzp_map) pictures.push({ src: this.capacity.mpzp_map });
        if (this.capacity.gugik) pictures.push({ src: this.capacity.gugik });
      }

      [this.largeView] = pictures;

      return pictures;
    },
  },
  watch: {
    announcement(val, oldVal) {
      if (isUndefined(oldVal) && val) {
        [this.largeView] = val.pictures;
      }
    },
  },
  methods: {
    changeLargeView(src) {
      this.largeView = src;
    },
  },
};
</script>

<style lang="scss">
  .gallery {
    &__largeView {
      height: 450px;
    }
    &__item {
      cursor: pointer;
      &:hover {
        border: 1px solid $white;
      }
    }
  }
  .v-responsive__sizer {
    padding-bottom: 96%!important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 45px!important;
    background: #DDDDDD99 0 0 no-repeat padding-box;
    opacity: 0.5;
    border-radius: 50%;
    &:after {
      font-size: 24px!important;
      font-weight: bold;
      color: $black;
    }
    &:hover {
      opacity: 1;
    }
  }
</style>
