import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { set } from '../../helpers/mutations';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    notification: null,
  },
  mutations: {
    ...set('notification'),
  },
  getters: {
    notification: (state) => state.notification,
  },
  actions: {
    getNotifications: ({ commit }) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/notifications/me/`)
      .then((notification) => {
        commit('setNotification', notification.data);
      }),
    clearNotification: ({ commit }, data) => axios
      .post(`${process.env.VUE_APP_HOST_URL}/notifications/clear/`, data)
      .then((notification) => {
        commit('setNotification', notification.data);
      }),
  },
};
