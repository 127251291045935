<template>
  <v-form
    v-model="valid"
    ref="form"
    :lazy-validation="lazy"
    @keyup.enter.native="validate"
    class="mt-md-4 pa-5 pa-md-0"
  >
    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      prepend-inner-icon="mdi-account"
      required
    ></v-text-field>
    <v-text-field
      v-model="password"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="passwordRules"
      :type="show ? 'text' : 'password'"
      name="input-10-1"
      :label="$t('password')"
      :hint="$t('eightChars')"
      prepend-inner-icon="mdi-key-variant"
      @click:append="show = !show"
    ></v-text-field>
    <div class="d-flex justify-center mt-5">
      <v-btn
        :disabled="!valid"
        class="px-8 w-100 btn-gradient-blue"
        @click="validate"
        rounded
      >
        {{ $t('login') }}
      </v-btn>
    </div>
    <router-link :to="{ name: 'reset' }">
      <p class="mt-5">{{ $t('forgot') }}?</p>
    </router-link>
    <v-alert
      class="mt-5"
      type="error"
      v-model="validationErrors.show"
      dismissible
      transition="fade-transition"
    >
      <p
        v-for="error in validationErrors.messages"
        :key="error"
      >
        {{ error }}
      </p>
    </v-alert>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import { findAllByKey } from '@/helpers/functions';

export default {
  name: 'Login',
  data() {
    return {
      tab: null,
      validationErrors: {
        show: false,
        messages: [],
      },
      valid: false,
      email: '',
      emailRules: [
        (v) => !!v || this.$i18n.t('emailRequired'),
        // eslint-disable-next-line max-len
        (v) => /^[a-zA-Z0-9.+`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(v) || this.$i18n.t('emailMustBeValid'),
      ],
      password: '',
      passwordRules: [
        (value) => !!value || this.$i18n.t('required'),
        (v) => v.length >= 8 || this.$i18n.t('eightChars'),
      ],
      show: false,
      lazy: false,
      steps: {},
    };
  },
  methods: {
    ...mapActions('user', ['logInUser']),
    ...mapActions('user', ['currentUser']),
    ...mapActions('user', ['getInvestorProfiles']),
    ...mapActions('properties', ['fetchProperties']),
    validate() {
      if (this.$refs.form.validate()) {
        const data = {
          email: this.email,
          password: this.password,
        };
        this.logInUser(data).then((response) => {
          if (response.status === 200) {
            window.localStorage.setItem('investor', false);
            window.localStorage.setItem('logged', true);
            this.currentUser().then((user) => {
              if (user.data.type === 'investor') {
                window.localStorage.setItem('investor', true);
                const uuid = user.data.id;
                if (user.data.is_investor_contract_accepted) {
                  this.getInvestorProfiles().then((profile) => {
                    if (!profile.data.length) {
                      this.$router.push({ name: 'investorProfile', params: { uuid } });
                    } else {
                      this.$router.push({ name: 'myParcels' });
                    }
                  });
                } else {
                  this.$router.push({ name: 'userContract' });
                }
              } else {
                this.fetchProperties().then((properties) => {
                  if (!properties.data.length) {
                    this.$router.push({ name: 'newProperty' });
                  } else {
                    this.$router.push({ name: 'myParcels' });
                  }
                });
              }
            });
          }
        }).catch((error) => {
          this.validationErrors.show = true;
          this.validationErrors.messages = [];
          const errors = findAllByKey(error.response.data, 'message');
          this.validationErrors.messages.push(...errors);
        });
      }
    },
  },
};
</script>
