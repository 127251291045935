<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        {{ $t("price") }}
      </v-card-title>
        <v-card-text class="proposal-card">
          <currency-input
            v-model="newPrice"
            currency="PLN"
            locale="pl"
            class="proposal-card__input"
            :allow-negative=false
          />
          <v-alert
            class="mt-5"
            type="error"
            v-model="error"
            dismissible
            transition="fade-transition"
          >
            {{ $t('toLowPrice') }}
          </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Proposal',
  created() {
    if (this.price != null) {
      this.newPrice = this.price;
    }
  },
  data: () => ({
    newPrice: null,
    error: false,
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['price']),
  },
  methods: {
    ...mapActions('newPropertyForm', ['deletePrice', 'updatePrice']),
    completeStep(completed) {
      this.$emit('stepCompleted', completed);
    },
  },
  watch: {
    newPrice(newVal) {
      if (newVal == null || newVal === 0) {
        this.deletePrice();
        this.completeStep(false);
      } else if (newVal < 10000) {
        this.completeStep(false);
        this.error = true;
      } else {
        this.updatePrice(this.newPrice);
        this.completeStep(true);
        this.error = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .proposal-card {
    &__input {
      width: 100%;
      padding: 2px 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      &:focus {
        outline: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
      }
    }
  }
</style>
