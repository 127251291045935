<template>
  <div class="announcement">
    <v-container fluid>
    <v-row id="announcement">
      <v-col
        cols="12"
        md="6"
      >
        <GeneralInfo
          v-if="announcement"
          :announcement="this.announcement"
          :capacity="this.capacity"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        @scroll="galleryScroll"
        id="gallery"
      >
        <Gallery
          :announcement="this.announcement"
          :capacity="this.capacity"
        />
      </v-col>
    </v-row>
    <v-row>
      <LawStatus />
      <Absorbency v-if="announcement" />
    </v-row>
  </v-container>
    <div class="fixed w-100 bottom-0 pr-md-5 bg-white z-999">
    <v-container fluid>
      <v-row v-if="!this.isPreview">
          <v-col
            cols="12"
            class="offer__actions"
          >
            <Actions />
          </v-col>
      </v-row>
    </v-container>
  </div>
  </div>
</template>

<script>
import Absorbency from '@/components/announcement/CapacityAnalisys.vue';
import Actions from '@/components/announcement/Actions.vue';
import GeneralInfo from '@/components/announcement/GeneralInfo.vue';
import Gallery from '@/components/announcement/Gallery.vue';
import LawStatus from '@/components/announcement/LawStatus.vue';
import { mapGetters, mapActions } from 'vuex';
import { isUndefined } from 'lodash';

export default {
  name: 'Announcement',
  data() {
    return {
      lastScrollTop: 0,
    };
  },
  created() {
    const { token } = this.$route.params;
    const { propertyId } = this.$route.params;
    if (this.isPreview) {
      this.getAnnouncementPreview({ propertyId, token });
    } else {
      this.getAnnouncement(propertyId);
    }
    window.addEventListener('scroll', this.galleryScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.galleryScroll);
  },
  components: {
    Absorbency,
    Actions,
    Gallery,
    GeneralInfo,
    LawStatus,
  },
  computed: {
    ...mapGetters('announcement', ['announcement', 'capacity']),
    isPreview() {
      const { token } = this.$route.params;
      return !isUndefined(token);
    },
  },
  methods: {
    ...mapActions('announcement', ['getAnnouncement', 'getAnnouncementPreview']),
    galleryScroll() {
      if (this.announcement) {
        const windowWidth = window.innerWidth;
        const gallery = document.querySelector('#gallery');
        const galleryContainer = document.querySelector('#galleryContainer');
        const container = document.querySelector('#info');
        const parent = document.querySelector('#announcement');
        const maxHeight = container.offsetHeight - galleryContainer.offsetHeight;
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (windowWidth >= 950) {
          if (st > this.lastScrollTop) {
            gallery.classList.remove('fixed-bottom');
            if (window.scrollY > galleryContainer.offsetTop) {
              if (window.scrollY > galleryContainer.offsetHeight || window.scrollY > maxHeight + 90) {
                gallery.classList.add('absolute');
                parent.classList.add('relative');
                gallery.classList.remove('fixed');
              } else {
                gallery.classList.add('fixed');
                gallery.classList.remove('absolute');
                parent.classList.remove('relative');
              }
            } else {
              gallery.classList.remove('fixed');
              gallery.classList.remove('absolute');
              parent.classList.remove('relative');
            }
          } else if (window.scrollY > galleryContainer.offsetTop) {
            if (window.scrollY > galleryContainer.offsetHeight || window.scrollY > maxHeight - 100) {
              gallery.classList.add('absolute');
              parent.classList.add('relative');
              gallery.classList.remove('fixed');
            } else {
              gallery.classList.add('fixed-bottom');
              gallery.classList.remove('absolute');
              parent.classList.remove('relative');
            }
          } else if (window.scrollY > galleryContainer.offsetTop - 30) {
            gallery.classList.remove('fixed-bottom');
            gallery.classList.remove('absolute');
            parent.classList.remove('relative');
          }
          this.lastScrollTop = st <= 0 ? 0 : st;
        }
      }
    },
  },
};
</script>
<style lang="scss">
  .announcement {
    padding-bottom: 110px;
    @include respond-to(md) {
      padding-bottom: 220px;
    }
    p {
      font-size: 14px;
    }
  }
  #gallery {
    right: 0;
    &.fixed {
      top: 0;
    }
    &.fixed-bottom {
      position: fixed;
      bottom: 90px;
    }
    &.absolute {
      bottom: 0;
    }
  }
  @keyframes smoothScroll {
    0% {
      transform: translateY(-873px);
    }
    100% {
      transform: translateY(-90px);
    }
  }
</style>
