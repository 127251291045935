<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-center" v-if="loading">
          <div class="loader-ring" />
        </div>
        <div class="confirmation text-center mt-5" v-if="success">
          <h1>{{ $t('confirmedAccount') }}!</h1>
          <h4 class="mt-4">{{ $t('confirmedMsg') }}</h4>
          <router-link
            :to="{ name: 'login'}"
            class="text-center"
          >
            <v-btn rounded class="mt-5 mb-1 px-10 btn-blue-rounded">
              {{ $t('login') }}
            </v-btn>
          </router-link>
        </div>
        <div class="confirmation text-center mt-5" v-if="activated">
          <h1>{{ $t('confirmedUser') }}!</h1>
          <h4 class="mt-4">{{ $t('confirmedMsg') }}</h4>
          <router-link
            :to="{ name: 'login'}"
            class="text-center"
          >
            <v-btn rounded class="mt-5 mb-1 px-10 btn-blue-rounded">
              {{ $t('login') }}
            </v-btn>
          </router-link>
        </div>
        <div class="confirmation text-center mt-5" v-if="error">
          <h1>{{ $t('somethingWentWrong') }}!</h1>
          <h4 class="mt-4">{{ $t('confirmErrorMsg') }}</h4>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Confirmation',
  data: () => ({
    loading: true,
    success: false,
    error: false,
    activated: false,
  }),
  created() {
    const data = {
      uid: this.$route.params.uuid,
      token: this.$route.params.token,
    };
    this.activateUser(data)
      .then((response) => {
        if (response.status === 204) {
          this.loading = false;
          this.success = true;
        } else if (response.status === 400) {
          this.loading = false;
          this.error = true;
        } else if (response.status === 403) {
          this.loading = false;
          this.activated = true;
        }
      });
  },
  methods: {
    ...mapActions('user', ['activateUser']),
  },
};
</script>
