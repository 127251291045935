<template>
  <v-container class="text-center" v-if="fetchingData">
    <v-progress-circular indeterminate/>
  </v-container >
  <v-container v-else class="pa-0">
    <v-row>
      <v-col class="pb-1">
        {{ $t('resignationFromContractCancel') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="font-small py-0">
        {{ $t('contractPreviewInfo') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col xl="2" sm="4" class="pt-0 col">
        <v-checkbox
          v-model="isAcceptChecked"
          class="mt-0 checkbox"
          :label="$t('accept')"
          @change="onAcceptCheckboxChange"
        />
      </v-col>
      <v-col xl="2" sm="4" class="pt-0">
        <v-checkbox
          v-model="isSkipChecked"
          class="mt-0 checkbox"
          :label="$t('skip')"
          @change="onSkipCheckboxChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col xl="3" md="5">
        <v-btn
          :disabled="!isSkipChecked && !isAcceptChecked"
          :loading="submitting"
          @click="onNextButtonClick()"
          class="w-100 btn-blue-rounded"
        >
          {{ $t("next") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="font-small pt-0">
        {{ $t('resignationInfo') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ContractCancelResignation',
  async created() {
    if (this.$route.params.propertyId) {
      this.fetchingData = true;
      await this.fetchProperties();
      this.currentProperty = await this.getProperty(this.$route.params.propertyId);
      this.fetchingData = false;
    }
  },
  data: () => ({
    currentProperty: {
      contract_cancel_resignation: null,
    },
    fetchingData: false,
    submitting: false,
    isSkipChecked: false,
    isAcceptChecked: false,
  }),
  methods: {
    ...mapActions('properties', ['getProperty', 'updateProperty', 'fetchProperties']),
    onSkipCheckboxChange() {
      this.isAcceptChecked = false;
      this.currentProperty.contract_cancel_resignation = false;
    },
    onAcceptCheckboxChange() {
      this.isSkipChecked = false;
      this.currentProperty.contract_cancel_resignation = true;
    },
    async onNextButtonClick() {
      this.submitting = true;
      await this.updateProperty({
        property: this.currentProperty,
        updatedPropertyField: 'contract_cancel_resignation',
        updatedValue: this.currentProperty.contract_cancel_resignation,
      });
      this.currentProperty = await this.getProperty(this.$route.params.propertyId);
      this.submitting = false;
      await this.completed();
    },
    completed() {
      this.$emit('completed');
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .checkbox .v-label {
    font-size: 14px;
  }
</style>
