<template>
  <v-app>
    <v-container
      fluid
      class="investor-profile"
      :class="{'success-profile' : success }"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-row>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >
              <h3 class="font-weight-bold">{{ $t('addInvestProfile')}}</h3>
              <v-form
                v-model="valid"
                ref="form"
                :lazy-validation="lazy"
                @keyup.enter.native="validate"
                v-if="!success"
              >
                <div class="investor-profile__radio my-5">
                  <p class="text-center text-uppercase font-weight-bold mt-5 mb-3">{{ $t('searching') }}:</p>
                  <div class="d-flex align-center justify-center">
                    <v-radio-group
                      v-model="type"
                      :rules="[v => !!v || 'Required']"
                      required
                    >
                      <v-radio
                        label="Działki"
                        value="parcel"
                      ></v-radio>
                      <v-radio
                        label="Budynku"
                        value="building"
                        disabled
                      ></v-radio>
                  </v-radio-group>
                  </div>
                </div>
                <v-text-field
                  v-model="name"
                  :label="$t('profilName')"
                  :rules="requiredRule"
                  required
                  class="my-2 mb-10"
                />
                <v-select
                  v-model="address.voivodeship"
                  :items="voivodeships"
                  menu-props="auto"
                  :label="$t('typeProvince')"
                  :rules="requiredRule"
                  @blur="search"
                  required
                  class="my-2"
                  :disabled="!address.country"
                />
                <v-select
                  v-model="address.county"
                  :items="counties(this.address.voivodeship)"
                  menu-props="auto"
                  :label="$t('typeCounty')"
                  class="my-2 no-required"
                  :disabled="!address.voivodeship"
                  @blur="search"
                />
                <v-text-field
                  v-model="address.community"
                  :label="$t('typeCommunity')"
                  class="my-2 no-required"
                />
                <v-text-field
                  v-model="address.city"
                  :label="$t('typeCity')"
                  class="my-2 no-required"
                  @blur="search"
                />
                <v-text-field
                  v-model="address.street"
                  :label="$t('typeStreet')"
                  @blur="search"
                  class="my-2 no-required"
                />
                <v-select
                  v-model="zoning_category"
                  :items="categories"
                  menu-props="auto"
                  :label="$t('destiny')"
                  :rules="requiredRule"
                  required
                  class="my-2"
                >
                  <template v-slot:item="{ item }">
                    {{ $t(item) }}
                  </template>

                  <template v-slot:selection="{ item }">
                    {{ $t(item) }}
                  </template>
                </v-select>
                <v-select
                  v-if="type === 'building'"
                  v-model="buildingType"
                  :items="buildings"
                  menu-props="auto"
                  :label="$t('buildingType')"
                  :rules="requiredRule"
                  required
                  class="my-2"
                >
                  <template v-slot:item="{ item }">
                    {{ $t(item) }}
                  </template>

                  <template v-slot:selection="{ item }">
                    {{ $t(item) }}
                  </template>
                </v-select>
                <p class="my-5">{{ $t('surface')}}</p>
                <div class="investor-profile__slider">
                  <v-text-field
                    :value="surface[0]"
                    class="investor-profile__slider--left no-required"
                    :label="$t('minSurf')"
                    type="number"
                    @change="$set(surface, 0, $event)"
                    oninput="value === '' ? value = 0 : value < 0 ? value = value * -1 : false"
                    min="0.1"
                  />
                  <v-text-field
                    :value="surface[1]"
                    class="investor-profile__slider--right no-required"
                    :label="$t('maxSurf')"
                    type="number"
                    @change="$set(surface, 1, $event)"
                    oninput="value === '' ? value = 0 : value < 0 ? value = value * -1 : false"
                  />
                </div>
                <v-range-slider
                  v-model="surface"
                  :max="maxSurf"
                  :min="minSurf"
                  hide-details
                  step="0.01"
                  class="align-center my-2"
                />
                <p class="my-4">{{ $t('enterPum')}}</p>
                <div class="investor-profile__slider">
                  <v-text-field
                    :value="pum[0]"
                    class="investor-profile__slider--left no-required"
                    :label="$t('pumMin')"
                    type="number"
                    @change="$set(pum, 0, $event)"
                    oninput="value === '' ? value = 0 : value < 0 ? value = value * -1 : false"
                    min="0.1"
                  />
                  <v-text-field
                    :value="pum[1]"
                    class="investor-profile__slider--right no-required"
                    :label="$t('pumMax')"
                    type="number"
                    @change="$set(pum, 1, $event)"
                    oninput="value === '' ? value = 0 : value < 0 ? value = value * -1 : false"
                  />
                </div>
                <v-range-slider
                  v-model="pum"
                  :max="maxPUMPUU"
                  :min="minSurf"
                  hide-details
                  class="align-center my-2"
                />
                <p class="my-4">{{ $t('enterPuu')}}</p>
                <div class="investor-profile__slider">
                  <v-text-field
                    :value="puu[0]"
                    class="investor-profile__slider--left no-required"
                    :label="$t('puuMin')"
                    type="number"
                    @change="$set(puu, 0, $event)"
                    oninput="value === '' ? value = 0 : value < 0 ? value = value * -1 : false"
                    min="0.1"
                  />
                  <v-text-field
                    :value="puu[1]"
                    class="investor-profile__slider--right no-required"
                    :label="$t('puuMax')"
                    type="number"
                    @change="$set(puu, 1, $event)"
                    oninput="value === '' ? value = 0 : value < 0 ? value = value * -1 : false"
                  />
                </div>
                <v-range-slider
                  v-model="puu"
                  :max="maxPUMPUU"
                  :min="minSurf"
                  hide-details
                  class="align-center my-2"
                />
                <p class="mt-5">{{ $t('enterPrice')}}</p>
                <div class="d-flex justify-lg-space-between mt-3">
                  <span>{{ $t('minPrice') }}</span>
                  <span>{{ $t('maxPrice') }}</span>
                </div>
                <div class="investor-profile__slider">
                  <currency-input
                    :value="price[0]"
                    class="investor-profile__slider--left"
                    :label="$t('minPrice')"
                    @change="$set(price, 0, $event)"
                    currency="PLN"
                    locale="pl"
                    :allow-negative=false
                  />
                  <currency-input
                    :value="price[1]"
                    class="investor-profile__slider--right"
                    :label="$t('maxPrice')"
                    @change="$set(price, 1, $event)"
                    currency="PLN"
                    locale="pl"
                    :allow-negative=false
                  />
                </div>
                <v-range-slider
                  v-model="price"
                  :max="maxPrice"
                  :min="minSurf"
                  hide-details
                  step="100"
                  class="align-center"
                />
                <p class="mb-4 mt-1"><span class="red--text">*</span> {{ $t('requiredField')}}</p>
                <div
                  class="d-flex mt-5"
                  :class="[isEditMode ? 'justify-space-between' : 'justify-center' ]"
                >
                  <router-link
                    v-if="isEditMode"
                    :to="{ name: 'investorProfile', params: { uuid: this.$route.params.uuid } }"
                  >
                    <v-btn
                      class="px-8"
                      rounded
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                  </router-link>
                  <v-btn
                    :disabled="!valid"
                    class="px-8 btn-gradient-blue"
                    @click="validate"
                    rounded
                  >
                    {{ $t('save') }}
                  </v-btn>
                </div>
              </v-form>
              <div v-else class="mt-12">
                <h2 class="text-center">{{ $t('investorProfileSuccess')}}</h2>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div id="map" />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import validations from '@/mixins/validations';
import { mapActions, mapGetters } from 'vuex';
import buildingsData from '@/data/building_types.json';
import categoriesData from '@/data/zoning_category.json';
import voivodeshipsData from '@/data/voivodeships.json';
import countriesData from '@/data/countries.json';
import countiesData from '@/data/counties.json';
import { isNull } from 'lodash';

let marker;

export default {
  name: 'investorProfileForm',
  data() {
    return {
      address: {
        city: null,
        country: 'Polska',
        county: null,
        voivodeship: null,
        street: null,
      },
      name: null,
      zoning_category: '',
      map: null,
      maxZoom: 20,
      lazy: false,
      valid: false,
      type: null,
      building_profile_type: '',
      surface: [0, 100000],
      maxSurf: 10000,
      maxPUMPUU: 250000,
      minSurf: 0,
      puu: [0, 1000000],
      pum: [0, 1000000],
      price: [0, 100000000],
      maxPrice: 100000000,
      success: false,
    };
  },
  mixins: [
    validations,
  ],
  created() {
    if (this.$route.params.profileId) {
      this.getInvestorProfile(this.$route.params.profileId).then((response) => {
        this.name = response.data.name;
        this.address = response.data.address;
        this.type = response.data.type;
        this.building_profile_type = response.data.building_profile_type;
        this.zoning_category = response.data.parcel_profile_type.zoning_category;
        this.surface = [
          Number(response.data.parcel_profile_type.area_min) / 10000,
          Number(response.data.parcel_profile_type.area_max) / 10000,
        ];
        this.puu = [
          Number(response.data.parcel_profile_type.puu_min),
          Number(response.data.parcel_profile_type.puu_max),
        ];
        this.pum = [
          Number(response.data.parcel_profile_type.pum_min),
          Number(response.data.parcel_profile_type.pum_max),
        ];
        this.price = [
          Number(response.data.parcel_profile_type.price_min),
          Number(response.data.parcel_profile_type.price_max),
        ];
      });
    }
  },
  mounted() {
    this.map = L.map('map', { zoomControl: false, tap: true }).setView([54.345, 18.64], 11);
    this.map.createPane('labels');
    L.control.zoom({
      position: 'topright',
    }).addTo(this.map);

    const basemaps = {
      OSM: L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      }),
    };

    basemaps.OSM.addTo(this.map);

    if (this.profile) {
      this.address = this.investorProfile.address;
    }
  },
  computed: {
    ...mapGetters('user', ['investorProfile']),
    categories() {
      return categoriesData.categories;
    },
    buildings() {
      return buildingsData.buildings;
    },
    voivodeships() {
      return voivodeshipsData.voivodeships;
    },
    countries() {
      return countriesData.countries;
    },
    isEditMode() {
      return this.$route.name === 'editProfile';
    },
  },
  methods: {
    ...mapActions('user', ['saveInvestProfile', 'searchLoc', 'getInvestorProfile', 'updateInvestorProfile']),
    counties(voivode) {
      if (isNull(voivode)) {
        return [];
      }
      return countiesData[voivode];
    },
    search() {
      const data = {
        country: this.address.country,
        state: this.address.voivodeship,
        county: this.address.county,
        community: this.address.community,
        city: this.address.city,
        street: this.address.street,
      };
      let zoom;
      if (this.address.street) {
        zoom = 16;
      } else if (this.address.city) {
        zoom = 14;
      } else if (this.address.county) {
        zoom = 12;
      } else if (this.address.voivodeship) {
        zoom = 11;
      } else zoom = 10;
      if (marker !== undefined) {
        this.map.removeLayer(marker);
      }
      this.searchLoc(data).then((response) => {
        if (response.data.length) {
          marker = L.marker([response.data[0].lat, response.data[0].lon]).addTo(this.map);
          this.map.setView([response.data[0].lat, response.data[0].lon], zoom);
        }
      });
    },
    sendData(data) {
      if (this.$route.params.profileId) {
        this.updateInvestorProfile({ data, id: this.$route.params.profileId }).then((response) => {
          if (response.status === 200) {
            this.success = true;
            setTimeout(() => {
              this.$router.push({ name: 'investorProfile', params: { uuid: this.$route.params.uuid } });
            }, 3000);
          }
        });
      } else {
        this.saveInvestProfile(data).then((response) => {
          if (response.status === 201) {
            this.success = true;
            setTimeout(() => {
              this.$router.push({ name: 'investorProfile', params: { uuid: this.$route.params.uuid } });
            }, 3000);
          }
        });
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        const {
          type, surface, puu, pum, price, address, building_profile_type, zoning_category, name,
        } = this;
        const { uuid } = this.$route.params;
        const parcel_profile_type = {
          zoning_category,
          area_min: surface[0] * 10000,
          area_max: surface[1] * 10000,
          puu_min: puu[0],
          puu_max: puu[1],
          pum_min: pum[0],
          pum_max: pum[1],
          price_min: price[0],
          price_max: price[1],
        };
        if (this.type === 'building') {
          const data = {
            id: uuid,
            name,
            type,
            address,
            parcel_profile_type,
          };
          this.sendData(data);
        } else {
          const data = {
            id: uuid,
            name,
            type,
            address,
            parcel_profile_type,
            building_profile_type,
          };
          this.sendData(data);
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .investor-profile {
    &__radio
    {
    width: 100%;
    .v-input__slot {
      margin-bottom: 0!important;
    }
    .v-input--selection-controls {
      margin-top: 0!important;
    }
    .v-input--radio-group__input {
      flex-direction: row!important;
      & * {
        margin-bottom: 0!important;
      }
      .v-input--selection-controls__input {
        display: none;
      }
      label {
        border: 1px solid $f-blue;
        padding: 10px 30px;
        justify-content: center;
        color: $f-blue;
        font-weight: bold;
      }
      .v-item--active {
        label {
          background-color: $f-blue;
          color: $white;
          font-weight: bold;
        }
      }
      .v-radio:first-child {
        label {
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
        }
      }
      .v-radio:not(:first-child) {
        label {
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
        }
      }
    }
  }
    &__slider {
      display: flex;
      &--right {
        padding-left: 30px;
        text-align: right;
      }
      &--left {
        padding-right: 30px;
      }
      input {
        width: 50%;
      }
    }
    .v-input {
      min-height: 54px;
    }
    .v-select__selection {
      padding-left: 5px!important;
    }
    .v-text-field__slot,
    .v-select__slot,
    .v-radio {
      .v-label:after {
        content: '*';
        color: red;
      }
    }
    .no-required {
      .v-label:after {
        content: ''!important;
      }
    }
    .v.v-text-field__slot,
    .v-select__slot {
      label {
        padding-left: 5px!important;
      }
    }
    .v-text-field {
      padding-top: 0!important;
    }
    .v-text-field .v-label {
      left: 2px!important;
      background-color: white;
    }
    #map {
      width: 100%;
      height: 90vh;
    }
    &.success-profile {
      #map {
        height: 100vh;
      }
    }
  }
</style>
