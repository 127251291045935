<template>
  <div v-if="isActive">
    <permit @stepCompleted="stepCompleted = $event"/>
    <v-btn
      :disabled="isActive && !validate()"
      color="primary"
      class="mt-5 mb-1"
      @click="completed()"
    >
      {{ $t("next") }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { permitGrantedOptions } from '@/helpers/enums';
import Permit from './Permit.vue';

export default {
  name: 'AddPermitStep',
  components: {
    Permit,
  },
  props: [
    'isActive',
  ],
  data: () => ({
    stepCompleted: false,
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['parcel']),
  },
  methods: {
    validate() {
      let result = false;
      switch (this.parcel.is_permit_granted) {
        case null:
          result = false;
          break;
        case permitGrantedOptions.notGranted:
          result = true;
          break;
        case permitGrantedOptions.inProgress:
          switch (this.parcel.is_building_permit_proposal) {
            case undefined:
              result = false;
              break;
            case false:
              result = true;
              break;
            default:
              result = this.parcel.building_project.length && this.parcel.building_permit_proposal.length;
          }
          break;
        default:
          result = this.parcel.building_permit.length;
      }
      if (!result) this.invalidated();

      return result;
    },
    completed() {
      this.$emit('completed');
    },
    invalidated() {
      this.$emit('invalidated');
    },
  },
};
</script>

<style scoped>

</style>
