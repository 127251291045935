<template>
  <v-select
    :items="categories"
    v-model="category"
    @change="zoningCategoryChanged"
    :placeholder="$t('propertyUse')"
  >
    <template v-slot:item="{ item }">
      {{ $t(item) }}
    </template>

    <template v-slot:selection="{ item }">
      {{ $t(item) }}
    </template>
  </v-select>
</template>

<script>
import categoriesData from '@/data/zoning_category.json';

export default {
  name: 'ZoningCategories',
  created() {
    this.category = this.zoningCategory;
  },
  data: () => ({
    category: null,
  }),
  props: [
    'zoningCategory',
  ],
  methods: {
    zoningCategoryChanged() {
      this.$emit('zoningCategoryChanged', this.category);
    },
  },
  computed: {
    categories() {
      return categoriesData.categories;
    },
  },
};
</script>
