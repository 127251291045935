import axios from 'axios';

const header = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// eslint-disable-next-line no-async-promise-executor
const uploadSingle = (file, type) => new Promise(async (resolve, reject) => {
  if (!file) reject();
  else {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
    const formData = new FormData();
    formData.append('title', file.name);
    formData.append('type', type);
    formData.append('file', file);
    try {
      const uploadedFile = await axios.post(`${process.env.VUE_APP_HOST_URL}/files/`, formData, header);
      resolve(uploadedFile.data);
    } catch (error) {
      reject(error);
    }
  }
});

const uploadMultiple = (files, type) => Promise.all(files.map(async (file) => {
  const uploadedFile = await uploadSingle(file, type);
  return uploadedFile.id;
}));

// eslint-disable-next-line no-async-promise-executor
const getFileMeta = (fileId) => new Promise(async (resolve, reject) => {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
  try {
    const fileMeta = await axios.get(`${process.env.VUE_APP_HOST_URL}/files/${fileId}/`, header);
    resolve(fileMeta.data);
  } catch (error) {
    reject(error);
  }
});

export default {
  uploadSingle,
  uploadMultiple,
  getFileMeta,
};
