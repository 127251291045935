export default {
  data: (vm) => ({
    parcelIdRules: [
      (v) => !!v || vm.$t('cantBeEmpty'),
      (v) => (v && v.length <= 30) || vm.$t('max30Chars'),
    ],
    landRegisterNumberRules: [
      (v) => !!v || vm.$t('cantBeEmpty'),
      (v) => (v && v.length >= 8) || vm.$t('eightChars'),
      (v) => (v && vm.validateKW(v)) || vm.$t('validNumberKw'),
      (v) => (v && v.length <= 20) || vm.$t('max20Chars'),
    ],
    areaRules: [
      (v) => !!v || vm.$t('cantBeEmpty'),
      (v) => (v && v !== '0') || vm.$t('noZero'),
      (v) => (v && parseFloat(v).countDecimals() <= 4) || vm.$t('max4Decimals'),
      (v) => (v && parseFloat(v).countNumbers() <= 12) || vm.$t('max16Numbers'),
    ],
  }),
  methods: {
    validateKW(kw) {
      // Check length
      if (kw == null) {
        return false;
      }
      if (kw.length !== 15) {
        return false;
      }

      // eslint-disable-next-line no-param-reassign
      kw = kw.toUpperCase();

      // Check slashes
      if (kw[4] !== '/' || kw[13] !== '/') {
        return false;
      }
      // Check court id
      let i = 0;
      // eslint-disable-next-line no-plusplus
      for (i; i < 2; i++) {
        if (this.getLetterValue(kw[i]) < 10) {
          return false;
        }
      }

      if (this.getLetterValue(kw[2]) < 0 || this.getLetterValue(kw[2]) > 9) {
        return false;
      }

      if (this.getLetterValue(kw[3]) < 10) {
        return false;
      }

      // Check numbers
      // eslint-disable-next-line no-plusplus
      for (i = 5; i < 13; i++) {
        if (this.getLetterValue(kw[i]) < 0 || this.getLetterValue(kw[i]) > 9) {
          return false;
        }
      }

      // Check checkdigit
      let sum = this.getLetterValue(kw[0])
        + 3 * this.getLetterValue(kw[1])
        + 7 * this.getLetterValue(kw[2])
        + this.getLetterValue(kw[3])
        + 3 * this.getLetterValue(kw[5])
        + 7 * this.getLetterValue(kw[6])
        + this.getLetterValue(kw[7])
        + 3 * this.getLetterValue(kw[8])
        + 7 * this.getLetterValue(kw[9])
        + this.getLetterValue(kw[10])
        + 3 * this.getLetterValue(kw[11])
        + 7 * this.getLetterValue(kw[12]);
      sum %= 10;
      this.validKw = !(+kw[14] === sum);
      return +kw[14] === sum;
    },
    getLetterValue(letter) {
      const letterValues = [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'X',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U',
        'W', 'Y', 'Z'];
      let j = 0;
      // eslint-disable-next-line no-plusplus
      for (j; j < letterValues.length; j++) {
        if (letter === letterValues[j]) {
          return j;
        }
      }
      return -1;
    },
  },
};
