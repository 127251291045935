<template>
  <InvestorProfileForm />
</template>

<script>
import InvestorProfileForm from '@/components/investorProfile/InvestorProfileForm.vue';

export default {
  name: 'NewProfile',
  components: {
    InvestorProfileForm,
  },
};
</script>
