<template>
  <div class="error-state" v-if="this.$route.name !== 'login'">
    <h1 class="text-center">{{ $t('somethingWentWrong') }}</h1>
    <h2 class="text-center">{{ $t('loginAgain')}}</h2>
    <router-link
      :to="{ name: 'login'}"
      class="text-center"
    >
      <v-btn rounded class="mt-5 mb-1 px-10 btn-blue-rounded">
        {{ $t('login') }}
      </v-btn>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ErrorMsg',
  props: ['errorShow'],
  created() {
    if (this.errorShow) {
      setTimeout(() => {
        this.$router.go('/');
      }, 5000);
    }
  },
};
</script>

<style lang="scss">
  .error-state {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(192, 192, 192, 0.7);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
</style>
