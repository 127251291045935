import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import VueCurrencyInput from 'vue-currency-input';
import VueAwesomeSwiper from 'vue-awesome-swiper';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/swiper.css';
import VueLuxon from 'vue-luxon';

Vue.use(VueLuxon);
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;
const VueScrollTo = require('vue-scrollto');

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'PL' },
};
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// eslint-disable-next-line no-extend-native
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split('.')[1].length || 0;
};

// eslint-disable-next-line no-extend-native
Number.prototype.countNumbers = function () {
  return this.toString().split('.')[0].length || 0;
};
