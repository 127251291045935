<template>
  <div v-if="isActive">
    <buildings @stepCompleted="stepCompleted = $event"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Buildings from './Buildings.vue';

export default {
  name: 'AddBuildingsStep',
  components: {
    Buildings,
  },
  props: [
    'isActive',
  ],
  data: () => ({
    stepCompleted: false,
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['parcel']),
  },
  methods: {
    validate() {
      let result = false;

      if (!this.parcel || this.parcel.is_built_up === undefined) result = false;
      else if (this.parcel.is_built_up === false) result = true;
      else result = this.parcel.buildings_description;

      // if (!result) this.invalidated();

      return result;
    },
    completed() {
      this.$emit('completed');
    },
    invalidated() {
      this.$emit('invalidated');
    },
  },
};
</script>

<style scoped>

</style>
