import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { get } from 'lodash';
// eslint-disable-next-line import/named

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    announcements: null,
  },
  mutations: {
    SET_ANNOUNCEMENTS: (state, data) => {
      state.announcements = data;
    },
  },
  getters: {
    announcements: (state) => get(state, 'announcements'),
  },
  actions: {
    getAnnouncements: ({ commit }) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/properties/published/`)
      .then((announcements) => {
        commit('SET_ANNOUNCEMENTS', announcements.data);
      }),
  },
};
