<template>
  <v-container fluid>
    <p class="title">{{ $t('parcelList')}}</p>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <properties-list @goToParcel="navigateToParcel"/>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <Map :parcels="lots" :goToParcelOnStart="goToParcelOnStart" ref="map"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
import Map from '@/components/Map.vue';
import PropertiesList from '@/components/PropertiesList.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MyParcels',
  data: () => ({
    parcelToZoomIn: null,
    goToParcelOnStart: false,
  }),
  components: {
    PropertiesList,
    Map,
  },
  computed: {
    ...mapGetters('properties', ['lots']),
  },
  methods: {
    navigateToParcel(data) {
      this.$refs.map.goToParcel(data);
    },
  },
};
</script>
