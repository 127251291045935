<template>
  <v-app>
    <v-container fluid class="reset">
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="0"
          class="d-flex align-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="10"
              lg="6"
              offset-md="1"
              offset-lg="3"
            >
              <p class="reset__subheader text-uppercase">{{ $t('forgot') }}</p>
              <h2 class="reset__header mb-8">{{ $t('sendEmail') }}</h2>
              <v-form
                v-model="valid"
                ref="form"
                :lazy-validation="lazy"
                @keyup.enter.native="validate"
                class="mt-md-4 pa-5 pa-md-0"
              >
                <v-text-field class="d-none"></v-text-field>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  v-if="!this.sended"
                ></v-text-field>
                <p v-else>{{ $t('sendedReset') }}</p>
                <div class="reset__button-box" v-if="!this.sended">
                  <v-btn
                    :disabled="!valid"
                    class="px-8 w-100 btn-gradient-blue"
                    @click="validate"
                    rounded
                    id="reset-password-btn"
                  >
                    {{ $t('resetPassword') }}
                  </v-btn>
                </div>
                <router-link :to="{ name: 'login' }">
                  <p class="text-center mt-5">
                    {{ $t('backToLogin') }}
                  </p>
                </router-link>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="d-none d-md-block">
          <div class="login-background">
            <img src="../assets/images/background.jpg">
            <div class="login-background__content">
              <p class="login-background__content--text">
                {{ $t('loginText')}}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'reset',
  data() {
    return {
      email: '',
      emailRules: [
        (v) => !!v || this.$i18n.t('emailMustBeValid'),
        // eslint-disable-next-line max-len
        (v) => /^[a-zA-Z0-9.+`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(v) || this.$i18n.t('emailMustBeValid'),
      ],
      valid: false,
      lazy: false,
      sended: false,
    };
  },
  methods: {
    ...mapActions('user', ['resetPassword']),
    validate() {
      this.valid = false;
      if (this.$refs.form.validate()) {
        const email = {
          email: this.email,
        };
        this.resetPassword(email).then((response) => {
          if (response.status === 200) {
            this.sended = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
  .reset {
    @include respond-to(sm) {
      background-image: image-set(
          '../assets/images/background.jpg' 1x
      );
      background-size: cover;
      background-position: center;
      min-height: 100vh;
    }
    &__header,
    &__subheader {
      @include respond-to(sm) {
        color: $white;
      }
    }
    .v-form {
      background: $white;
    }
    .v-btn__content {
      color: $white;
      font-weight: bold;
    }
  }
</style>
