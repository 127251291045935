var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("permitExists"))+" ")]),_c('v-card-text',[_c('v-btn-toggle',{on:{"change":_vm.isPermitGrantedChanged},model:{value:(_vm.isPermitGrantedOption),callback:function ($$v) {_vm.isPermitGrantedOption=$$v},expression:"isPermitGrantedOption"}},[_c('v-btn',{staticClass:"new-property__toggle-btn btn-left",attrs:{"value":_vm.permitGrantedOptions.granted}},[_vm._v(" "+_vm._s(_vm.$t("yes"))+" ")]),_c('v-btn',{staticClass:"new-property__toggle-btn",attrs:{"value":_vm.permitGrantedOptions.inProgress}},[_vm._v(" "+_vm._s(_vm.$t("inProgress"))+" ")]),_c('v-btn',{staticClass:"new-property__toggle-btn btn-right",attrs:{"value":_vm.permitGrantedOptions.notGranted}},[_vm._v(" "+_vm._s(_vm.$t("no"))+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-5 new-property-files",attrs:{"flat":""}},[(this.isPermitGrantedOption === _vm.permitGrantedOptions.granted)?_c('v-card-text',[_c('v-form',{attrs:{"model":_vm.permitForm}},[_c('v-file-input',{staticClass:"required",attrs:{"hint":_vm.$t('required'),"placeholder":_vm.$t('addPermissionFiles'),"loading":_vm.uploadingPermit,"disabled":_vm.uploadingPermit,"rules":_vm.permitFileRules,"value":_vm.selectedPermitFiles,"multiple":""},on:{"change":(files) => {
            _vm.fileSelected(
              files,
              'building_permit',
              'building_permit',
              'uploadingPermit',
              'selectedPermitFiles'
            )
          }}}),_c('file-list',{attrs:{"files":_vm.permitFiles},on:{"fileRemoved":(id) => _vm.onFileRemoved(id, 'building_permit')}}),_c('v-file-input',{attrs:{"hint":_vm.$t('notRequired'),"placeholder":_vm.$t('addBuildingProject'),"loading":_vm.uploadingProject,"disabled":_vm.uploadingProject,"model":_vm.selectedProjectFiles,"multiple":""},on:{"change":(files) => {
            _vm.fileSelected(
              files,
              'building_project',
              'building_project',
              'uploadingProject',
              'selectedProjectFiles'
            )
          }}}),_c('file-list',{attrs:{"files":_vm.projectFiles},on:{"fileRemoved":(id) => _vm.onFileRemoved(id, 'building_project')}}),_c('v-textarea',{attrs:{"hint":_vm.$t('notRequired'),"label":_vm.$t('addCommentOrOpinionFiles'),"counter":"","counter-value":_vm.valuePerMax},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}}),_c('v-file-input',{attrs:{"multiple":"","hint":_vm.$t('notRequired'),"placeholder":_vm.$t('otherFiles'),"loading":_vm.uploadingOther,"disabled":_vm.uploadingOther,"model":_vm.selectedOtherFiles},on:{"change":(files) => {
            _vm.fileSelected(
              files,
              'other',
              'other_files',
              'uploadingOther',
              null
            )
          }}}),_c('file-list',{attrs:{"files":_vm.otherFiles},on:{"fileRemoved":(id) => {_vm.onFileRemoved(id, 'other_files')}}})],1)],1):_vm._e(),(this.isPermitGrantedOption === _vm.permitGrantedOptions.inProgress
      || this.isPermitProposalOption && this.isPermitGrantedOption === _vm.permitGrantedOptions.notGranted)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("permitProposalExists"))+" ")]),_c('v-card-text',[_c('v-btn-toggle',{on:{"change":_vm.isPermitProposalChanged},model:{value:(_vm.isPermitProposalOption),callback:function ($$v) {_vm.isPermitProposalOption=$$v},expression:"isPermitProposalOption"}},[_c('v-btn',{staticClass:"new-property__toggle-btn btn-left",attrs:{"value":_vm.permitProposalOptions.yes}},[_vm._v(_vm._s(_vm.$t("yes")))]),_c('v-btn',{staticClass:"new-property__toggle-btn btn-right",attrs:{"value":_vm.permitProposalOptions.no}},[_vm._v(_vm._s(_vm.$t("no")))])],1)],1)],1):_vm._e(),(this.isPermitProposalOption === _vm.permitProposalOptions.yes)?_c('v-card-text',[_c('v-form',{attrs:{"model":_vm.permitForm}},[_c('v-file-input',{staticClass:"required",attrs:{"hint":_vm.$t('required'),"placeholder":_vm.$t('addBuildingProject'),"loading":_vm.uploadingProject,"disabled":_vm.uploadingProject,"model":_vm.selectedProjectFiles,"multiple":""},on:{"change":(files) => {
              _vm.fileSelected(
                files,
                'building_project',
                'building_project',
                'uploadingProject',
                'selectedProjectFiles'
              )
            }}}),_c('file-list',{attrs:{"files":_vm.projectFiles},on:{"fileRemoved":(id) => {_vm.onFileRemoved(id, 'building_project')}}}),_c('v-file-input',{staticClass:"required",attrs:{"hint":_vm.$t('required'),"placeholder":_vm.$t('permitProposal'),"loading":_vm.uploadingPermitProposal,"disabled":_vm.uploadingPermitProposal,"model":_vm.selectedPermitProposalFiles,"multiple":""},on:{"change":(files) => {
              _vm.fileSelected(
                files,
                'building_permit_proposal',
                'building_permit_proposal',
                'uploadingPermitProposal',
                'selectedPermitProposalFiles',
              )
            }}}),_c('file-list',{attrs:{"files":_vm.buildingProposalFiles},on:{"fileRemoved":(id) => _vm.onFileRemoved(id, 'building_permit_proposal')}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }