<template>
  <div v-if="isActive">
    <lot-entry />
    <parcel-list class="mt-5" :parcels="lots" @navigateToParcel="navigateToParcel"/>
    <div v-if="isLotBuiltUp.length" class="mt-5">
      <p>{{ $t('infoBuildingDevelopment')}}</p>
      <add-buildings-step :isActive="true" />
    </div>
    <v-btn
      v-if="this.lots"
      :disabled="this.lots.length === 0 || property.location_description === null || !isValidate()"
      class="mt-5 mb-1"
      @click="completed()"
      color="primary"
    >
      {{ $t("next") }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { filter } from 'lodash';
import ParcelList from '@/components/ParcelList.vue';
import LotEntry from './LotEntry.vue';
import AddBuildingsStep from './AddBuildingsStep.vue';

export default {
  name: 'AddLotsStep',
  props: [
    'isActive',
  ],
  components: {
    LotEntry,
    ParcelList,
    AddBuildingsStep,
  },
  computed: {
    ...mapGetters('newPropertyForm', ['property', 'lots', 'parcel']),
    isLotBuiltUp() {
      return filter(this.lots, { is_built_up: true });
    },
  },
  methods: {
    isValidate() {
      let result = false;

      if (!this.parcel || this.parcel.is_built_up === undefined) result = false;
      else if (this.parcel.is_built_up === false) result = true;
      else result = this.parcel.buildings_description !== null;
      return result;
    },
    completed() {
      this.$emit('completed');
    },
    invalidated() {
      this.$emit('invalidated');
    },
    navigateToParcel(data) {
      this.$emit('navigateToParcel', data);
    },
  },
};
</script>
