<template>
  <div class="register">
    <v-form
      v-model="valid"
      ref="form"
      :lazy-validation="lazy"
      @keyup.enter.native="validate"
      class="mt-md-4 pa-5 pa-md-0"
      v-if="!this.success"
    >
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        prepend-inner-icon="mdi-account"
        required
      ></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        :type="show ? 'text' : 'password'"
        name="input-10-1"
        :label="$t('password')"
        :hint="$t('eightChars')"
        prepend-inner-icon="mdi-key-variant"
        @click:append="show = !show"
      ></v-text-field>
      <v-text-field
        v-model="confirmPassword"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[(password === confirmPassword) || $t('samePassword')]"
        :type="show1 ? 'text' : 'password'"
        name="input-10-1"
        :label="$t('confirmPassword')"
        :hint="$t('eightChars')"
        prepend-inner-icon="mdi-key-variant"
        @click:append="show1 = !show1"
      ></v-text-field>
      <p class="mt-10 text-center">{{ $t('whatYouPlan') }}?</p>
      <v-radio-group
        v-model="type"
        :rules="[(v) => !!v || 'Required',]"
        required
      >
        <v-radio
          :label="$t('wantSell')"
          value="owner"
        ></v-radio>
        <v-radio
          :label="$t('wantBuy')"
          value="investor"
        ></v-radio>
      </v-radio-group>
      <v-checkbox
        required
        v-model="checkbox"
        :label="$t('readTheRegulations')"
        :rules="[v => !!v || $t('agreeToContinue')]"
      />
      <p class="mb-4 mt-1"><span class="red--text">*</span> {{ $t('requiredField')}}</p>
      <div class="d-flex justify-center mt-10">
        <v-btn
          :disabled="!valid"
          class="px-8 w-100 btn-gradient-blue"
          @click="validate"
          rounded
        >
          {{ $t('register') }}
        </v-btn>
      </div>
      <v-alert
        class="mt-5"
        type="error"
        v-model="validationErrors.show"
        dismissible
        transition="fade-transition"
      >
        <p
          v-for="error in validationErrors.messages"
          :key="error"
        >
          {{ error }}
        </p>
      </v-alert>
    </v-form>
    <div v-else class="register__success mt-5 text-center">
      <h2>{{ $t('registerSuccess') }}</h2>
      <h4 class="my-4">{{ $t('confirmActivation') }}</h4>
      <div class="mt-3">
        <v-btn
          class="px-8 btn-gradient-blue"
          rounded
          @click="backToLogin"
        >
          {{ $t('goToLogin') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { findAllByKey } from '@/helpers/functions';

export default {
  name: 'Login',
  data: (vm) => ({
    validationErrors: {
      show: false,
      messages: [],
    },
    checkbox: false,
    valid: false,
    email: '',
    emailRules: [
      (v) => !!v || vm.$t('emailRequired'),
      // eslint-disable-next-line max-len
      (v) => /^[a-zA-Z0-9.+`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(v) || vm.$t('emailMustBeValid'),
    ],
    password: '',
    passwordRules: [
      (value) => !!value || vm.$t('required'),
      (v) => v.length >= 8 || vm.$t('eightChars'),
      (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(v) || vm.$t('pwdMustHave'),
    ],
    confirmPassword: '',
    show: false,
    show1: false,
    lazy: false,
    type: '',
    success: false,
  }),
  methods: {
    ...mapActions('user', ['registerUser']),
    reset() {
      this.checkbox = false;
      this.email = '';
      this.password = '';
      this.confirmPassword = '';
      this.type = '';
      this.success = false;
    },
    validate() {
      if (this.$refs.form.validate()) {
        const data = {
          email: this.email,
          password: this.password,
          re_password: this.confirmPassword,
          type: this.type,
        };
        this.registerUser(data)
          .then((response) => {
            if (response.status === 201) {
              this.success = true;
              setTimeout(() => {
                this.reset();
                this.$emit('backToLogin');
              }, 10000);
            }
          })
          .catch((error) => {
            this.validationErrors.show = true;
            this.validationErrors.messages = [];
            const errors = findAllByKey(error.response.data, 'message');
            this.validationErrors.messages.push(...errors);
          });
      }
    },
    backToLogin() {
      this.reset();
      this.$emit('backToLogin');
    },
  },
};
</script>

<style lang="scss">
  .register {
    .v-input--radio-group__input {
      flex-direction: row!important;
      & * {
        margin-bottom: 0!important;
      }
      .v-input--selection-controls__input {
        display: none;
      }
      label {
        border: 1px solid $f-blue;
        padding: 10px 20px;
        justify-content: center;
        color: $f-blue;
        font-weight: bold;
      }
      .v-item--active {
        label {
          background-color: $f-blue;
          color: $white;
          font-weight: bold;
        }
      }
      .v-radio {
        width: 50%;
      }
      .v-radio:first-child {
        label {
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
        }
      }
      .v-radio:not(:first-child) {
        label {
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
        }
      }
    }
    .v-radio,
    .v-input__slot,
    .v-text-field__slot {
      .v-label:after {
        content: '*';
        color: red;
      }
    }
  }
</style>
