<template>
  <v-container fluid class="offer">
    <v-row id="offer">
      <v-col
        cols="12"
        md="6"
        order="2"
        order-md="1"
      >
        <div class="mb-5">
          <router-link
            :to="{ name: 'offerList', params: { propertyId: this.$route.params.propertyId }}"
            class="d-none d-md-block"
          >
            <v-icon class="font-blue">mdi-menu-left</v-icon>
            <span class="small font-weight-bold">{{ $t('backOfferList') }}</span>
          </router-link>
        </div>
        <GeneralInfo
          v-if="this.property"
          :announcement="this.property.announcement"
          :capacity="this.property.development_capacity_analysis"
        />
        <OfferDetails v-if="this.offer" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        id="gallery"
        order="1"
        order-md="2"
        class="pt-0 pt-md-4"
      >
        <router-link
          :to="{ name: 'offerList', params: { propertyId: this.$route.params.propertyId }}"
          class="d-block d-md-none mb-5"
        >
          <v-icon class="font-blue">mdi-menu-left</v-icon>
          <span class="small font-weight-bold">{{ $t('backOfferList') }}</span>
        </router-link>
        <Gallery
          v-if="this.property"
          :announcement="this.property.announcement"
          :capacity="this.property.development_capacity_analysis"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import GeneralInfo from '@/components/announcement/GeneralInfo.vue';
import Gallery from '@/components/announcement/Gallery.vue';
import OfferDetails from '@/components/offer/OfferDetails.vue';

export default {
  name: 'Offer',
  created() {
    const { offerId } = this.$route.params;
    this.$store.dispatch('offer/getOffer', offerId);
  },
  components: {
    OfferDetails,
    GeneralInfo,
    Gallery,
  },
  computed: {
    ...mapGetters('offer', ['property', 'offer']),
  },
};
</script>
