<template>
  <v-expand-transition>
    <v-list v-if="files.length > 0">
      <v-list-item v-for="id in files" :key="id">
        <template v-if="fileName(id)">
          <v-list-item-content>
            <v-list-item-title>
              {{ fileName(id) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="removeFile(id)">
              <v-icon color="primary">mdi-trash-can</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
        <v-progress-linear v-else indeterminate/>
      </v-list-item>
    </v-list>
  </v-expand-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FileList',
  props: [
    'files',
  ],
  created() {
    this.fetchFilesMeta(this.files);
  },
  computed: {
    ...mapGetters('files', ['getFileMeta']),
  },
  methods: {
    ...mapActions('files', ['fetchFilesMeta']),
    fileName(id) {
      if (id) {
        const meta = this.getFileMeta(id);
        return meta.title;
      }

      return null;
    },
    removeFile(id) {
      this.$emit('fileRemoved', id);
    },
  },
  watch: {
    files() {
      this.fetchFilesMeta(this.files);
    },
  },
};
</script>

<style scoped>

</style>
