<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        {{ $t("localPlanExists") }}
      </v-card-title>
      <v-card-text>
        <v-btn-toggle v-model="isLocalPlanOptionIndex" @change="isLocalPlanChanged">
          <v-btn class="new-property__toggle-btn btn-left">{{ $t("yes") }}</v-btn>
          <v-btn class="new-property__toggle-btn btn-right">{{ $t("no") }}</v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>

    <v-card flat class="mt-5" v-if="isLocalPlanOptionIndex === 0">
      <v-card-text>
        <zoning-categories
          :zoning-category="zoningCategory"
          @zoningCategoryChanged="zoningCategoryChanged"/>
      </v-card-text>
    </v-card>

    <v-card flat class="mt-5" v-else-if="isLocalPlanOptionIndex === 1">
      <v-card-title>
        {{ $t("decisionExists") }}
      </v-card-title>
      <v-card-text>
        <v-btn-toggle
          v-model="hasBuildingConditionsDecisionOptionIndex"
          @change="hasBuildingConditionsChanged"
        >
          <v-btn class="new-property__toggle-btn btn-left">{{ $t("yes") }}</v-btn>
          <v-btn class="new-property__toggle-btn btn-right">{{ $t("no") }}</v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>

    <v-card flat class="mt-5" v-if="hasBuildingConditionsDecisionOptionIndex === 0">
      <v-card-text>
        <v-card flat class="mt-5">
          <v-card-text>
            <zoning-categories
              :zoning-category="zoningCategory"
              @zoningCategoryChanged="zoningCategoryChanged"/>
          </v-card-text>
        </v-card>
        <v-file-input
          :hint="$t('notRequired')"
          :placeholder="$t('decisionFile')"
          :loading="uploadingDecision"
          :disabled="uploadingDecision"
          @change="(files) => {
            fileSelected(
              files,
              'building_conditions_decision',
              'building_conditions_decision',
              'uploadingDecision',
            )
          }"
          multiple
        />
        <file-list :files="decisionFiles" @fileRemoved="onFileRemoved"/>
      </v-card-text>
    </v-card>

    <v-card flat class="mt-5" v-else-if="hasBuildingConditionsDecisionOptionIndex === 1">
      <v-card-title>
        {{ $t("useStudy") }}
      </v-card-title>
      <v-card-text>
        <zoning-categories
          :zoning-category="zoningCategory"
          @zoningCategoryChanged="zoningCategoryChanged"/>
      </v-card-text>
    </v-card>
    <v-card flat class="mt-5">
      <v-card-title>
        {{ $t("archConcept") }}
      </v-card-title>
      <v-card-text>
        <v-btn-toggle
          v-model="hasConcept"
          @change="isConceptChanged"
        >
          <v-btn class="new-property__toggle-btn btn-left">{{ $t("yes") }}</v-btn>
          <v-btn class="new-property__toggle-btn btn-right">{{ $t("no") }}</v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>
    <v-card flat class="mt-5" v-if="hasConcept === 0">
      <v-card-text>
        <v-file-input
          :hint="$t('required')"
          :placeholder="$t('conceptFile')"
          :loading="uploadingConcept"
          :disabled="uploadingConcept"
          :rules="conceptFileRules"
          @change="(files) => {
            fileSelected(
              files,
              'parcel_concept',
              'concept',
              'uploadingConcept',
            )
          }"
          class="required"
          multiple
        />
        <file-list :files="conceptFiles" @fileRemoved="onConceptFileRemoved"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fileUpload from '@/services/fileUpload';
import FileList from '@/components/FileList.vue';
import ZoningCategories from './ZoningCategories.vue';

export default {
  name: 'Plans',
  components: { ZoningCategories, FileList },
  data: (vm) => ({
    isLocalPlanOptionIndex: null,
    hasBuildingConditionsDecisionOptionIndex: null,
    zoningCategory: null,
    uploadingDecision: false,
    uploadingConcept: false,
    hasConcept: null,
    uploadedConceptFiles: [],
    conceptFileRules: [
      (v) => !!v || vm.conceptFiles.length > 0 || vm.$t('cantBeEmpty'),
    ],
  }),
  created() {
    if (this.parcel.has_local_plan != null) {
      this.isLocalPlanOptionIndex = this.parcel.has_local_plan ? 0 : 1;
    }

    if (this.parcel.has_building_conditions_decision != null) {
      this.hasBuildingConditionsDecisionOptionIndex = this.parcel.has_building_conditions_decision
        ? 0 : 1;
    }

    if (this.parcel.initial_zoning_category != null) {
      this.initial_zoning_category = this.parcel.initial_zoning_category;
    }

    if (this.parcel.has_concept != null) {
      this.has_concept = this.parcel.has_concept;
    }
  },
  computed: {
    ...mapGetters('newPropertyForm', ['parcel']),
    decisionFiles() {
      return this.parcel.building_conditions_decision;
    },
    conceptFiles() {
      return this.parcel.concept;
    },
  },
  methods: {
    ...mapActions('newPropertyForm', ['updateParcel']),
    completeStep(completed) {
      this.$emit('stepCompleted', completed);
    },
    isLocalPlanChanged(index) {
      this.hasBuildingConditionsDecisionOptionIndex = null;
      this.completeStep(false);

      if (index != null) {
        const parcel = {
          ...this.parcel,
          has_local_plan: index === 0,
          initial_zoning_category: null,
          has_building_conditions_decision: null,
        };

        this.zoningCategory = null;

        this.updateParcel(parcel);
      }
    },
    isConceptChanged(index) {
      this.completeStep(false);

      if (index != null) {
        const parcel = {
          ...this.parcel,
          has_concept: index === 0,
        };
        this.updateParcel(parcel);
      }
    },
    zoningCategoryChanged(dest) {
      if (dest == null) {
        this.completeStep(false);
      } else {
        const parcel = {
          ...this.parcel,
          initial_zoning_category: dest,
        };

        this.updateParcel(parcel);
      }
    },
    hasBuildingConditionsChanged(index) {
      this.completeStep(false);
      this.initial_zoning_category = null;

      if (index != null) {
        const parcel = {
          ...this.parcel,
          has_building_conditions_decision: index === 0,
          initial_zoning_category: null,
        };

        this.updateParcel(parcel);
      }
    },
    fileSelected(files, type, updatedParcelField, uploadingStateField) {
      if (!files) return;
      this[uploadingStateField] = true;
      try {
        fileUpload.uploadMultiple(files, type).then((uploadedFileIds) => {
          const parcel = {
            ...this.parcel,
            [updatedParcelField]: [...this.parcel[updatedParcelField], ...uploadedFileIds],
          };
          this.updateParcel(parcel);
        });
      } catch (error) {
        console.log('Error uploading file', error);
      } finally {
        this[uploadingStateField] = false;
      }
    },
    onFileRemoved(id) {
      const updatedDecisionFiles = this.parcel.building_conditions_decision.filter((fileId) => fileId !== id);
      const parcel = {
        ...this.parcel,
        building_conditions_decision: [...updatedDecisionFiles],
      };

      this.updateParcel(parcel);
    },
    onConceptFileRemoved(id) {
      const updatedConceptFiles = this.parcel.concept.filter((fileId) => fileId !== id);
      const parcel = {
        ...this.parcel,
        concept: [...updatedConceptFiles],
      };

      this.updateParcel(parcel);
    },
  },
};
</script>

<style scoped lang="scss">
  .new-property {
    ::v-deep &__toggle-btn {
      background: transparent !important;

      .v-btn__content {
        color: $black !important;
      }

      &.v-item--active {
        background: $f-blue !important;

        .v-btn__content {
          color: $white !important;
        }
      }
    }
  }
</style>
