import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { isNull } from 'lodash';

Vue.use(Vuex);
/* eslint-disable dot-notation */

let tokenRefreshInterval;

export default {
  namespaced: true,
  state: {
    user: {},
    investorProfiles: [],
    investorProfile: {},
    matches: [],
    isError: false,
  },
  getters: {
    user: (state) => state.user,
    investorProfiles: (state) => state.investorProfiles,
    investorProfile: (state) => state.investorProfile,
    matches: (state) => state.matches,
    isError: (state) => state.isError,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_INVESTOR_PROFILES: (state, user) => {
      state.investorProfiles = user;
    },
    SET_INVESTOR_PROFILE: (state, profile) => {
      state.investorProfile = profile;
    },
    SET_MATCHES: (state, matches) => {
      state.matches = matches;
    },
    SHOW_ERROR: (state, isError) => {
      state.isError = isError;
    },
  },
  actions: {
    logInUser: ({ commit, dispatch }, data) => {
      delete axios.defaults.headers.common['Authorization'];
      return axios
        .post(`${process.env.VUE_APP_HOST_URL}/auth/jwt/create/`, data)
        .then((user) => {
          localStorage.setItem('token', user.data.access);
          localStorage.setItem('refresh_token', user.data.refresh);
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          commit('SET_USER', user.data);

          // refresh after 30 minutes
          tokenRefreshInterval = window.setInterval(async () => {
            try {
              const token = await dispatch('refreshToken');
              if (token.status >= 400) {
                localStorage.removeItem('token');
                localStorage.removeItem('logged');
                localStorage.removeItem('refresh_token');
                commit('SHOW_ERROR', true);
                window.clearInterval(tokenRefreshInterval);
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
            }
          }, 0.900e+6);
          commit('SHOW_ERROR', false);
          return user;
        });
    },
    logOutUser: ({ commit }) => axios
      .post(`${process.env.VUE_APP_HOST_URL}/auth/token/logout/`)
      .then((user) => {
        localStorage.removeItem('token');
        localStorage.removeItem('logged');
        localStorage.removeItem('refresh_token');
        commit('SHOW_ERROR', false);
        window.clearInterval(tokenRefreshInterval);
        return user;
      }),
    currentUser: ({ commit }) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/users/me/`)
      .then((user) => {
        commit('SET_USER', user.data);
        return user;
      }),
    activateUser: (context, data) => {
      delete axios.defaults.headers.common['Authorization'];
      return axios
        .post(`${process.env.VUE_APP_HOST_URL}/auth/users/activation/`, data)
        .then((response) => response)
        .catch((error) => error.response);
    },
    refreshToken: () => {
      delete axios.defaults.headers.common['Authorization'];
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      const data = {
        refresh: localStorage.getItem('refresh_token'),
      };
      return axios
        .post(`${process.env.VUE_APP_HOST_URL}/auth/jwt/refresh/`, data)
        .then((response) => {
          localStorage.setItem('token', response.data.access);
          return response;
        })
        .catch((error) => error.response);
    },
    registerUser: (context, data) => {
      delete axios.defaults.headers.common['Authorization'];
      return axios
        .post(`${process.env.VUE_APP_HOST_URL}/auth/users/`, data)
        .then((user) => user);
    },
    resetPassword: (context, data) => {
      delete axios.defaults.headers.common['Authorization'];
      return axios
        .post(`${process.env.VUE_APP_HOST_URL}/auth/users/reset_password/`, data)
        .then((response) => response.data);
    },
    newUserPassword: (context, data) => {
      delete axios.defaults.headers.common['Authorization'];
      return axios
        .post(`${process.env.VUE_APP_HOST_URL}/auth/users/reset_password_confirm/`, data);
    },
    //  INVESTOR
    searchLoc: (context, data) => {
      let url;
      if (!isNull(data.state) && !isNull(data.county) && !isNull(data.city) && !isNull(data.street)) {
        url = `https://nominatim.openstreetmap.org/search?country=${data.country}&state=${data.state}&county=${data.county}&city=${data.city}&street=${data.street}&format=json`;
      } else if (!isNull(data.state) && !isNull(data.county) && !isNull(data.city)) {
        url = `https://nominatim.openstreetmap.org/search?country=${data.country}&state=${data.state}&county=${data.county}&city=${data.city}&format=json`;
      } else if (!isNull(data.state) && !isNull(data.county)) {
        url = `https://nominatim.openstreetmap.org/search?country=${data.country}&state=${data.state}&county=${data.county}&format=json`;
      } else if (!isNull(data.state) && !isNull(data.city)) {
        url = `https://nominatim.openstreetmap.org/search?country=${data.country}&state=${data.state}&city=${data.city}&format=json`;
      } else if (!isNull(data.state)) {
        url = `https://nominatim.openstreetmap.org/search?country=${data.country}&state=${data.state}&format=json`;
      }
      return axios
        .get(url)
        .then((response) => response);
    },
    saveInvestProfile: ({ commit }, data) => axios
      .post(`${process.env.VUE_APP_HOST_URL}/investor_profiles/`, data)
      .then((user) => {
        commit('SET_INVESTOR_PROFILE', user.data);
        return user;
      }),
    getInvestorProfiles: ({ commit }) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/investor_profiles/`)
      .then((profile) => {
        commit('SET_INVESTOR_PROFILES', profile.data);
        return profile;
      }),
    getInvestorProfile: ({ commit }, id) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/investor_profiles/${id}/`)
      .then((profile) => {
        commit('SET_INVESTOR_PROFILE', profile.data);
        return profile;
      }),
    updateInvestorProfile: ({ commit }, { data, id }) => axios
      .put(`${process.env.VUE_APP_HOST_URL}/investor_profiles/${id}/`, data)
      .then((profile) => {
        commit('SET_INVESTOR_PROFILE', profile.data);
        return profile;
      }),
    getInvestorMatches: ({ commit }, id) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/investor_profiles/${id}/match/`)
      .then((matches) => {
        commit('SET_MATCHES', matches.data);
        return matches;
      }),
    updateUser: ({ commit }, data) => axios
      .patch(`${process.env.VUE_APP_HOST_URL}/users/me/`, data)
      .then((response) => {
        commit('SET_USER', response.data);
      }),
    showError: ({ commit }, isError) => {
      commit('SHOW_ERROR', isError);
    },
  },
};
