<template>
  <div>
    <v-expand-transition>
      <v-data-table
        v-if="parcels && parcels.length > 0"
        :headers="headers"
        :items="parcels"
        @click:row="navigateToParcel"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("parcelsAdded") }}</v-toolbar-title>
          </v-toolbar>
          <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
              <v-card-title>
                {{ $t("editItem" )}}
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="lotData" v-model="valid">
                    <v-row>
                      <v-text-field
                        v-model="editedItem.lot_id"
                        :label="$t('parcelId')"
                        :rules="parcelIdRules"
                        @change="error.show = false"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="editedItem.land_register.number"
                        :label="$t('registerId')"
                        :rules="landRegisterNumberRules"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="editedItem.area"
                        :label="$t('area')"
                        :rules="areaRules"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-checkbox
                        v-model="editedItem.is_built_up"
                        :label="$t('isBuiltUp')"
                      />
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1"  @click="closeEdit()">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="blue darken-1"
                  :disabled="!valid"
                  @click="saveEditedItem()"
                >
                  {{ $t('save') }}
                </v-btn>
              </v-card-actions>
              <v-container>
                <v-alert
                  class="mt-5"
                  type="error"
                  v-model="error.show"
                  dismissible
                  transition="fade-transition">
                  {{ error.message }}
                </v-alert>
              </v-container>

            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click.stop="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click.stop="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.lot_id="{ item }">
          <p class="body-1 mt-2">{{ item.lot_id }}</p>
          <div class="location-details mt-n1 mb-2 d-none d-md-block">
            <p v-if="item.address && item.address.voivodeship">
              <span class="caption">{{ $t("voivodeship") }}:</span>
              <span class="ml-1 subtitle-2">{{ item.address.voivodeship }}</span>
            </p>
            <p v-if="item.address && item.address.county">
              <span class="caption">{{ $t("county") }}:</span>
              <span class="ml-1 subtitle-2">{{ item.address.county }}</span>
            </p>
            <p v-if="item.address && item.address.municipality">
              <span class="caption">{{ $t("municipality" )}}:</span>
              <span class="ml-1 subtitle-2">{{ item.address.municipality }}</span>
            </p>
            <p v-if="item.address && item.address.region">
              <span class="caption">{{ $t("region") }}:</span>
              <span class="ml-1 subtitle-2">{{ item.address.region }}</span>
            </p>
            <p v-if="item.address && item.address.lot_number">
              <span class="caption">{{ $t("parcel") }}:</span>
              <span class="ml-1 subtitle-2">{{ item.address.lot_number }}</span>
            </p>
          </div>
        </template>
      </v-data-table>
    </v-expand-transition>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import lotValidation from '@/mixins/lotValidation';
import uldk from '@/services/uldk-service';

export default {
  name: 'ParcelList',
  props: ['parcels'],
  mixins: [
    lotValidation,
  ],
  data: (vm) => ({
    editDialog: false,
    editedItem: {
      lot_id: null,
      area: null,
      land_register: {
        number: null,
      },
      is_built_up: false,
    },
    headers: [
      { text: vm.$t('parcelId'), align: 'start', value: 'lot_id' },
      { text: vm.$t('registerId'), value: 'land_register.number' },
      { text: vm.$t('area'), value: 'area' },
      { text: vm.$t('editItem'), value: 'actions', sortable: false },
    ],
    valid: true,
    error: {
      show: false,
      message: null,
    },
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['parcel']),
  },
  methods: {
    ...mapActions('newPropertyForm', ['deleteLot', 'updateLot', 'updateParcel']),
    navigateToParcel(data) {
      this.$emit('navigateToParcel', data);
    },
    deleteItem(item) {
      this.deleteLot(item.id);
    },
    editItem(item) {
      this.editedItem = {
        address: item.address,
        area: item.area,
        id: item.id,
        land_register: { ...item.land_register },
        lot_id: item.lot_id,
        is_built_up: item.is_built_up,
      };
      this.editDialog = true;
    },
    closeEdit() {
      this.editDialog = false;
    },
    async saveEditedItem() {
      try {
        const address = await uldk.getAddressFromId(this.editedItem.lot_id);
        const lot = {
          ...this.editedItem,
          address: {
            id: this.editedItem.address.id,
            ...address,
          },
        };
        const parcel = {
          ...this.parcel,
          is_built_up: this.editedItem.is_built_up,
        };
        await this.updateParcel(parcel);
        console.log('will update lot', lot);
        this.updateLot(lot);
        this.editDialog = false;
      } catch (error) {
        this.showError(error.message);
      }
    },
    showError(code) {
      this.error.message = `${this.$t('error')}: ${this.$t(code)}`;
      this.error.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .location-details {
    font-size: 1rem;
  }
  .v-dialog__content {
    width: 50% !important;
  }
  .v-data-footer__icons-after,
  .v-data-footer__icons-before {
    .v-btn__content {
      color: $f-blue!important;
    }
  }
</style>
