import { camelCase, set as lodashSet } from 'lodash';
/**
 * Generates a mutation 'set' method
 * @param {string} destination - Path to item in state (deep path supported)
 * @return {object} - { key: method }
 * @example
 * mutations: {
 *   ...set('announcement'),
 *   ...set('properties')
 * }
*/

const set = (destination) => {
  const key = camelCase(`set-${destination}`);
  return {
    [key]: (state, payload) => {
      lodashSet(state, destination, payload);
    },
  };
};

/* eslint-disable import/prefer-default-export */
export {
  set,
};
