<template>
  <v-app>
    <Navbar :key="$route.fullPath + Math.random()"/>
    <ErrorMsg v-if="this.isError" :errorShow="this.isError"/>
    <router-view :key="$route.fullPath"/>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Navbar from '@/components/shared/Navbar.vue';
import ErrorMsg from '@/components/shared/ErrorMsg.vue';
import { isNull } from 'lodash';

export default {
  name: 'App',
  async created() {
    if (isNull(this.logged)) {
      window.localStorage.setItem('logged', false);
    } else if (this.logged) {
      // refresh after 30 minutes
      const tokenRefreshInterval = window.setInterval(async () => {
        try {
          const token = await this.refreshToken();
          if (token.status >= 400) {
            localStorage.removeItem('token');
            localStorage.removeItem('logged');
            localStorage.removeItem('refresh_token');
            this.showError(true);
            window.clearInterval(tokenRefreshInterval);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }, 0.900e+6);
      this.showError(false);
    }
  },
  components: { Navbar, ErrorMsg },
  computed: {
    logged() {
      return JSON.parse(window.localStorage.getItem('logged'));
    },
    ...mapGetters('user', ['isError']),
  },
  methods: {
    ...mapActions('user', ['refreshToken', 'showError']),
  },
};
</script>

<style lang="scss">
  .v-application {
    font-family: Muli, sans-serif!important;
    padding: 0 25px;
    color: #41414A!important;
    @include respond-to(md) {
      padding: 0 5px;
    }
  }
  a {
    text-decoration: none;
  }
  p {
    margin-bottom: 0!important;
  }
  .primary {
    .v-btn__content {
      color: $white!important;
      font-weight: bold;
    }
  }
</style>
