<template>
  <v-fade-transition leave-absolute hide-on-leave>
    <v-progress-circular
      class="mx-auto mt-5"
      v-if="hasDraft && draft === null"
      indeterminate
    />
    <new-property-form v-else :propertyDraft="draft"/>
  </v-fade-transition>
</template>

<script>
import NewPropertyForm from '@/components/NewPropertyForm.vue';
import { mapActions } from 'vuex';
import { isNull } from 'lodash';

export default {
  name: 'NewProperty',
  components: { NewPropertyForm },
  async created() {
    if (this.$route.params.uuid) {
      this.hasDraft = true;
      this.draft = await this.getProperty(this.$route.params.uuid);
    } else {
      this.hasDraft = false;
      this.draft = null;
    }

    await this.fetchProperties();
  },
  data: () => ({
    hasDraft: false,
    draft: null,
  }),
  methods: {
    ...mapActions('properties', ['fetchProperties', 'getProperty']),
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== from.name) {
      localStorage.removeItem('lastDraft');
    }

    next();
  },
  beforeRouteEnter(to, from, next) {
    const lastDraft = localStorage.getItem('lastDraft');
    if (isNull(from.name) && !isNull(lastDraft)) {
      next(async (vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.hasDraft = true;
        // eslint-disable-next-line no-param-reassign
        vm.draft = await vm.getProperty(lastDraft);
      });
    } else {
      localStorage.removeItem('lastDraft');
      next();
    }
  },
};
</script>

<style scoped>

</style>
