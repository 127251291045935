<template>
  <v-row>
    <v-col
      cols="12"
      class="d-none d-lg-flex"
    >
      <v-row class="property-offer-list__row">
        <v-col
          cols="6"
          lg="3"
        >
          <div class="d-flex align-center">
            <span class="font-weight-bold">{{ this.index + 1 }}</span>
            <div class="ml-10">
              <p class="font-small">{{ $t('proposalOfPrice') }}</p>
              <p>{{ num(this.offer.amount) }} PLN</p>
            </div>
          </div>
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <p class="font-small">{{ $t('aboveProposedPrice') }}</p>
          <p>{{ getBoolTranslate(this.offer.is_higher_than_price) }}</p>
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <p class="font-small">{{ $t('additionalComment') }}</p>
          <p>{{ getBoolTranslate(this.offer.has_comment) }}</p>
        </v-col>
        <v-col
          cols="6"
          lg="2"
          class="d-flex align-center justify-end"
        >
          <router-link
            :to="{
          name: 'offer',
          params: { propertyId: this.$route.params.propertyId, offerId: this.offer.id }}"
          >
            <span class="font-weight-bold">{{ $t('goToOffer') }}</span><v-icon class="font-blue">mdi-menu-right</v-icon>
          </router-link>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      class="d-flex d-lg-none"
    >
      <v-row class="property-offer-list__row">
        <v-col
          cols="1"
          class="d-flex align-center"
        >
          <div >
            <span class="font-weight-bold">{{ this.index + 1 }}</span>
          </div>
        </v-col>
        <v-col
          cols="11"
          sm="8"
        >
          <v-row>
            <v-col
              cols="6"
              sm="4"
              lg="3"
            >
              <p class="font-small">{{ $t('proposalOfPrice') }}</p>
              <p class="font-weight-bold">{{ num(this.offer.amount) }} PLN</p>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              lg="3"
            >
              <p class="font-small">{{ $t('aboveProposedPrice') }}</p>
              <p class="font-weight-bold">{{ getBoolTranslate(this.offer.is_higher_than_price) }}</p>
            </v-col>
            <v-col
              cols="6"
              sm="3"
              lg="3"
            >
              <p class="font-small">{{ $t('additionalComment') }}</p>
              <p class="font-weight-bold">{{ getBoolTranslate(this.offer.has_comment) }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          class="d-flex align-center justify-center"
        >
          <router-link
            :to="{
                  name: 'offer',
                  params: { propertyId: this.$route.params.propertyId, offerId: this.offer.id }}"
          >
                <span class="font-weight-bold">
                  {{ $t('goToOffer') }}
                </span>
            <v-icon class="font-blue">mdi-menu-right</v-icon>
          </router-link>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PropertySingleOffer',
  props: [
    'offer',
    'index',
  ],
  methods: {
    num(value) {
      return Number(value).toLocaleString();
    },
    getBoolTranslate(item) {
      if (item === false) {
        return this.$t('false');
      }
      if (item === true) {
        return this.$t('true');
      }
      if (item === null) {
        return this.$t('null');
      }
      return item;
    },
  },
};
</script>

<style lang="scss">
  .property-offer-list {
    &__row {
      border-bottom: 1px solid #7070702F;
    }
  }
</style>
