<template>
  <div v-if="isActive">
    <proposal
      @stepCompleted="stepCompleted = $event"
    />
    <v-btn v-if="stepCompleted" color="primary" class="mt-5 mb-1" @click="completed()">
      {{ $t("finish") }}
    </v-btn>
  </div>
</template>

<script>
import Proposal from './Proposal.vue';

export default {
  name: 'AddProposalStep',
  components: {
    Proposal,
  },
  props: [
    'isActive',
  ],
  data: () => ({
    stepCompleted: false,
  }),
  methods: {
    completed() {
      this.$emit('completed');
    },
  },
};
</script>

<style scoped>

</style>
