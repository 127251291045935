<template>
  <v-row class="properties-list__row">
    <v-col
      cols="12"
      md="3"
    >
      <div class="d-md-flex">
        <router-link :to="{ name: 'announcement', params: { propertyId: this.singleProperty.id} }">
          <img
            class="properties-list__image"
            :src="this.singleProperty.announcement.main_picture"
            alt="picture"
          />
        </router-link>
        <div>
          <router-link :to="{ name: 'announcement', params: { propertyId: this.singleProperty.id} }">
            <p class="ml-3 font-weight-bold text-center text-md-left">
              {{ this.singleProperty.announcement.main_address.municipality }},
              ul.{{ this.singleProperty.location_description }}
            </p>
          </router-link>
          <p class="ml-md-3 font-small text-center text-md-left">
            {{ $t('offerNumber') }}
          </p>
          <span class="ml-md-3 text-center text-md-left font-weight-bold">
            {{ this.singleProperty.number_of_offers }}
          </span>
          <span v-if="this.singleProperty.has_new_offers">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="font-blue"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >mdi-information</v-icon>
              </template>
              <span>{{ $t('newOffer')}}</span>
            </v-tooltip>
          </span>
        </div>
      </div>
    </v-col>
    <v-col
      cols="6"
      md="2"
      lg="1"
    >
      <p class="ml-md-3 font-small mt-2">{{ $t('createdAt') }}</p>
      <p class="ml-md-3 font-small font-weight-bold">
        {{ this.singleProperty.announcement.created_at | luxon:format('dd/MM/yyyy') }}
      </p>
    </v-col>
    <v-col
      cols="6"
      md="2"
      lg="2"
    >
      <p class="font-small mt-2">{{ $t('pum') }}:</p>
      <p
        class="font-small font-weight-bold"
      >
        max. {{ num(this.singleProperty.announcement.main_usable_area_calculation.pum) }} m<sup>2</sup>
      </p>
    </v-col>
    <v-col
      cols="6"
      md="2"
      lg="2"
    >
      <p class="font-small mt-2">{{ $t('puu') }}:</p>
      <p
        class="font-small font-weight-bold"
      >
        max. {{ num(this.singleProperty.announcement.main_usable_area_calculation.puu) }} m<sup>2</sup>
      </p>
    </v-col>
    <v-col
      cols="6"
      md="2"
    >
      <p class="font-small mt-2">{{ $t('surface') }}:</p>
      <p
        class="font-small font-weight-bold"
      >
        {{ num(this.singleProperty.total_lots_area) }} m<sup>2</sup>
      </p>
    </v-col>
    <v-col
      cols="12"
      lg="2"
      class="d-flex align-center justify-center justify-lg-end"
    >
      <router-link
        v-if="hasOffers"
        :to="{ name: 'offerList',
        params: { propertyId: this.singleProperty.id } }"
      >
        <span class="font-weight-bold">{{ $t('goToOffer') }}</span><v-icon class="font-blue">mdi-menu-right</v-icon>
      </router-link>
      <span v-else>
         <span
           class="font-weight-bold">{{ $t('goToOffer') }}
        </span>
        <v-icon>mdi-menu-right</v-icon>
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SingleProperty',
  props: [
    'singleProperty',
  ],
  computed: {
    hasOffers() {
      return this.singleProperty.number_of_offers > 0;
    },
  },
  methods: {
    num(value) {
      return Number(value).toLocaleString();
    },
  },
};
</script>

<style lang="scss">
  .properties-list {
    &__row {
      border-bottom: 1px solid #7070702F;
    }
    &__image {
      @include respond-to(sm) {
        height: auto;
        width: 100%;
      }
      height: 76px;
      .v-image__image,
      .v-responsive__content {
        width: 76px!important;
      }
    }
  }
</style>
