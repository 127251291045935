<template>
  <div>
    <v-container class="text-center" v-if="fetchingData">
      <v-progress-circular indeterminate/>
    </v-container >
    <v-container v-else class="pa-0">
      <v-row>
        <v-col class="font-small font-weight-bold">
          {{ $t('intermediationContractInfo') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-1">
          {{ $t('contractAcceptance') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="font-small py-0">
          {{ $t('contractPreviewInfo') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-checkbox
            v-model="checked"
            class="mt-0 checkbox"
            :label="$t('accept')"
            @change="onCheckboxChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="3" md="5">
          <v-btn
            :disabled="!this.currentProperty.contract_accepted"
            @click="completed()"
            class="w-100 btn-blue-rounded"
          >
            {{ $t("next") }}
          </v-btn>
        </v-col>
        <v-col xl="3" md="5">
          <v-btn
            @click="() => $router.push('/properties')"
            class="w-100 btn-blue-rounded"
          >
            {{ $t("saveDraft") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'IntermediationContact',
  async created() {
    if (this.$route.params.propertyId) {
      this.fetchingData = true;
      await this.fetchProperties();
      this.currentProperty = await this.getProperty(this.$route.params.propertyId);
      this.checked = this.currentProperty.contract_accepted;
      this.fetchingData = false;
    }
  },
  data: () => ({
    currentProperty: {
      contract_accepted: false,
    },
    fetchingData: false,
    checked: false,
  }),
  methods: {
    ...mapActions('properties', ['getProperty', 'updateProperty', 'fetchProperties']),
    async onCheckboxChange(value) {
      if (!value) this.currentProperty.contract_accepted = false;
      await this.updateProperty({
        property: this.currentProperty,
        updatedPropertyField: 'contract_accepted',
        updatedValue: value,
      });
      this.currentProperty = await this.getProperty(this.$route.params.propertyId);
    },
    completed() {
      this.$emit('completed');
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .checkbox .v-label {
    font-size: 14px;
  }
</style>
