<template>
  <div class="announcement__info">
    <v-row class="offer-details">
      <v-col
        cols="12"
      >
        <p>{{ $t('sendOffer')}}</p>
        <h2>{{ price(offer.amount)}} PLN</h2>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <p>{{ $t('valueForYour') }}</p>
            <p class="font-weight-bold">{{ numberWithSpaces(offer.amount_difference) }} PLN</p>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <p>{{ $t('ranking') }}</p>
            <p class="font-weight-bold">{{ price(offer.ranking) }} {{ $t('position') }}</p>
            <router-link class="small" :to="{ name: 'offerList' }">
              {{ $t('seeOfferts') }}
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="offer.comment">
          <v-col cols="12">
            <p>{{ $t('offertComment') }}</p>
            <p>{{ offer.comment }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="10"
            lg="7"
            v-if="offer.status === 'active' && this.showButtons"
          >
            <div class="d-flex justify-space-between offer-btn-container" v-if="!success">
              <v-btn
                color="success"
                @click="acceptModal = !acceptModal"
              >
                {{ $t('acceptOffer')}}
              </v-btn>
              <v-btn
               class="btn-outlined-red"
                @click="rejectModal = true"
              >
                {{ $t('rejectOffer')}}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="mt-8"
            v-if="offer.status === 'accepted'"
          >
            <p class="text-center font-weight-bold">{{ $t('offerAccepted') }}</p>
          </v-col>
          <v-col
            cols="12"
            class="mt-8"
            v-if="offer.status === 'rejected'"
          >
            <p class="text-center font-weight-bold">{{ $t('offerRejected') }}</p>
            <p
              class="text-center"
              v-if="offer.rejection_reason"
            >
              {{ $t('rejectionReason') }}: {{ offer.rejection_reason }}
            </p>
          </v-col>
        </v-row>
        <v-dialog v-model="acceptModal" max-width="900px">
          <v-card class="pa-5" v-if="!success">
            <h1 class="text-center">
              {{ $t("modalAcceptOfferHeader" )}}
            </h1>
            <p class="text-center mt-6">
              {{ $t('modalAcceptOfferText')}}
            </p>
            <v-row>
              <v-col
                cols="8"
                offset="2"
              >
                <div class="d-flex justify-space-between mt-10">
                  <v-btn
                    color="success"
                    @click="acceptProposal()"
                  >
                    {{ $t('acceptOffer')}}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="acceptModal = !acceptModal"
                    outlined
                  >
                    {{ $t('backToOffer') }}
                  </v-btn>
                </div>
                <v-alert
                  class="mt-5"
                  type="error"
                  v-model="validationErrors.show"
                  dismissible
                  transition="fade-transition"
                >
                  <p
                    v-for="error in validationErrors.messages"
                    :key="error"
                  >
                    {{ error }}
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-5" v-else>
            <h1 class="text-center">
              {{ $t("acceptedOffer" )}}
            </h1>
          </v-card>
        </v-dialog>
        <v-dialog v-model="rejectModal" max-width="900px">
          <v-card class="pa-5" v-if="!success">
            <h1 class="text-center">
              {{ $t("modalRejectOfferHeader" )}}
            </h1>
            <v-row>
              <v-col
                cols="8"
                offset="2"
              >
                <div class="d-flex justify-space-between offer-btn-container mt-10">
                  <v-btn
                    :class="[ reject ? 'btn-simple-red' : 'btn-outlined-red' ]"
                    @click="firstReject($event)"
                  >
                    {{ $t('rejectOffer')}}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="rejectModal = !rejectModal; reject = false"
                   outlined
                  >
                    {{ $t('backToOffer') }}
                  </v-btn>
                </div>
                <div v-if="reject" class="text-center mt-8">
                  <v-textarea
                    :hint="$t('notRequired')"
                    v-model="rejectComment"
                    :label="$t('rejectComment')"
                  />
                  <v-btn
                    color="primary"
                    @click="rejectProposal()"
                  >
                    {{ $t('confirmReject') }}
                  </v-btn>
                </div>
                <v-alert
                  class="mt-5"
                  type="error"
                  v-model="validationErrors.show"
                  dismissible
                  transition="fade-transition"
                >
                  <p
                    v-for="error in validationErrors.messages"
                    :key="error"
                  >
                    {{ error }}
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-5" v-else>
            <h1 class="text-center">
              {{ $t("rejectedOffer" )}}
            </h1>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { findAllByKey } from '@/helpers/functions';

export default {
  name: 'OfferDetails',
  data: () => ({
    acceptModal: false,
    rejectModal: false,
    rejectComment: null,
    reject: false,
    success: false,
    showButtons: true,
    validationErrors: {
      show: false,
      messages: [],
    },
  }),
  computed: {
    ...mapGetters('offer', ['offer']),
  },
  methods: {
    ...mapActions('offer', ['acceptOffer', 'rejectOffer']),
    price(amount) {
      return amount.toLocaleString();
    },
    numberWithSpaces(string) {
      return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    acceptProposal() {
      const { offerId } = this.$route.params;
      this.acceptOffer(offerId).then((response) => {
        if (response.status === 204) {
          this.success = true;
          this.showButtons = false;
        }
      }).catch((error) => {
        this.validationErrors.show = true;
        this.validationErrors.messages = [];
        const errors = findAllByKey(error.response.data.detail, 'message');
        this.validationErrors.messages.push(...errors);
      });
    },
    rejectProposal() {
      const data = {
        rejection_reason: this.rejectComment,
      };
      const { offerId } = this.$route.params;
      this.rejectOffer(offerId, data).then((response) => {
        if (response.status === 204) {
          this.success = true;
          this.showButtons = false;
        }
      }).catch((error) => {
        this.validationErrors.show = true;
        this.validationErrors.messages = [];
        const errors = findAllByKey(error.response.data.detail, 'message');
        this.validationErrors.messages.push(...errors);
      });
    },
    firstReject() {
      this.reject = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .offer {
    &-details {
      padding-top: 10px;
      border-top: 1px solid $black;
    }
    &-btn-container {
      .v-btn {
        width: 47%;
        @include respond-to(md) {
          width: 49%;
        }
      }
    }
  }
  .primary {
    ::v-deep .v-btn__content {
      color: #fff !important;
    }
  }
  .btn-outlined-red {
    background-color: $white!important;
    border: 1px solid $f-red;
    ::v-deep .v-btn__content {
      color: $f-red!important;
      font-weight: bold;
    }
  }
  .btn-simple-red {
    background-color: $f-red!important;
    border: 1px solid $f-red;
    ::v-deep .v-btn__content {
      color: $white!important;
      font-weight: bold;
    }
  }
</style>
