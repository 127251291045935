import Vue from 'vue';
import Vuex from 'vuex';
import filesService from '@/services/fileUpload';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    filesMeta: {},
  },
  mutations: {
    SET_FILE: (state, file) => {
      Vue.set(state.filesMeta, file.id, file);
    },
  },
  actions: {
    fetchFilesMeta: async ({ commit }, filesIds) => {
      filesIds.map(async (id) => {
        const meta = await filesService.getFileMeta(id);
        commit('SET_FILE', meta);
      });
    },
  },
  getters: {
    getFileMeta: (state) => (id) => (state.filesMeta[id] ? state.filesMeta[id] : {}),
  },
};
