<template>
  <v-container fluid class="contract stepper">
    <v-row>
      <v-col v-if="!showForm">
        <UserContractInfo
          @showForm="showForm = true"
        />
      </v-col>
      <v-col v-else-if="fetchingData">
        <div class="text-center mt-5">
          <v-progress-circular indeterminate/>
        </div>
      </v-col>
      <v-col v-else>
        <v-alert
          class="mt-5"
          type="error"
          v-model="error.isError"
          dismissible
          transition="fade-transition"
        >
          {{error.message}}
        </v-alert>
        <v-stepper
          v-if="localStep < 4"
          v-model="localStep"
          vertical
          @change="stepperChanged"
        >
          <v-stepper-step
            :complete="currentStep > 1"
            editable
            step="1"
          >
            {{ $t("buyerDetails") }}
          </v-stepper-step >
          <v-stepper-content step="1" class="pb-5">
            <OwnerData
              @ownerAdded="handleOwnerAdded"
              :owner="this.investorContractData"
              :isActive="localStep === 1"
              :key="this.rerenderComponent"
            />
          </v-stepper-content>

          <v-stepper-step
            :complete="currentStep > 2"
            :editable="currentStep > 1"
            step="2"
          >
            {{ $t("intermediationContract") }}
          </v-stepper-step>
          <v-stepper-content step="2">
            <IntermediationUserContract
              :isActive="localStep === 2"
              @completed="nextStep(2)"
            />
          </v-stepper-content>

          <v-stepper-step
            step="3"
            :complete="currentStep > 3"
            :editable="currentStep > 2"
          >
            {{ $t("shipmentDetails") }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <ShippingAddress
              :currentOwner="this.investorContractData"
              @completedDeliveryForm="completedDeliveryForm"
            />
          </v-stepper-content>
        </v-stepper>
        <v-container v-else class="d-flex flex-column align-center justify-center vh-100">
          <v-row class="justify-center align-content-end">
            <v-col cols="8" class="text-center">
              {{ $t("investorContractInfo") }}
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col>
              <v-btn
                @click="redirectToNewInvestorProfile"
                class="w-100 btn-blue-rounded"
              >
                {{ $t("next") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-if="localStep === 2">
        <v-container class="pa-0">
          <v-card
            elevation="4"
            class="mb-1"
          >
            <v-card-title class="justify-center">
              {{ $t('contractPreview') }}
            </v-card-title>
          </v-card>
          <v-card
            elevation="4"
            class="d-flex justify-center pdf-preview"
          >
            <vue-pdf-embed
              :source="this.pdfContract"
            />
          </v-card>
          <v-card
            elevation="4"
            class="mt-1"
          >
            <v-card-title class="justify-end">
              <a
                :href="this.pdfContract"
                :download="`contract-${Date.now()}.pdf`"
              >
                <v-btn class="btn-blue-rounded">
                  {{ $t('downloadPdf') }}
                  <v-icon class="ms-3">
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
              </a>
            </v-card-title>
          </v-card>
        </v-container>
      </v-col>
      <v-col v-else class="hidden-sm-and-down">
        <div class="agreement-image"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserContractInfo from '@/components/userContractInfo/UserContractInfo.vue';
import OwnerData from '@/components/contractForm/OwnerData.vue';
import IntermediationUserContract from '@/components/contractForm/IntermediationUserContract.vue';
import ShippingAddress from '@/components/contractForm/ShippingAddress.vue';
import { mapActions, mapGetters } from 'vuex';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
  name: 'UserContract.vue',
  components: {
    UserContractInfo,
    OwnerData,
    IntermediationUserContract,
    ShippingAddress,
    VuePdfEmbed,
  },
  async created() {
    this.fetchingData = true;
    this.resetContractData();
    await this.currentUser();
    const investorContractId = {
      businessContractId: this.user.investor_contract.business_user_contract_id,
      naturalPersonContractId: this.user.investor_contract.natural_person_user_contract_id,
    };
    await this.getContractData(investorContractId);
    this.fetchingData = false;
  },
  data: () => ({
    fetchingData: false,
    showForm: false,
    localStep: 1,
    rerenderComponent: false,
    pdfContract: null,
  }),
  computed: {
    ...mapGetters('userContract',
      [
        'currentStep',
        'investorContractData',
        'intermediationInvestorContract',
        'error',
      ]),
    ...mapGetters('user', ['user']),
    ...mapGetters('companyPartner', ['companyPartnerError']),
  },
  methods: {
    ...mapActions('userContract',
      [
        'setStep',
        'getContractData',
        'createOrUpdateNaturalPersonContract',
        'createOrUpdateBusinessContract',
        'deleteNaturalPersonContract',
        'deleteBusinessContract',
        'resetContractData',
        'getIntermediationInvestorContract',
      ]),
    ...mapActions('user', ['currentUser']),
    ...mapActions('companyPartner', ['createOrUpdateCompanyPartner']),
    async handleOwnerAdded({ owner, partners }) {
      await this.currentUser();
      if (owner.owner_type === 'natural_person') {
        await this.handleNaturalPersonAdded(owner);
      } else if (owner.owner_type === 'company') {
        await this.handleCompanyAdded(owner, partners);
      }
      if (!this.error.isError && !this.companyPartnerError.isError) {
        await this.showIntermediationContractPdf();
      }
    },
    stepperChanged(val) {
      this.localStep = parseInt(val, 10);
    },
    nextStep(fromStep) {
      this.setStep(fromStep + 1);
      this.localStep = fromStep + 1;
    },
    async completedDeliveryForm(data) {
      if (data) {
        this.fetchingData = true;
        if (this.investorContractData.owner_type === 'natural_person') {
          await this.createOrUpdateNaturalPersonContract({
            id: this.investorContractData.id,
            delivery_address: data.id,
          });
        } else {
          await this.createOrUpdateBusinessContract({
            id: this.investorContractData.id,
            delivery_address: data.id,
          });
        }
        this.fetchingData = false;
        this.nextStep(3);
      }
    },
    async showIntermediationContractPdf() {
      await this.getIntermediationInvestorContract();
      this.pdfContract = this.intermediationInvestorContract;
    },
    redirectToNewInvestorProfile() {
      this.$router.push({ name: 'newProfile', params: { uuid: this.user.id } });
    },
    async handleNaturalPersonAdded(owner) {
      if (this.user.investor_contract.business_user_contract_id) {
        await this.deleteBusinessContract(this.user.investor_contract.business_user_contract_id);
      }
      await this.createOrUpdateNaturalPersonContract({
        ...owner,
        phone_number: `+${owner.phone_number}`,
        type: this.user.type,
        user: this.user.id,
      });
      if (!this.error.isError && !this.companyPartnerError.isError) {
        await this.nextStep(1);
      }
      this.rerenderComponent = !this.rerenderComponent;
    },
    async handleCompanyAdded(owner, partners) {
      if (this.user.investor_contract.natural_person_user_contract_id) {
        await this.deleteNaturalPersonContract(this.user.investor_contract.natural_person_user_contract_id);
      }
      await this.createOrUpdateBusinessContract({
        ...owner,
        phone_number: `+${owner.phone_number}`,
        type: this.user.type,
        user: this.user.id,
      });
      if (partners.length) {
        await Promise.all(partners.map((partner) => {
          this.createOrUpdateCompanyPartner({
            ...partner,
            user_contract: this.investorContractData.id,
          });
          return null;
        }));
      }
      if (!this.error.isError && !this.companyPartnerError.isError) {
        await this.nextStep(1);
      }
      this.rerenderComponent = !this.rerenderComponent;
    },
  },
};
</script>

<style scoped lang="scss">
  .agreement-image {
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    background-image: url("../assets/images/background.jpg");
    background-position: center;
    background-size: cover;
  }

  .pdf-preview {
    .vue-pdf-embed {
      width: 90%;
      max-height: 80vh;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    background-color: $f-grey-light;
  }
</style>
