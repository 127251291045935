<template>
  <v-app>
    <v-container fluid class="new-password">
      <v-row class="pa-5">
        <v-col
          cols="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="0"
          class="d-flex align-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="10"
              lg="6"
              offset-md="1"
              offset-lg="3"
            >
              <v-row>
                <v-col
                  sm="12"
                  v-if="!this.success && !this.errorMsg"
                >
                  <h2 class="new-password__header font-weight-regular mb-5">
                    {{ $t('setNewPassword') }}
                  </h2>
                  <v-form
                    v-model="valid"
                    ref="form"
                    :lazy-validation="lazy"
                    @keyup.enter.native="validate"
                    class="pa-5 pa-md-0"
                  >
                    <v-text-field
                      v-model="password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[passwordRules.required, passwordRules.min]"
                      :type="show ? 'text' : 'password'"
                      name="input-10-1"
                      :label="$t('password')"
                      :hint="$t('eightChars')"
                      prepend-inner-icon="mdi-key-variant"
                      counter
                      @click:append="show = !show"
                    ></v-text-field>
                    <v-text-field
                      v-model="confirmPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[(password === confirmPassword) || 'Hasła muszą być takie same']"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      :label="$t('confirmPassword')"
                      :hint="$t('eightChars')"
                      prepend-inner-icon="mdi-key-variant"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                    <div class="new-password__button-box my-5">
                      <v-btn
                        :disabled="!valid"
                        class="px-8 w-100 btn-gradient-blue"
                        @click="validate"
                        rounded
                      >
                        {{ $t('changePassword') }}
                      </v-btn>
                    </div>
                  </v-form>
                </v-col>
                <v-col
                  sm="12"
                  v-else-if="this.success && !this.errorMsg"
                >
                  <h2 class="text-center font-weight-regular mb-5">
                    {{ $t('passwordSuccess') }}
                  </h2>
                  <div class="new-password__button-box my-5">
                    <router-link :to="{ name: 'login'}">
                      <v-btn
                        class="px-8 w-100 btn-gradient-blue"
                        rounded
                      >
                        {{ $t('goToApp') }}
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
                <v-col
                  sm="12"
                  v-else-if="!this.success && this.errorMsg"
                >
                  <h2 class="text-center font-weight-regular mb-5">
                    {{ $t('passwordError') }}
                  </h2>
                  <div class="new-password__button-box my-5">
                    <router-link :to="{ name: 'reset' }">
                      <v-btn
                        class="px-8 w-100 btn-gradient-blue"
                        rounded
                      >
                        {{ $t('resendEmail') }}
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="d-none d-md-block">
          <div class="login-background">
            <img src="../assets/images/background.jpg"
             alt="background">
            <div class="login-background__content">
              <p class="login-background__content--text">
                {{ $t('loginText')}}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'newPassword',
  data() {
    return {
      password: '',
      passwordRules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters or longer',
      },
      confirmPassword: '',
      show: false,
      show1: false,
      lazy: false,
      valid: false,
      success: false,
      errorMsg: false,
    };
  },
  methods: {
    ...mapActions('user', ['newUserPassword']),
    validate() {
      if (this.$refs.form.validate()) {
        const data = {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password: this.password,
          re_new_password: this.confirmPassword,
        };
        this.newUserPassword(data).then((response) => {
          if (response.status === 200) {
            this.success = true;
          }
        }).catch((error) => {
          if (error.response.status === 400) {
            this.errorMsg = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
  .new-password {
    @include respond-to(sm) {
      background-image: image-set(
          '../assets/images/background.jpg' 1x
      );
      background-size: cover;
      background-position: center;
      min-height: 100vh;
    }
    &__header,
    &__subheader {
      @include respond-to(sm) {
        color: $white;
      }
    }
    .v-input__icon.v-input__icon--prepend-inner .v-icon {
      color: $f-blue;
    }
    .v-input__slot::before {
      border-color: $f-blue!important;
    }
    .v-btn__content {
      color: $white;
      font-weight: bold;
    }
    .v-form {
      background: $white;
    }
  }
</style>
