<template>
  <v-row class="property-offer">
    <v-col
      cols="12"
      md="3"
      lg="3"
      xl="3"
    >
      <div class="d-md-flex">
        <img
          class="announcements-list__image"
          :src="this.announcement.main_picture"
          alt="picture"
        />
        <div>
          <p class="ml-3 mb-1 font-small text-center text-md-left">
            {{ $t('offersFor') }}
          </p>
          <p class="ml-3 font-weight-bold text-center text-md-left">
            {{ this.announcement.main_address.municipality }}
            ul.{{ this.propertyOffers.location_description }}
          </p>
        </div>
      </div>
    </v-col>
    <v-col
      cols="6"
      md="2"
      lg="1"
      v-if="this.capacity.concept_pum"
    >
      <p class="font-small mt-2">{{ $t('conceptionPum') }}
      <p
        class="font-small font-weight-bold"
      >
        max. {{ num(this.capacity.concept_pum) }} m<sup>2</sup>
      </p>
    </v-col>
    <v-col
      cols="6"
      lg="1"
      v-if="this.capacity.concept_puu"
    >
      <p class="font-small mt-2">{{ $t('conceptionPuu') }}
      <p
        class="font-small font-weight-bold"
      >
        max. {{ num(this.capacity.concept_puu) }} m<sup>2</sup>
      </p>
    </v-col>
    <v-col
      cols="6"
      md="2"
      lg="2"
    >
      <p class="font-small mt-2">{{ $t('pum') }}:</p>
      <p
        class="font-small font-weight-bold"
      >
        max. {{ num(this.announcement.main_usable_area_calculation.pum) }} m<sup>2</sup>
      </p>
    </v-col>
    <v-col
      cols="6"
      md="2"
      lg="2"
    >
      <p class="font-small mt-2">{{ $t('puu') }}:</p>
      <p
        class="font-small font-weight-bold"
      >
        max. {{ num(this.announcement.main_usable_area_calculation.puu) }} m<sup>2</sup>
      </p>
    </v-col>
    <v-col
      cols="6"
      md="2"
      lg="2"
    >
      <p class="font-small mt-2">{{ $t('surface') }}:</p>
      <p
        class="font-small font-weight-bold"
      >
        {{ num(this.propertyOffers.total_lots_area) }} m<sup>2</sup>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PropertyOffer',
  computed: {
    ...mapGetters('propertyOffers', ['announcement', 'capacity', 'propertyOffers']),
  },
  methods: {
    num(value) {
      return Number(value).toLocaleString();
    },
  },
};
</script>

<style lang="scss">
  .announcements-list {
    &__image {
      @include respond-to(sm) {
        height: auto;
        width: 100%;
      }
      height: 76px;
    }
  }
</style>
