<template>
  <div class="properties-list" v-if="this.announcements">
    <h3 class="mb-5">{{ $t('yourPublishedProperties') }} ({{ this.announcements.length }})</h3>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <div class="properties-list__radio my-5">
          <div class="d-flex align-center">
            <v-radio-group
              v-model="type"
              :rules="[v => !!v || 'Required']"
              required
            >
              <v-radio
                :label="lengthParcelsLabel()"
                value="parcel"
              />
              <v-radio
                :label="lengthBuildingsLabel()"
                value="building"
              />
            </v-radio-group>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="this.announcements.length">
      <div v-if="type === 'parcel'">
        <template v-for="(item, index) in parcels">
          <SingleProperty :key="index" :singleProperty="item" />
        </template>
        <div class="d-flex justify-center mt-5">
          <button
            class="btn-pagination"
            :disabled="page === 1"
            @click="prev()"
          >
            <v-icon class="font-blue font-big pointer">mdi-menu-left</v-icon>
          </button>
          <span class="d-flex align-center">{{ this.page }}</span>
          <button
            class="btn-pagination"
            :disabled="disableNextParcel"
            @click="next()"
          >
            <v-icon class="font-blue font-big pointer">mdi-menu-right</v-icon>
          </button>
        </div>
      </div>
      <div v-else>
        <template v-for="(item, index) in buildings">
          <SingleProperty :key="index" :singleProperty="item" />
        </template>
        <div class="d-flex justify-center mt-5">
          <button
            class="btn-pagination"
            :disabled="page === 1"
            @click="prev()"
          >
            <v-icon class="font-blue font-big pointer">mdi-menu-left</v-icon>
          </button>
          <span class="d-flex align-center">{{ this.page }}</span>
          <button
            class="btn-pagination"
            :disabled="disableNextBuildings"
            @click="next()"
          >
            <v-icon class="font-blue font-big pointer">mdi-menu-right</v-icon>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="font-weight-bold mt-2">{{ $t('noOffers')}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { slice } from 'lodash';
import SingleProperty from './SingleProperty.vue';

export default {
  name: 'PropertiesList',
  data: () => ({
    type: 'parcel',
    limit: 6,
    page: 1,
    parcelsLength: null,
    buildingsLength: null,
  }),
  components: {
    SingleProperty,
  },
  watch: {
    type: {
      handler() {
        this.page = 1;
      },
    },
  },
  computed: {
    ...mapGetters('propertyAnnouncements', ['announcements']),
    parcels() {
      return this.announcements.filter((p) => p.property_type === 'parcel');
    },
    buildings() {
      return this.announcements.filter((p) => p.property_type === 'building');
    },
    disableNextParcel() {
      return Math.ceil(this.parcels.length / 6) <= this.page;
    },
    disableNextBuildings() {
      return Math.ceil(this.buildings.length / 6) <= this.page;
    },
    propertyOffers() {
      const start = (this.page - 1) * this.limit;
      const end = start + this.limit;
      return slice(this.announcements, start, end);
    },
  },
  methods: {
    lengthParcelsLabel() {
      return `${this.$t('parcels')} (${this.parcels.length})`;
    },
    lengthBuildingsLabel() {
      return `${this.$t('buildings')} (${this.buildings.length})`;
    },
    prev() {
      this.page -= 1;
    },
    next() {
      this.page += 1;
    },
  },
};
</script>

<style lang="scss">
  .properties-list {
    &__radio  {
      width: 100%;
      .v-input__slot {
        margin-bottom: 0!important;
      }
      .v-input--selection-controls {
        margin-top: 0!important;
      }
      .v-input--radio-group__input {
        flex-direction: row!important;
        & * {
          margin-bottom: 0!important;
        }
        .v-input--selection-controls__input {
          display: none;
        }
        label {
          border: 1px solid $f-blue;
          padding: 10px 30px;
          justify-content: center;
          font-weight: bold;
        }
        .v-item--active {
          label {
            background-color: $f-blue!important;
            color: $white;
            font-weight: bold;
          }
        }
        .v-radio {
          background-color: #F8F8F8;
          &:first-child {
            label {
              border-bottom-left-radius: 15px;
              border-top-left-radius: 15px;
            }
          }
          &:not(:first-child) {
            label {
              border-bottom-right-radius: 15px;
              border-top-right-radius: 15px;
            }
          }
        }
      }
    }
  }
</style>
