<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <AnnouncementsList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import AnnouncementsList from '@/components/properties/PropertiesList.vue';

export default {
  name: 'Properties',
  components: {
    AnnouncementsList,
  },
  created() {
    this.getAnnouncements();
  },
  methods: {
    ...mapActions('propertyAnnouncements', ['getAnnouncements']),
  },
};
</script>
