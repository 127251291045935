<template>
  <div>
    <v-form v-model="streetValid" ref="parcelAddress">
      <v-row>
        <v-col cols="12" md="9">
          <v-text-field
            v-model="parcelAddress.location_description"
            :rules="streetRule"
            maxlength="100"
            :label="$t('locationDescription')"
            class="required"
          />
          <v-text-field
            v-model="parcelAddress.street_name"
            maxlength="100"
            :label="$t('streetName')"
          />
          <v-text-field
            v-model="parcelAddress.street_number"
            maxlength="10"
            :label="$t('streetNumber')"
          />
          <v-text-field
            v-model="parcelAddress.city"
            :rules="requiredRule"
            maxlength="150"
            :label="$t('city')"
            class="required"
          />
          <v-text-field
            v-model="parcelAddress.postal_code"
            :rules="requiredRule"
            maxlength="10"
            :label="$t('zipCode')"
            class="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn
            color="primary"
            class="elevation-0 mt-3"
            :disabled="disableSaveAddress || !streetValid"
            @click="saveAddress"
          >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="property.location_description">
      <v-switch class="ml-2" v-model="noId" :label="$t('parcelIdUnknown')"/>
      <v-form v-if="noId" v-model="noIdValid" ref="lotWithoutId" class="new-property-form">
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              :items="voivodeships"
              v-model="lotNoIdQuery.voivodeship"
              :label="$t('voivodeship')"
              class="listToTop"
              :rules="requiredRule"
              required
              @change="error.show = false"
              @blur="tryGettingId"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="lotNoIdQuery.regionName"
              :label="$t('regionName')"
              :rules="requiredRule"
              required
              @change="error.show = false"
              @blur="tryGettingId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex align-center relative noid">
            <v-combobox
              :items="landRegisterCodes"
              v-model="landRegisterCodeNoId"
              :label="$t('departmentCode')"
              class="listToTop region-code"
              :class="{'error--text v-input--has-state v-input--is-dirty': invalidRegisterNumber }"
              :rules="requiredRule"
              required
              @change="error.show = false"
              @blur="validateRegisterNoId"
            >
              <template v-slot:selection="{ item }">
                {{ item.value }}
              </template>
            </v-combobox>
            <span class="px-1">/</span>
            <v-text-field
              v-model="landRegisterNumberNoId"
              :rules="onlyNumber"
              :label="$t('registerId')"
              :class="{'error--text v-input--has-state v-input--is-dirty': invalidRegisterNumber }"
              required
              @change="error.show = false"
              class="land-register-number"
              @blur="validateRegisterNoId"
            />
            <span class="px-1">/</span>
            <v-text-field
              v-model="controlNumberNoId"
              :rules="onlyNumber"
              :label="$t('controlNumber')"
              class="land-register-number-control"
              :class="{'error--text v-input--has-state v-input--is-dirty': invalidRegisterNumber }"
              required
              type="number"
              oninput="value === '' ? value = '' : value < 0 ? value = value * -1 : false"
              @change="error.show = false"
              @blur="validateRegisterNoId"
            />
            <p v-if="invalidRegisterNumber" class="error-kw">{{ $t('validNumberKw')}}</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="lotNoIdQuery.parcelNumber"
              :label="$t('parcelNumber')"
              required
              :rules="requiredRule"
              @change="error.show = false"
              @blur="tryGettingId"
            />
            <p v-if="lotExist" class="red--text">{{ $t('lotExist')}}</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="lotNoIdQuery.area"
              type="number"
              :rules="areaRules"
              :label="$t('areaHa')"
              oninput="value === '' ? value = '' : value < 0 ? value = value * -1 : false"
              min="0.0001"
              @change="error.show = false"
              @input="updateMeters"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="areaInMeters"
              type="number"
              :rules="areaRules"
              :label="$t('areaM')"
              oninput="value === '' ? value = '' : value < 0 ? value = value * -1 : false"
              min="0.0001"
              @change="error.show = false"
              @input="updateHa"
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="lotNoIdQuery.is_built_up"
              :label="$t('isBuiltUp')"
            />
          </v-col>
        </v-row>
        <p class="mb-4 mt-1"><span class="red--text">*</span> {{ $t('requiredField')}}</p>
        <v-btn
          class="mr-4 btn-blue-rounded"
          :loading="submitting"
          @click="submitWithoutId"
          :disabled="submitting || !noIdValid || lotExist"
        >
          {{ $t("addLot" )}}
        </v-btn>
      </v-form>
      <v-form
        v-else
        v-model="valid"
        ref="lotWithId"
        @keyup.enter.native="submitWithId"
        class="new-property-form"
      >
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="lotWithIdQuery.lot_id"
              :rules="parcelIdRules"
              :label="$t('parcelId')"
              @blur="idAdded(lotWithIdQuery.lot_id)"
            />
            <p v-if="lotExist" class="red--text">{{ $t('lotExist')}}</p>
          </v-col>
          <v-col cols="12" class="d-flex align-center relative id">
            <v-combobox
              :items="landRegisterCodes"
              v-model="landRegisterCodeWithId"
              :label="$t('departmentCode')"
              class="listToTop region-code"
              :class="{'error--text v-input--has-state v-input--is-dirty': invalidRegisterNumber }"
              :rules="requiredRule"
              required
              @change="error.show = false"
              @blur="validateRegisterWithId"
            >
              <template v-slot:selection="{ item }">
                {{ item.value }}
              </template>
            </v-combobox>
            <span class="px-1">/</span>
            <v-text-field
              v-model="landRegisterNumberWithId"
              :rules="onlyNumber"
              :label="$t('registerId')"
              :class="{'error--text v-input--has-state v-input--is-dirty': invalidRegisterNumber }"
              required
              @change="error.show = false"
              class="land-register-number"
              @blur="validateRegisterWithId"
            />
            <span class="px-1">/</span>
            <v-text-field
              v-model="controlNumberWithId"
              :rules="onlyNumber"
              :label="$t('controlNumber')"
              class="land-register-number-control"
              :class="{'error--text v-input--has-state v-input--is-dirty': invalidRegisterNumber }"
              required
              type="number"
              oninput="value === '' ? value = '' : value < 0 ? value = value * -1 : false"
              @change="error.show = false"
              @blur="validateRegisterWithId"
            />
            <p v-if="invalidRegisterNumber" class="error-kw">{{ $t('validNumberKw')}}</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="lotWithIdQuery.area"
              type="number"
              :rules="areaRules"
              :label="$t('areaHa')"
              oninput="value === '' ? value = '' : value < 0 ? value = value * -1 : false"
              min="0.0001"
              @change="error.show = false"
              @input="updateMeters"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="areaInMeters"
              type="number"
              :rules="areaRules"
              :label="$t('areaM')"
              oninput="value === '' ? value = '' : value < 0 ? value = value * -1 : false"
              min="0.0001"
              @change="error.show = false"
              @input="updateHa"
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="lotWithIdQuery.is_built_up"
              :label="$t('isBuiltUp')"
            />
          </v-col>
        </v-row>
        <p class="mb-10 mt-1"><span class="red--text">*</span> {{ $t('requiredField')}}</p>
        <v-btn
          class="mr-4 btn-blue-rounded"
          @click="submitWithId"
          :loading="submitting"
          :disabled="submitting || !valid || lotExist"
        >
          {{ $t("addLot") }}
        </v-btn>
      </v-form>
    </div>
    <v-alert
      class="mt-5"
      type="error"
      v-model="error.show"
      dismissible
      transition="fade-transition">
      {{ error.message }}
    </v-alert>
  </div>
</template>

<script>
import validations from '@/mixins/validations';
import uldk from '@/services/uldk-service';
import { mapActions, mapGetters } from 'vuex';
import voivodeshipsData from '@/data/voivodeships.json';
import landRegisterCodesData from '@/data/land_register_codes.json';
import lotValidation from '@/mixins/lotValidation';
import { some, isEqual } from 'lodash';
import { eventBus } from '@/services/eventBus';

export default {
  name: 'LotEntry',
  mixins: [
    lotValidation,
    validations,
  ],
  created() {
    this.parcelAddress.location_description = this.property.location_description;
    this.parcelAddress.street_name = this.property.street_name;
    this.parcelAddress.street_number = this.property.street_number;
    this.parcelAddress.city = this.property.city;
    this.parcelAddress.postal_code = this.property.postal_code;
  },
  mounted() {
    eventBus.$on('lotArea', this.onAreaFetched);
  },
  data: () => ({
    areaInMeters: null,
    valid: false,
    noIdValid: false,
    noId: false,
    streetValid: false,
    landRegisterCodeWithId: null,
    landRegisterCodeNoId: null,
    controlNumberWithId: null,
    controlNumberNoId: null,
    landRegisterNumberWithId: null,
    landRegisterNumberNoId: null,
    parcelAddress: {
      location_description: null,
      street_name: null,
      street_number: null,
      city: null,
      postal_code: null,
    },
    lotNoIdQuery: {
      regionName: null,
      parcelNumber: null,
      voivodeship: null,
      area: null,
      is_built_up: false,
    },
    lotWithIdQuery: {
      lot_id: null,
      area: null,
      is_built_up: false,
    },
    error: {
      show: false,
      message: null,
    },
    submitting: false,
    invalidRegisterNumber: false,
    lotExist: false,
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['lots', 'property', 'parcel']),
    fullLandRegisterNumberWithId() {
      return `${this.landRegisterCodeWithId.value}/${this.landRegisterNumberWithId}/${this.controlNumberWithId}`;
    },
    fullLandRegisterNumberNoId() {
      return `${this.landRegisterCodeNoId.value}/${this.landRegisterNumberNoId}/${this.controlNumberNoId}`;
    },
    voivodeships() {
      return voivodeshipsData.voivodeships;
    },
    disableSaveAddress() {
      const currentAddress = {
        city: this.property.city,
        location_description: this.property.location_description,
        postal_code: this.property.postal_code,
        street_name: this.property.street_name,
        street_number: this.property.street_number,
      };
      return isEqual(currentAddress, this.parcelAddress);
    },
    landRegisterCodes() {
      return landRegisterCodesData.land_register_codes;
    },
  },
  watch: {
    noId() {
      this.error.show = false;
    },
    'lotWithIdQuery.lot_id': {
      handler() {
        this.isParcelExist(this.lotWithIdQuery.lot_id);
      },
    },
  },
  methods: {
    ...mapActions('newPropertyForm', ['addLot', 'setAddress', 'updateParcel']),
    async submitWithId() {
      this.invalidRegisterNumber = false;
      if (this.$refs.lotWithId.validate() && this.$refs.parcelAddress.validate()) {
        if (this.validateKW(this.fullLandRegisterNumberWithId)) {
          this.submitting = true;
          try {
            const address = await uldk.getAddressFromId(this.lotWithIdQuery.lot_id);
            const propertyId = await this.addLot({
              lot_id: this.lotWithIdQuery.lot_id,
              land_register: {
                number: this.fullLandRegisterNumberWithId,
              },
              area: (+this.lotWithIdQuery.area * 10000).toFixed(2),
              is_built_up: this.lotWithIdQuery.is_built_up,
              address,
            });
            const parcel = {
              ...this.parcel,
              is_built_up: this.lotWithIdQuery.is_built_up,
            };
            await this.updateParcel(parcel);
            this.$refs.lotWithId.reset();
            this.saveLastDraft(propertyId);
          } catch (error) {
            this.showError(error.message);
          } finally {
            this.submitting = false;
          }
        } else {
          this.invalidRegisterNumber = true;
        }
      }
    },
    async submitWithoutId() {
      if (this.$refs.lotWithoutId.validate() && this.$refs.parcelAddress.validate()) {
        if (this.validateKW(this.fullLandRegisterNumberNoId)) {
          this.submitting = true;

          try {
            const lots = await uldk.getIdFromParcelNameAndNumber(
              this.lotNoIdQuery.regionName,
              this.lotNoIdQuery.parcelNumber,
            );

            const filteredLots = lots
              .filter((uldkLot) => uldkLot.address.voivodeship.toLowerCase()
                  === this.lotNoIdQuery.voivodeship.toLowerCase());

            if (filteredLots.length === 0) {
              this.showError('E_PARCEL_NOT_FOUND_FOR_REGION_NUMBER');
            } else {
              let propertyId = null;
              let parcelAlreadyAdded = false;

              // eslint-disable-next-line no-restricted-syntax
              for (const uldkLot of filteredLots) {
                parcelAlreadyAdded = this.isParcelNoIdExist(uldkLot.address);
                if (!parcelAlreadyAdded) {
                  // eslint-disable-next-line no-await-in-loop
                  propertyId = await this.addLot({
                    lot_id: uldkLot.lot_id,
                    land_register: {
                      number: this.fullLandRegisterNumberNoId,
                    },
                    is_built_up: this.lotNoIdQuery.is_built_up,
                    area: (+this.lotNoIdQuery.area * 10000).toFixed(2),
                    address: uldkLot.address,
                  });
                }
              }
              const parcel = {
                ...this.parcel,
                is_built_up: this.lotNoIdQuery.is_built_up,
              };
              await this.updateParcel(parcel);
              if (!parcelAlreadyAdded) this.$refs.lotWithoutId.reset();
              this.saveLastDraft(propertyId);
            }
          } catch (error) {
            this.showError(error.message);
          } finally {
            this.submitting = false;
          }
        } else {
          this.invalidRegisterNumber = false;
        }
      }
    },
    showError(code) {
      this.error.message = `${this.$t('error')}: ${this.$t(code)}`;
      this.error.show = true;
    },
    idAdded(id) {
      this.isParcelExist(id);
      this.getArea(id);
    },
    isParcelExist(id) {
      this.error.show = false;
      this.lotExist = some(this.lots, { lot_id: id });
      return this.lotExist;
    },
    isParcelNoIdExist(address) {
      this.error.show = false;
      this.lotExist = some(
        this.lots,
        (lot) => address && lot.address.voivodeship === address.voivodeship
        && lot.address.lot_number === address.lot_number
        && lot.address.region === address.region
        && lot.address.municipality === address.municipality
        && lot.address.county === address.county,
      );

      return this.lotExist;
    },
    saveLastDraft(id) {
      localStorage.setItem('lastDraft', id);
    },
    saveAddress() {
      this.setAddress({
        location_description: this.parcelAddress.location_description,
        street_name: this.parcelAddress.street_name,
        street_number: this.parcelAddress.street_number,
        city: this.parcelAddress.city,
        postal_code: this.parcelAddress.postal_code,
      });
    },
    getArea(id, parcelNumber, regionName) {
      if (id) {
        eventBus.$emit('requestArea', { id, parcelNumber, regionName });
      }
    },
    onAreaFetched(payload) {
      if (!this.noId && this.lotWithIdQuery.lot_id === payload.id) {
        this.lotWithIdQuery.area = payload.area;
      } else if (this.lotNoIdQuery.regionName === payload.meta.regionName
          && this.lotNoIdQuery.parcelNumber === payload.meta.parcelNumber) {
        this.lotNoIdQuery.area = payload.area;
      }
    },
    async tryGettingId() {
      this.lotExist = false;
      if (this.lotNoIdQuery.voivodeship && this.lotNoIdQuery.parcelNumber && this.lotNoIdQuery.regionName) {
        const lots = await uldk.getIdFromParcelNameAndNumber(
          this.lotNoIdQuery.regionName,
          this.lotNoIdQuery.parcelNumber,
        );

        const filteredLots = lots
          .filter((uldkLot) => uldkLot.address.voivodeship.toLowerCase()
              === this.lotNoIdQuery.voivodeship.toLowerCase());

        if (filteredLots.length > 0) {
          this.getArea(
            filteredLots[0].lot_id,
            this.lotNoIdQuery.parcelNumber,
            this.lotNoIdQuery.regionName,
          );
        }
      }
    },
    validateRegisterNoId() {
      if (this.landRegisterCodeNoId && this.controlNumberNoId && this.landRegisterNumberNoId) {
        this.invalidRegisterNumber = !this.validateKW(this.fullLandRegisterNumberNoId);
      }
    },
    validateRegisterWithId() {
      if (this.landRegisterCodeWithId && this.controlNumberWithId && this.landRegisterNumberWithId) {
        this.invalidRegisterNumber = !this.validateKW(this.fullLandRegisterNumberWithId);
      }
    },
    updateHa(val) {
      this.lotWithIdQuery.area = val / 10000;
      this.lotNoIdQuery.area = val / 10000;
    },
    updateMeters(val) {
      this.areaInMeters = val * 10000;
    },
  },
  beforeDestroy() {
    eventBus.$off('lotArea');
  },
};
</script>

<style scoped lang="scss">
  .listToTop {
    z-index: 500;
  }

  .error-kw {
    color: red;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
  }

  .error--text.v-input--has-state.v-input--is-dirty {
    ::v-deep .v-label,
    ::v-deep input {
      color: red !important;
    }
  }

  .required {
    ::v-deep .v-label {
      &::after {
        content: '*' !important;
        color: red !important;
      }
    }
  }

  .new-property-form {
    ::v-deep .v-label {
      &::after {
        content: '*' !important;
        color: red !important;
      }
    }

    .v-input--checkbox {
      ::v-deep .v-label {
        &::after {
          content: '' !important;
        }
      }
    }
  }

  .region-code {
    width: 20% !important;
  }

  .land-register-number-control {
    width: 10%;

    ::v-deep input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
</style>
