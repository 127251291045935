<template>
  <v-col cols="12">
    <v-dialog
      v-model="offerDialog"
      persistent
      width="700"
    >
      <v-card
        class="pa-10 offer-form-card"
        v-if="!success"
      >
        <h1 class="text-center">{{ $t('makeAnOfferHeader')}}</h1>
        <h4 class="text-center">{{ $t('makeAnOfferSubHeader')}}</h4>
        <p class="small mt-10" :class="{ 'error--text' : this.amountError }">{{ $t('youOffer')}}</p>
        <v-form
          ref="form"
          :lazy-validation="lazy"
          >
          <currency-input
            v-model="amount"
            currency="PLN"
            locale="pl"
            class="offer-form-card__input"
            :class="{ 'error--text' : this.amountError }"
            :allow-negative=false
            required
          />
          <v-text-field
            v-model="comment"
            :label="$t('comment')"
            required
            :rules="textRules"
            maxlength="500"
          />
          <p class="text-center">{{ $t('agreeOffer')}}</p>
          <v-row>

            <v-col
              cols="12"
              md="3"
              offset-md="4"
            >
              <v-text-field
                v-model="agree"
                required
                :rules="isTheSameRule"
                :label="$t('typeWord')"
                maxlength="3"
                class="text-center"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1"  @click="close()">{{ $t('cancel') }}</v-btn>
            <v-btn
              color="blue darken-1"
              @click="sendOffer()"
              :disabled="!valid"
            >
              {{ $t('makeAnOffer') }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-alert
          class="mt-5"
          type="error"
          v-model="validationErrors.show"
          dismissible
          transition="fade-transition">
          {{ validationErrors.messages }}
        </v-alert>
      </v-card>
      <v-card
        class="pa-10 offer-form-card"
        v-else
      >
        <h1 class="text-center">{{ $t('congratulations')}}</h1>
        <h4 class="text-center">{{ $t('successOffer')}}</h4>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex';
import { findAllByKey } from '@/helpers/functions';

export default {
  name: 'OfferForm',
  data: (vm) => ({
    agree: '',
    amount: null,
    valid: false,
    lazy: false,
    comment: undefined,
    success: false,
    amountError: false,
    validationErrors: {
      show: false,
      messages: [],
    },
    textRules: [
      (v) => !!v || vm.$t('cantBeEmpty'),
    ],
    isTheSameRule: [
      (v) => v.toUpperCase() === 'TAK',
    ],
  }),
  props: [
    'offerDialog',
  ],
  watch: {
    amount: {
      handler() {
        this.valid = this.amount !== null
          && this.comment !== undefined
          && this.comment !== ''
          && this.agree.toUpperCase() === 'TAK';
        this.amountError = !(this.amount !== null);
      },
    },
    comment: {
      handler() {
        this.valid = this.amount !== null && this.comment !== '' && this.agree.toUpperCase() === 'TAK';
      },
    },
    agree: {
      handler() {
        this.valid = this.amount !== null && this.comment !== '' && this.agree.toUpperCase() === 'TAK';
      },
    },
  },
  methods: {
    ...mapActions('offer', ['makeOffer']),
    close() {
      this.$emit('closeDialog');
    },
    sendOffer() {
      if (this.$refs.form.validate() && this.amount !== null) {
        const { amount, comment } = this;
        const data = {
          amount,
          comment,
          property: this.$route.params.propertyId,
        };
        this.makeOffer(data)
          .then((response) => {
            if (response.status === 201) {
              this.success = true;
              this.agree = '';
              this.comment = null;
              this.amount = null;
              setTimeout(() => {
                this.success = false;
                this.close();
              }, 4000);
            }
          })
          .catch((error) => {
            this.validationErrors.show = true;
            this.validationErrors.messages = [];
            const errors = findAllByKey(error.response.data, 'message');
            this.validationErrors.messages.push(...errors);
          });
      }
    },
  },
};
</script>

<style lang="scss">
  .offer-form-card {
    .small {
      font-size: 10px;
    }
    &__input {
      width: 100%;
      padding: 2px 5px;
      margin-top: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      &:focus {
        outline: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
      }
      &.error--text {
        border-bottom: 1px solid #ff5252;
      }
    }
  }
</style>
