import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { get } from 'lodash';
import { set } from '../../helpers/mutations';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    offer: null,
  },
  mutations: {
    ...set('offer'),
  },
  getters: {
    offer: (state) => get(state, 'offer'),
    property: (state) => get(state.offer, 'property'),
  },
  actions: {
    getOffer: ({ commit }, offerId) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/offer/${offerId}`)
      .then((offer) => {
        commit('setOffer', offer.data);
      }),
    makeOffer: (context, data) => axios
      .post(`${process.env.VUE_APP_HOST_URL}/offer/`, data)
      .then((offer) => offer),
    acceptOffer: (context, offerId) => axios
      .post(`${process.env.VUE_APP_HOST_URL}/offer/${offerId}/accept/`)
      .then((offer) => offer),
    rejectOffer: (context, offerId, data) => axios
      .post(`${process.env.VUE_APP_HOST_URL}/offer/${offerId}/reject/`, data)
      .then((offer) => offer),
  },
};
