import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    error: {
      isError: false,
      message: '',
    },
  },
  mutations: {
    SET_ERROR: (state, data) => {
      state.error = data;
    },
  },
  getters: {
    error: (state) => state.error,
  },
  actions: {
    createDeliveryAddress: async ({ commit }, payload) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        const response = await axios.post(
          `${process.env.VUE_APP_HOST_URL}/delivery_address`,
          payload.data,
        );

        if (response && response.data) return response.data;
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
      return null;
    },
    updateDeliveryAddress: async ({ commit }, payload) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        const response = await axios.patch(
          `${process.env.VUE_APP_HOST_URL}/delivery_address/${payload.id}/`,
          payload.data,
        );

        if (response && response.data) return response.data;
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
      return null;
    },
  },
};
