export default {
  data: (vm) => ({
    requiredRule: [
      (v) => !!v || vm.$t('required'),
    ],
    onlyTextRule: [
      (v) => /^(?!.* {2})(?=\S)(?=.*\S$)[a-ząęćńśłóżźA-ZĄĘŃŚŁÓŻŹ ]+$/.test(v) || vm.$t('enterCorrectName'),
      (v) => !!v || vm.$t('cantBeEmpty'),
    ],
    cityRule: [
      // eslint-disable-next-line max-len
      (v) => /^(?!.* {2})(?=\S)(?=.*\S$)[-a-ząęćńśłóżźA-ZĄĘŃŚŁÓŻŹ ]+$/.test(v) || vm.$t('enterCorrectName'),
      (v) => !!v || vm.$t('cantBeEmpty'),
      (v) => (v && v.length >= 2) || vm.$t('min2chars'),
    ],
    streetRule: [
      // eslint-disable-next-line max-len
      (v) => /^(?!.* {2})(?=\S)(?=.*\S$)[a-ząęćńśłóżźA-ZĄĘŃŚŁÓŻŹ0-9-'.,;/ ]+$/.test(v) || vm.$t('enterCorrectName'),
      (v) => !!v || vm.$t('cantBeEmpty'),
    ],
    onlyNumber: [
      (v) => !!v || vm.$t('cantBeEmpty'),
      (v) => /^[0-9]+$/.test(v) || vm.$t('enterNumber'),
    ],
  }),
};
