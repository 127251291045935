export const permitGrantedOptions = Object.freeze({
  granted: 'granted',
  inProgress: 'in_progress',
  notGranted: 'not_granted',
});

export const permitProposalOptions = Object.freeze({
  yes: 'yes',
  no: 'no',
});

export const partnerships = Object.freeze({
  generalPartnership: 'Spółka jawna',
  professionalPartnership: 'Spółka partnerska',
  limitedPartnership: 'Spółka komandytowa',
  masterLimitedPartnership: 'Spółka komandytowo-akcyjna',
  limitedLiabilityPartnership: 'Spółka z o.o.',
  simpleJointStockCompany: 'Prosta spółka akcyjna',
  jointStockCompany: 'Spółka akcyjna',
});

export const propertyTypeOptions = Object.freeze({
  ownership: {
    value: 'ownership',
    displayValue: 'Prawo własności',
  },
  perpetualUsufructRight: {
    value: 'perpetual_usufruct_right',
    displayValue: 'Prawo użytkowania wieczystego',
  },
});
