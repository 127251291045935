import axios from 'axios';

const setHeader = () => {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
};

const postOrPatch = async (endpoint, data) => {
  setHeader();

  let response = null;
  if (data.id) {
    response = await axios.patch(
      `${process.env.VUE_APP_HOST_URL}/${endpoint}/${data.id}/`,
      data,
    );
  } else {
    response = await axios.post(
      `${process.env.VUE_APP_HOST_URL}/${endpoint}/`,
      data,
    );
  }

  return response;
};

export default {
  postOrPatch,
};
