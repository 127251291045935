<template>
  <div v-if="this.offers.length">
    <div class="property-offer-list mt-8">
      <template v-for="(item, index) in propertyOffersList">
        <PropertyOffers :key="index" :index="index" :offer="item" />
      </template>
      <div class="d-flex justify-center mt-5">
        <button
          class="btn-pagination"
          :disabled="page === 1"
          @click="prev()"
        >
          <v-icon class="font-blue font-big pointer">mdi-menu-left</v-icon>
        </button>
        <span class="d-flex align-center">{{ this.page }}</span>
        <button
          class="btn-pagination"
          :disabled="disableNext"
          @click="next()"
        >
          <v-icon class="font-blue font-big pointer">mdi-menu-right</v-icon>
        </button>
      </div>
    </div>
  </div>
  <div
    class="mt-10"
    v-else
  >
    <p class="font-weight-bold">
      {{ $t('noMoneyOffers') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { slice } from 'lodash';
import PropertyOffers from './PropertyOffers.vue';

export default {
  name: 'PropertyOffersList',
  data: () => ({
    limit: 8,
    page: 1,
  }),
  components: {
    PropertyOffers,
  },
  computed: {
    ...mapGetters('propertyOffers', ['offers', 'propertyOffers']),
    disableNext() {
      return Math.ceil(this.offers.length / 8) <= this.page;
    },
    propertyOffersList() {
      const start = (this.page - 1) * this.limit;
      const end = start + this.limit;
      return slice(this.offers, start, end);
    },
  },
  methods: {
    prev() {
      this.page -= 1;
    },
    next() {
      this.page += 1;
    },

  },
};
</script>
