import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import apiHelper from '@/services/apiHelper';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    property: {
      property_type: 'parcel',
    },
    lots: [],
    landRegisters: [],
    addresses: [],
    currentStep: 1,
  },
  mutations: {
    ADD_LOT: (state, lot) => {
      const index = state.lots.findIndex((l) => l.id === lot.id);
      if (index >= 0) {
        Vue.set(state.lots, index, lot);
      } else state.lots.push(lot);
    },
    ADD_ADDRESS: (state, address) => {
      const index = state.addresses.findIndex((a) => a.id === address.id);
      if (index >= 0) {
        Vue.set(state.addresses, index, address);
      } else state.addresses.push(address);
    },
    ADD_LAND_REGISTER: (state, landRegister) => {
      state.landRegisters.push(landRegister);
    },
    DELETE_PRICE: (state) => {
      delete state.property.price;
    },
    SET_STEP: (state, step) => {
      state.currentStep = step;
    },
    SET_PARCEL: (state, parcel) => {
      state.property.parcel = { ...parcel };
    },
    SET_PROPERTY: (state, property) => {
      state.property = { ...property };
      state.lots = property.lots;
      if (property.lots) {
        state.landRegisters = property.lots.map((lot) => lot.land_register);
        state.addresses = property.lots.map((lot) => lot.address);
      }
    },
    RESET_LOTS: (state) => {
      state.lots = [];
    },
    RESET_LAND_REGISTERS: (state) => {
      state.landRegisters = [];
    },
    RESET_ADDRESSES: (state) => {
      state.addresses = [];
    },
  },
  getters: {
    property: (state) => state.property,
    lots: (state) => state.lots,
    parcel: (state) => state.property.parcel,
    currentStep: (state) => state.currentStep,
    price: (state) => state.property.price,
  },
  actions: {
    createOrUpdateAddress: async ({ commit }, address) => {
      const response = await apiHelper.postOrPatch('lot_addresses', address);
      if (response && response.data) commit('ADD_ADDRESS', response.data);
      return response.data.id;
    },
    createOrUpdateLandRegister: async ({ commit }, payload) => {
      const response = await apiHelper.postOrPatch('land_registers', payload);
      if (response && response.data) commit('ADD_LAND_REGISTER', response.data);
      return response.data.id;
    },
    createOrUpdateLot: async ({ commit }, payload) => {
      const response = await apiHelper.postOrPatch('lots', payload);
      if (response && response.data) commit('ADD_LOT', response.data);
      return response.data.id;
    },
    createOrUpdateParcel: async ({ commit }, payload) => {
      const response = await apiHelper.postOrPatch('parcels', payload);
      if (response && response.data) commit('SET_PARCEL', response.data);
    },
    fetchProperty: async ({ commit, dispatch, state }) => {
      const response = await axios.get(
        `${process.env.VUE_APP_HOST_URL}/properties/${state.property.id}/`,
      );

      commit('SET_PROPERTY', response.data);
      if (!response.data.parcel) {
        await dispatch('createOrUpdateParcel', {
          property: state.property.id,
        });
      }
    },
    tryUpdateProperty: async ({ commit, state }, property) => {
      const draft = await apiHelper.postOrPatch('properties', {
        id: state.property.id,
        property_type: state.property.property_type,
        ...property,
      });
      if (draft && draft.data) commit('SET_PROPERTY', draft.data);
      return draft.data.id;
    },
    updateParcel: async ({ dispatch, state }, parcel) => {
      await dispatch('createOrUpdateParcel', {
        ...parcel,
        property: state.property.id,
      });
    },
    updatePrice: async ({ dispatch }, price) => {
      await dispatch('tryUpdateProperty', { price });
    },
    addLot: async ({ dispatch, state }, lot) => {
      const addressId = await dispatch('createOrUpdateAddress', lot.address);

      // check if we have a land register created for the provided number
      let landRegisterId = null;
      const existingLandRegister = state.landRegisters.find(
        (register) => register.number === lot.land_register.number,
      );
      if (existingLandRegister) {
        landRegisterId = existingLandRegister.id;
      } else {
        // if changing existing, check if there's any other lot referencing

        landRegisterId = await dispatch('createOrUpdateLandRegister', {
          property: state.property.id,
          number: lot.land_register.number,
        });
      }

      await dispatch('createOrUpdateLot', {
        id: lot.id,
        lot_id: lot.lot_id,
        area: lot.area,
        property: state.property.id,
        address: addressId,
        land_register: landRegisterId,
        is_built_up: lot.is_built_up ? lot.is_built_up : false,
      });
      await dispatch('fetchProperty');
    },
    updateLot: async ({ dispatch, state }, lot) => {
      const addressId = await dispatch('createOrUpdateAddress', lot.address);
      // check if we have a land register created for the provided number
      let landRegisterId = null;
      const existingLandRegister = state.landRegisters.find(
        (register) => register.number === lot.land_register.number,
      );

      if (existingLandRegister) {
        landRegisterId = existingLandRegister.id;
        if (existingLandRegister.number !== lot.land_register.number) {
          await dispatch('createOrUpdateLandRegister', {
            id: existingLandRegister.id,
            property: state.property.id,
            number: lot.land_register.number,
          });
        }
      } else {
        landRegisterId = await dispatch('createOrUpdateLandRegister', {
          property: state.property.id,
          number: lot.land_register.number,
        });
      }

      await dispatch('createOrUpdateLot', {
        id: lot.id,
        lot_id: lot.lot_id,
        area: lot.area,
        property: state.property.id,
        address: addressId,
        land_register: landRegisterId,
        is_built_up: lot.is_built_up,
      });
      await dispatch('fetchProperty');
    },
    setAddress: async ({ dispatch }, payload) => {
      const newProperty = {
        location_description: payload.location_description,
        street_name: payload.street_name,
        street_number: payload.street_number,
        city: payload.city,
        postal_code: payload.postal_code,
      };
      const propertyId = await dispatch('tryUpdateProperty', newProperty);
      return propertyId;
    },
    setStep: ({ commit }, step) => {
      commit('SET_STEP', step);
    },
    deletePrice: ({ commit }) => {
      commit('DELETE_PRICE');
    },
    deleteLot: async ({ dispatch }, id) => {
      await axios.delete(
        `${process.env.VUE_APP_HOST_URL}/lots/${id}/`,
      );

      await dispatch('fetchProperty');
    },
    initProperty: ({ commit }, property) => {
      commit('RESET_ADDRESSES');
      commit('RESET_LOTS');
      commit('RESET_LAND_REGISTERS');
      commit('SET_PROPERTY', property);
      commit('SET_STEP', 1);
    },
    newDraft: ({ commit }) => {
      commit('RESET_ADDRESSES');
      commit('RESET_LOTS');
      commit('RESET_LAND_REGISTERS');
      commit('SET_PROPERTY', {
        property_type: 'parcel',
      });
      commit('SET_STEP', 1);
    },
    validateProperty: async ({ state }) => {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_HOST_URL}/properties/${state.property.id}/validate/`,
        );
        return response;
      } catch (error) {
        return {
          error: true,
          ...error.response.data,
        };
      }
    },
  },
};
