<template>
  <v-container class="d-flex flex-column align-center justify-center vh-100">
    <v-row class="justify-center align-content-end">
      <v-col cols="8" class="user-contract-info">
        {{ $t("userContractInfo") }}
      </v-col>
    </v-row>
    <v-row class="justify-center align-content-center align-content-sm-start w-100">
      <v-col xl="4" md="6">
        <v-btn
          @click="onShowFormClick"
          class="w-100 btn-blue-rounded"
        >
          {{ $t("goToContract") }}
        </v-btn>
      </v-col>
      <v-col xl="4" md="6">
        <v-btn
          @click="() => $router.push('/properties')"
          class="w-100 btn-blue-rounded"
        >
          {{ $t("resignation") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserContractInfo.vue',
  methods: {
    onShowFormClick() {
      this.$emit('showForm');
    },
  },
};
</script>

<style scoped lang="scss">
  .user-contract-info {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    @media (min-width: $sm) {
      font-size: 32px;
    }
  }
  .fixed-btn-height {
    height: 30px;
  }
</style>
