import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { get } from 'lodash';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    propertyOffers: null,
  },
  mutations: {
    SET_OFFERS: (state, data) => {
      state.propertyOffers = data;
    },
  },
  getters: {
    announcement: (state) => get(state.propertyOffers, 'announcement'),
    capacity: (state) => get(state.propertyOffers, 'development_capacity_analysis'),
    offers: (state) => get(state.propertyOffers, 'offers'),
    propertyOffers: (state) => state.propertyOffers,
  },
  actions: {
    getPropertyOffers: ({ commit }, propertyId) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/properties/published/${propertyId}/`)
      .then((property) => {
        commit('SET_OFFERS', property.data);
      }),
  },
};
