<template>
  <v-row justify="center">
    <v-col cols="12">
      <h2>{{ $t('ownersList') }}</h2>

      <v-data-table
        v-if="companyOwners.length > 0"
        :headers="companyHeaders"
        :items="companyOwners"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click.stop="editOwner(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click.stop="deleteOwner(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>

      <v-data-table
        v-if="privateOwners.length > 0"
        :headers="privateHeaders"
        :items="privateOwners"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click.stop="editOwner(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click.stop="deleteOwner(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.first_name }} {{ item.last_name }}
        </template>
        <template v-slot:item.street="{ item }">
          {{ item.street_name }} {{ item.street_number }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'OwnersList',
  props: [
    'owners',
  ],
  data: (vm) => ({
    companyHeaders: [
      { text: vm.$t('company_name'), align: 'start', value: 'name' },
      { text: vm.$t('krs'), value: 'krs' },
      { text: vm.$t('nip'), value: 'nip' },
      {
        text: vm.$t('editItem'), value: 'actions', sortable: false, align: 'end',
      },
    ],
    privateHeaders: [
      { text: vm.$t('first_and_last_name'), align: 'start', value: 'name' },
      { text: vm.$t('pesel'), value: 'pesel' },
      { text: vm.$t('street'), value: 'street' },
      {
        text: vm.$t('editItem'), value: 'actions', sortable: false, align: 'end',
      },
    ],
  }),
  computed: {
    companyOwners() {
      return this.owners.filter((owner) => owner.owner_type === 'company');
    },
    privateOwners() {
      return this.owners.filter((owner) => owner.owner_type !== 'company');
    },
  },
  methods: {
    editOwner(item) {
      this.$emit('editOwner', item);
    },
    deleteOwner(item) {
      this.$emit('deleteOwner', item);
    },
  },
};
</script>

<style scoped>

</style>
