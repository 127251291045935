import axios from 'axios';

const uldkApi = axios.create();

const apiUrl = 'https://uldk.gugik.gov.pl/';
const getParcelFunction = '?request=GetParcelById&id=';
const getParcelIdFunction = '?request=GetParcelByIdOrNr&id=';
const geometryResult = '&result=geom_wkt&srid=4326';
const fullLocationResult = '&result=voivodeship,county,commune,region,parcel';
const fullLocationAndIdResult = '&result=id,voivodeship,county,commune,region,parcel';

const getParcelGeometryRequest = (parcelId) => `${apiUrl}${getParcelFunction}${parcelId}${geometryResult}`;

const getParcelLocationRequest = (parcelId) => `${apiUrl}${getParcelFunction}${parcelId}${fullLocationResult}`;

const getParcelIdRequest = (parcelRegionAndNumber) => `
  ${apiUrl}${getParcelIdFunction}${parcelRegionAndNumber}${fullLocationAndIdResult}
`;

const getParcelGeometry = async (parcelId) => {
  const cachedData = localStorage.getItem(parcelId);

  if (cachedData) {
    return cachedData;
  }

  delete uldkApi.defaults.headers.common.Authorization;
  try {
    const result = await uldkApi.get(getParcelGeometryRequest(parcelId));

    const updatedLines = [];
    result.data.split(/\r?\n/).forEach((line) => {
      if (line.startsWith('POLYGON')) {
        // eslint-disable-next-line no-param-reassign
        updatedLines.push(`SRID=4326;${line}`);
      } else {
        updatedLines.push(line);
      }
    });

    const updated = updatedLines.join('\n');
    localStorage.setItem(parcelId, updated);
    return updated;
  } catch (error) {
    console.log('problem getting geometry', error);
    return null;
  }
};

const getAddressFromId = async (parcelId) => {
  const cachedData = localStorage.getItem(`address_${parcelId}`);
  let responseData = null;
  if (cachedData && cachedData.indexOf('brak wyników') < 0) {
    responseData = cachedData;
  } else {
    delete uldkApi.defaults.headers.common.Authorization;
    const result = await uldkApi.get(getParcelLocationRequest(parcelId));
    responseData = result.data;
    localStorage.setItem(`address_${parcelId}`, responseData);
  }

  const lines = responseData.split(/\r?\n/);
  if (lines[0] === '-1 brak wyników') throw Error('E_PARCEL_ID_NOT_FOUND');

  const data = lines[1].split('|');
  return {
    voivodeship: data[0],
    county: data[1],
    municipality: data[2],
    region: data[3],
    lot_number: data[4],
  };
};

const getIdFromParcelNameAndNumber = async (regionName, parcelNumber) => {
  const request = `${regionName} ${parcelNumber}`;

  const cachedData = localStorage.getItem(request);
  let responseData = null;
  if (cachedData) {
    responseData = cachedData;
  } else {
    delete uldkApi.defaults.headers.common.Authorization;
    const response = await uldkApi.get(getParcelIdRequest(request));
    responseData = response.data;
    localStorage.setItem(request, responseData);
  }

  const lines = responseData.split(/\r?\n/);

  lines.shift();
  lines.pop();

  if (lines.length === 0) throw Error('E_PARCEL_NOT_FOUND_FOR_REGION_NUMBER');

  const result = lines.map((line) => {
    const data = line.split('|');
    return {
      lot_id: data[0],
      address: {
        voivodeship: data[1],
        county: data[2],
        municipality: data[3],
        region: data[4],
        lot_number: data[5],
      },
    };
  });

  return result;
};

export default {
  getParcelGeometry,
  getAddressFromId,
  getIdFromParcelNameAndNumber,
};
