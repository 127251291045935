<template>
  <v-container fluid class="stepper">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <p class="title my-5">{{ $t("newProperty") }}</p>
        <v-stepper v-model="localStep" vertical @change="stepperChanged">
          <v-stepper-step
            :complete="currentStep > 1"
            editable
            step="1"
          >
            {{ $t("addParcels") }}
          </v-stepper-step >

          <v-stepper-content step="1" class="pb-5">
            <p class="mb-5">{{ $t('infoAboutParcel')}}</p>
            <add-lots-step
              :isActive="localStep == 1"
              @completed="nextStep(1)"
              @navigateToParcel="navigateToParcel"
            />
          </v-stepper-content>
          <v-stepper-step
            :complete="currentStep > 2"
            :editable="currentStep > 1"
            step="2"
          >
            {{ $t("plans") }}
          </v-stepper-step>

          <v-stepper-content step="2">
            <p class="mb-5">{{ $t('gotPlans')}}</p>
            <add-plans-step
              :isActive="localStep == 2"
              @completed="nextStep(2)"
              @invalidated="resetSteps(2)"
            />
          </v-stepper-content>

          <v-stepper-step
            step="3"
            :complete="currentStep > 3"
            :editable="currentStep > 2"
          >
            {{ $t("permit") }}
          </v-stepper-step>

          <v-stepper-content step="3">
            <p class="mb-5">{{ $t('gotPermit') }}</p>
            <add-permit-step
              :isActive="localStep == 3"
              @completed="nextStep(3)"
            />
          </v-stepper-content>

          <v-stepper-step
            step="4"
            :complete="currentStep > 4"
            :editable="currentStep > 3"
          >
            {{ $t("offer") }}
          </v-stepper-step>

      <v-stepper-content step="4">
        <p class="mb-5">{{ $t('satisfyProposal')}}</p>
        <add-proposal-step
          :isActive="localStep == 4"
          @completed="finish()"
        />
      </v-stepper-content>
    </v-stepper>
    <v-alert
        class="mt-5"
        type="error"
        v-model="validationErrors.show"
        dismissible
        transition="fade-transition">
        {{ validationErrors.messages }}
    </v-alert>
      </v-col>
      <v-col
        cols="10"
        md="6"
        offset="1"
        offset-md="0"
      >
        <v-card height="100%" width="100%" flat>
          <Map :parcels="lots" :goToParcelOnStart="goToParcelOnStart" ref="map"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { findAllByKey } from '@/helpers/functions';
import { mapActions, mapGetters } from 'vuex';
import Map from '@/components/Map.vue';
import AddLotsStep from './newPropertyForm/AddLotsStep.vue';
import AddPlansStep from './newPropertyForm/AddPlansStep.vue';
import AddPermitStep from './newPropertyForm/AddPermitStep.vue';
import AddProposalStep from './newPropertyForm/AddProposalStep.vue';

export default {
  name: 'NewPropertyForm',
  props: ['propertyDraft'],
  components: {
    AddLotsStep,
    AddPlansStep,
    AddPermitStep,
    AddProposalStep,
    Map,
  },
  created() {
    if (this.propertyDraft) {
      this.initProperty(this.propertyDraft);
    } else {
      this.newDraft();
    }
  },
  data: () => ({
    localStep: 1,
    validationErrors: {
      show: false,
      messages: [],
    },
    goToParcelOnStart: true,
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['property', 'currentStep', 'lots']),
  },
  methods: {
    ...mapActions('newPropertyForm', ['newDraft', 'initProperty', 'setStep', 'validateProperty']),
    nextStep(fromStep) {
      this.setStep(fromStep + 1);
      this.localStep = fromStep + 1;
    },
    async finish() {
      this.validateProperty()
        .then((response) => {
          if (response.error) {
            this.validationErrors.show = true;
            const errors = findAllByKey(response, 'message');
            this.validationErrors.messages.push(...errors);
          } else {
            this.$router.push({ name: 'contract', params: { propertyId: this.property.id } });
          }
        })
        .catch((error) => {
          this.validationErrors.show = true;
          this.validationErrors.messages.push(error);
        });
    },
    resetSteps(toStep) {
      this.setStep(toStep);
    },
    stepperChanged(val) {
      this.localStep = parseInt(val, 10);
    },
    navigateToParcel(data) {
      this.$refs.map.goToParcel(data.lot_id);
    },
  },
  watch: {
    currentStep(val) {
      this.localStep = val;
    },
  },
};

</script>

<style lang="scss">
  .stepper {
    .v-stepper {
      box-shadow: none!important;
      &__step {
        padding: 25px 18px 16px!important;
        .v-stepper__step__step {
          background: transparent!important;
          color: $black!important;
          padding: 18px;
          font-size: 18px;
        }
        &--active,
        &--complete {
          .v-stepper__step__step {
            border: 2px solid $f-blue;
            i {
              color: $f-blue!important;
            }
          }
        }
        &--inactive {
          .v-stepper__step__step {
            border: 1px solid grey;
          }
        }
        &--editable {
          .v-stepper__label {
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
