<template>
  <v-container>
    <v-card flat class="mt-5">
      <v-card-title>
        {{ $t("whatBuildingsDescription") }}
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model.lazy="description"
          :label="$t('buildingsDescription')"
          :rules="descriptionRules"
          class="required"
          counter
          :counter-value="valuePerMax"
        />
        <v-form ref="files">
          <v-file-input
            :hint="$t('notRequired') + ' | ' + $t('max5Photos')"
            multiple
            accept="image/*"
            :placeholder="$t('photos')"
            :loading="uploadingFile"
            :disabled="uploadingFile || building_photos.length >= 5"
            :rules="photos_rules"
            @change="fileSelected"
          />
        </v-form>
        <file-list :files="files" @fileRemoved="onFileRemoved"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { remove } from 'lodash';
import fileUpload from '@/services/fileUpload';
import FileList from '@/components/FileList.vue';

export default {
  name: 'Buildings',
  components: {
    FileList,
  },
  data: (vm) => ({
    description: null,
    uploadingFile: false,
    building_photos: [],
    photos_rules: [
      (value) => !value || (value && vm.building_photos.length + value.length <= 5) || vm.$t('max5Photos'),
    ],
    descriptionRules: [
      (v) => !!v || vm.$t('cantBeEmpty'),
      (v) => (v && v.length <= 300) || vm.$t('max300Chars'),
    ],
  }),
  created() {
    this.description = this.parcel.buildings_description;
    if (this.parcel.building_photos) this.building_photos = this.parcel.building_photos;
  },
  methods: {
    ...mapActions('newPropertyForm', ['updateParcel']),
    completeStep(completed) {
      this.$emit('stepCompleted', completed);
    },
    async fileSelected(files) {
      this.uploadingFile = true;
      try {
        const uploadedFiles = [];
        if (this.$refs.files.validate()) {
          // eslint-disable-next-line no-restricted-syntax
          for (const file of files) {
            // eslint-disable-next-line no-await-in-loop
            const uploadedFile = await fileUpload.uploadSingle(file, 'building_photo');
            uploadedFiles.push(uploadedFile.id);
          }

          this.building_photos.push(...uploadedFiles);

          const parcel = {
            ...this.parcel,
            building_photos: this.building_photos,
          };

          this.updateParcel(parcel);
        }
      } catch (error) {
        console.log('Error uploading file', error);
      } finally {
        this.uploadingFile = false;
      }
    },
    onFileRemoved(id) {
      remove(this.building_photos, (photo) => photo === id);
      const parcel = {
        ...this.parcel,
        building_photos: this.building_photos,
      };
      this.updateParcel(parcel);
    },
    valuePerMax(value) {
      return value ? `${value.length} / 300` : '0 / 300';
    },
  },
  computed: {
    ...mapGetters('newPropertyForm', ['parcel']),
    files() {
      if (this.parcel) {
        return [
          ...this.parcel.building_photos,
        ];
      }
      return [];
    },
  },
  watch: {
    description() {
      const parcel = { ...this.parcel };
      if (parcel.is_built_up) {
        parcel.buildings_description = this.description;
      }

      if (!parcel.buildings_description) parcel.buildings_description = null; // can't have "empty"

      this.updateParcel(parcel);
    },
  },
};
</script>

<style scoped lang="scss">
  .new-property {
    .required {
      ::v-deep .v-label {
          &::after {
          content: '*' !important;
          color: red !important;
        }
      }
    }

    ::v-deep &__toggle-btn {
      background: transparent !important;

      .v-btn__content {
        color: $black !important;
      }

      &.v-item--active {
        background: $f-blue !important;

        .v-btn__content {
          color: $white !important;
        }
      }
    }
  }
</style>
