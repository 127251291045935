<template>
  <div v-if="isActive">
    <plans
      @stepCompleted="stepCompleted = $event"
    />
    <v-btn
      :disabled="!validate()"
      color="primary"
      class="mt-5 mb-1"
      @click="completed()"
    >
      {{ $t("next") }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Plans from './Plans.vue';

export default {
  name: 'AddPlansStep',
  components: {
    Plans,
  },
  props: [
    'isActive',
  ],
  data: () => ({
    stepCompleted: false,
  }),
  computed: {
    ...mapGetters('newPropertyForm', ['parcel']),
  },
  methods: {
    validate() {
      let result = false;
      if (this.parcel.has_local_plan === undefined && this.parcel.has_concept === undefined) result = false;
      else if (this.parcel.has_local_plan === false && this.parcel.has_concept === false) {
        result = this.parcel.has_building_conditions_decision != null
          && this.parcel.initial_zoning_category !== null;
      } else if (this.parcel.has_local_plan !== null && this.parcel.has_concept === true) {
        result = this.parcel.initial_zoning_category !== null
          && this.parcel.concept.length;
      } else {
        result = this.parcel.initial_zoning_category !== null && this.parcel.has_concept !== null;
      }

      // if (!result) this.invalidated();

      return result;
    },
    completed() {
      this.$emit('completed');
    },
    invalidated() {
      this.$emit('invalidated');
    },
  },
};
</script>

<style scoped>

</style>
