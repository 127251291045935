import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { get } from 'lodash';
// eslint-disable-next-line import/named
import { set } from '../../helpers/mutations';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    announcement: {},
  },
  mutations: {
    ...set('announcement'),
  },
  getters: {
    offer: (state) => get(state, 'announcement'),
    announcement: (state) => get(state.announcement, 'announcement'),
    capacity: (state) => get(state.announcement, 'development_capacity_analysis'),
    mpzps: (state) => get(state.announcement, 'mpzps'),
    land_register: (state) => get(state.announcement, 'land_registers'),
    calculations: (state) => get(state.announcement.announcement, 'main_usable_area_calculation'),
    mpzpList: (state) => state.announcement.mpzps.map((mpzp) => ({
      planNumber: mpzp.plan_number,
      id: mpzp.id,
    })),
    ownerships: (state) => get(state.announcement, 'ownership_types'),
  },
  actions: {
    getAnnouncement: ({ commit }, propertyId) => axios
      .get(`${process.env.VUE_APP_HOST_URL}/properties/${propertyId}/announcement/`)
      .then((announcement) => {
        commit('setAnnouncement', announcement.data);
      }),
    getAnnouncementPreview: ({ commit }, { propertyId, token }) => {
      delete axios.defaults.headers.common['Authorization'];
      return axios
        .get(`${process.env.VUE_APP_HOST_URL}/properties/${propertyId}/announcement/preview/${token}/`)
        .then((announcement) => {
          commit('setAnnouncement', announcement.data);
        });
    },
  },
};
